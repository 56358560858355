import React, {useEffect} from "react";
import {hasGrantedAllScopesGoogle, useGoogleLogin} from '@react-oauth/google';
import {useOutletContext} from "react-router-dom";

import iconGoogle from 'modernize-essentials/dist/images/svgs/google-icon.svg';
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import {useNotifications} from "../../providers/NotificationsProvider";


const GoogleLogin = (props) => {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const parentContext = useOutletContext();
    const notificationsContext = useNotifications();

    const scopes = [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/business.manage'
    ];


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const scope = urlParams.get('scope');
        if (code) {
            onSuccessfulAuth({code, scope});
        }
    }, []);


    const onSuccessfulAuth = (codeResponse) => {
        /*const hasAccess = hasGrantedAllScopesGoogle(
            codeResponse,
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/business.manage'
        );

        console.log(hasAccess, codeResponse);
        if (hasAccess) {*/
            parentContext.onGoogleAuth(codeResponse.code);
        /*} else {
            notificationsContext.notify(t('You need to grant all permissions.'), 'error');
        }*/
    }


    const redirectUri = `${window.location.origin}/auth/login`;
    const onClick = useGoogleLogin({
        onSuccess: onSuccessfulAuth,
        onError: (error) => {
            console.log(error);
        },
        flow: 'auth-code',
        scope: scopes.join(' '),
        ux_mode: 'redirect',
        state: 'google',
        redirect_uri: redirectUri,
        select_account: true
    });


    return (
        <a className="btn btn-white text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8" href="#" role="button" onClick={(e) => {e.preventDefault(); onClick();}}>
            <img src={iconGoogle} alt="" className="img-fluid me-2" width="18" height="18" />
            <span className="d-none d-sm-block me-1 flex-shrink-0">{t('Sign in with')}</span>Google
        </a>
    );
}

export default GoogleLogin;