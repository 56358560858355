import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesPostsModel from "../../../models/BusinessesPostsModel";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import TableEditableRow from "../../ui/TableEditableRow";
import Alert from "../../ui/Alert";
import BusinessFormTitle from "../formElements/BusinessFormTitle";
import BusinessFormCategories from "../formElements/BusinessFormCategories";
import BusinessFormDescription from "../formElements/BusinessFormDescription";
import BusinessFormWebsite from "../formElements/BusinessFormWebsite";
import BusinessFormPhoneNumbers from "../formElements/BusinessFormPhoneNumbers";
import BusinessesPostAddEvent from "./BusinessesPostAddEvent";
import BusinessesPostAddStandard from "./BusinessesPostAddStandard";
import BusinessesPostAddOffer from "./BusinessesPostAddOffer";
import BusinessesPostAddPublish from "./BusinessesPostAddPublish";
import BusinessViewMenu from "../BusinessViewMenu";
import BusinessesPostMedia from "./BusinessesPostMedia";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";


//LocalPost for Google My Business: https://developers.google.com/my-business/reference/rest/v4/accounts.locations.localPosts#LocalPost
const LocalPostTemplate = {
    languageCode: '',
    summary: '',
    callToAction: {
        actionType: 'ACTION_TYPE_UNSPECIFIED',
        url: ''
    },
    media: {
        mediaFormat: '',
        sourceUrl: ''
    },
    topicType: 'LOCAL_POST_TOPIC_TYPE_UNSPECIFIED',
    event: {
        title: '',
        schedule: {
            startDate: {year: '', month: '', day: ''},
            startTime: {hours: 0, minutes: 0, seconds: 0, nanos: 0},
            endDate: {year: '', month: '', day: ''},
            endTime: {hours: 0, minutes: 0, seconds: 0, nanos: 0},
        }
    },
    offer: {
        couponCode: '',
        redeemOnlineUrl: '',
        termsConditions: '',
    },
};

function BusinessesPostAdd(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const bpModel = new BusinessesPostsModel(apiContext.api);
    const businessesModel = new BusinessesModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();


    const [data, setData] = React.useState(LocalPostTemplate);
    const [publishDate, setPublishDate] = React.useState((new Date()).toISOString());
    const [mediaForPost, setMediaForPost] = React.useState([]);
    const [aiGenerationActive, setAiGenerationActive] = React.useState(true);
    const [aiHints, setAiHints] = React.useState({theme: '', keywords: '', additional: ''});
    const [generationLoading, setGenerationLoading] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState({});
    const [alertNotification, setAlertNotification] = React.useState({type: null, text: null});


    const updateAiHints = (field, value) => {
        let newAiHints = {...aiHints};
        newAiHints[field] = value;
        setAiHints(newAiHints);
    }


    const onFieldChange = (field, value) => {
        const keys = field.split('.');
        let newData = {...data},
            currentData = newData;

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (i === keys.length - 1) {
                currentData[key] = value;
            } else {
                // Not the last key, check if object exists, if not create one
                if (currentData[key] === undefined) {
                    currentData[key] = {};
                }
                currentData = currentData[key];
            }
        }

        setData(newData);
    }


    const onSubmitError = (response) => {
        const categorizedFields = ['summary', 'topic_type', 'event.title', 'event.schedule.start_date', 'event.schedule.start_time', 'event.schedule.end_date', 'event.schedule.end_time', 'call_to_action.action_type', 'call_to_action.url', 'offer.couponCode', 'offer.redeemOnlineUrl', 'offer.termsConditions'];
        if (response.error && response.error.details) {
            let errors = {};
            response.error.details.forEach((detail, index) => {
                if (detail.errorDetails) {
                    detail.errorDetails.forEach((errorDetail, index) => {
                        let fieldName = 'uncategorized';
                        if (errorDetail.field && (categorizedFields.indexOf(errorDetail.field) !== -1)) {
                            fieldName = errorDetail.field;
                        }
                        if (errors[fieldName] === undefined) {errors[fieldName] = []}

                        const fieldIds2Labels = {
                            'summary': t('Summary'),
                            'topic_type': t('Topic type'),
                            'event.title': t('Event title'),
                            'event.schedule.start_date': t('Event start date'),
                            'event.schedule.start_time': t('Event start time'),
                            'event.schedule.end_date': t('Event end date'),
                            'event.schedule.end_time': t('Event end time'),
                            'call_to_action.action_type': t('Call to action type'),
                            'call_to_action.url': t('Call to action URL'),
                        }
                        let message = errorDetail.message;
                        if (fieldIds2Labels[fieldName]) {
                            message = message.replace(fieldName, fieldIds2Labels[fieldName]);
                        }
                        errors[fieldName].push(message);
                    });
                }
            });

            setErrors(errors);
            notifications.notify(t('Please, fix errors before continuing.'), 'error');
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        let publishDateUTC = (new Date(publishDate)).toISOString();

        const validationErrors = bpModel.validatePostData(data);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setLoading(false);
            return;
        }

        bpModel.create(params.locationId, {data: data, publish_date: publishDateUTC, media: mediaForPost}, onSubmitError).then((response) => {
            if (response !== false) {
                window.location.href = '/businesses/'+params.locationId+'/publications/'+response.id;
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onGenerate = (e) => {
        e.preventDefault();
        setGenerationLoading(true);

        let dataToSend = {...aiHints, ...{data: data}};

        bpModel.generate(params.locationId, dataToSend).then((response) => {
            if (response !== false) {
                onFieldChange('summary', response.text);
            }

            setGenerationLoading(false);
        });
    }


    const toggleGenerate = (e) => {
        e.preventDefault();

        setAiGenerationActive(!aiGenerationActive);
    }


    const onProfileRefresh = (profile, lastUpdated) => {
        return true;
    }


    const onFilesChanged = (files) => {
        setMediaForPost(files);
        return true;
    }


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                breadcrumbs.update({
                    title: response.data.title,
                    breadcrumbs: [
                        {label: t('Businesses List'), url: '/businesses'},
                        {label: response.data.title, url: '/businesses/'+params.locationId},
                        {label: t('Publications'), url: '/businesses/'+params.locationId+'/publications'},
                        {label: t('Create')}
                    ]
                });

                document.title = t('Create a new Publication')+' | '+response.data.title+' | LocalBoost';
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [language]);


    useEffect(() => {
        if (data.topicType === 'LOCAL_POST_TOPIC_TYPE_UNSPECIFIED') {
            onFieldChange('topicType', 'STANDARD');
        }
    }, [data]);


    const getSubmitLabel = () => {
        if ((new Date(publishDate)).toISOString() < (new Date()).toISOString()) {
            return t('Publish now');
        } else {
            return t('Schedule');
        }
    }


    return (
        <>
            <BusinessViewHeader
                locationId={params.locationId}
                onProfileRefresh={onProfileRefresh}
            />

            <BusinessViewMenu locationId={params.locationId} active="publications"/>

            <div className="d-flex m-block-gap">
                <Link to={'/businesses/' + params.locationId + '/publications'} className="btn btn-light-info text-info">
                    <i className="ti ti-arrow-left me-2"></i>
                    {t('Back to all Publications')}
                </Link>
            </div>

            <div className="row">
                <div className={aiGenerationActive ? 'col-lg-8' : 'col-12'}>
                    <SimpleCardContainer title={t('Create a new Publication')} loading={loading}>
                        <p className="card-subtitle mb-4">{t('Create a new publication, schedule it or publish immediately.')}</p>

                        {alertNotification.text && (
                            <Alert
                                type={alertNotification.type}
                                text={alertNotification.text}
                            />
                        )}

                        {errors && errors['uncategorized'] && (
                            <Alert
                                type={'danger'}
                                text={errors['uncategorized'].join()}
                                permanent={true}
                            />
                        )}

                        {errors && errors['topic_type'] && (
                            <Alert
                                type={'danger'}
                                text={errors['topic_type'].join()}
                                permanent={true}
                            />
                        )}

                        <form>
                            <div className={'form-group py-2'}>
                                <label className="form-label fw-semibold mb-2">{t('Select Publication type to start')}</label>
                                <div className="btn-group d-flex">
                                    <button type="button"
                                            className={'btn font-medium ' + (data.topicType === 'STANDARD' ? 'btn-primary' : 'text-primary btn-light-primary')}
                                            onClick={(e) => {
                                                onFieldChange('topicType', 'STANDARD')
                                            }}>
                                        {t('Standard Post')}
                                    </button>
                                    <button type="button"
                                            className={'btn font-medium ' + (data.topicType === 'EVENT' ? 'btn-primary' : 'text-primary btn-light-primary')}
                                            onClick={(e) => {
                                                onFieldChange('topicType', 'EVENT')
                                            }}>
                                        {t('Event')}
                                    </button>
                                    <button type="button"
                                            className={'btn font-medium ' + (data.topicType === 'OFFER' ? 'btn-primary' : 'text-primary btn-light-primary')}
                                            onClick={(e) => {
                                                onFieldChange('topicType', 'OFFER')
                                            }}>
                                        {t('Offer')}
                                    </button>
                                </div>
                            </div>

                            <div className={'form-group py-2'}>
                                <label htmlFor="cf-media" className="form-label fw-semibold">{t('Media (optional)')}</label>
                                <BusinessesPostMedia onFilesChanged={onFilesChanged}/>
                                {errors['media'] && <div className="form-text text-danger">{errors['media'].join('. ')}</div>}
                            </div>

                            {data.topicType === 'STANDARD' && (<BusinessesPostAddStandard data={data} onFieldChange={onFieldChange} errors={errors}/>)}
                            {data.topicType === 'EVENT' && (<BusinessesPostAddEvent data={data} onFieldChange={onFieldChange} errors={errors}/>)}
                            {data.topicType === 'OFFER' && (<BusinessesPostAddOffer data={data} onFieldChange={onFieldChange} errors={errors}/>)}


                            <BusinessesPostAddPublish data={publishDate} editable={true} onPublishDateChange={setPublishDate} errors={errors}/>


                            <div
                                className={'d-flex align-items-center py-2 gap-3 ' + ((['STANDARD', 'EVENT', 'OFFER', 'ALERT'].indexOf(data.topicType) !== -1) ? 'justify-content-between' : 'justify-content-end')}>
                                {(['STANDARD', 'EVENT', 'OFFER', 'ALERT'].indexOf(data.topicType) !== -1) && (
                                    <button className="btn btn-primary" onClick={onSubmit}>{getSubmitLabel()}</button>
                                )}

                                <button onClick={toggleGenerate}
                                        className="d-inline-flex align-items-center justify-content-center text-secondary btn btn-light-secondary">
                                    {!aiGenerationActive && (
                                        <>
                                            <i className={'ti ti-code me-2 text-info'}></i>
                                            {t('Prepare with AI')}
                                        </>
                                    ) || aiGenerationActive && (
                                        <>
                                            {t('Hide AI Panel')}
                                        </>
                                    )}
                                </button>
                            </div>
                        </form>
                    </SimpleCardContainer>
                </div>
                <div className={aiGenerationActive ? 'col-lg-4' : 'd-none'}>
                    <SimpleCardContainer title={t('Generate post with AI')}>
                        <p className="card-subtitle mb-4">{t('Give us some information about what should be written.')}</p>

                        <div className={'form-group py-2'}>
                            <label htmlFor="ai-theme" className="form-label fw-semibold mb-0">{t('Main theme')}</label>
                            <div>
                                <span className="fs-2 d-block mb-2">{t('Shortly explain what should be the post about.')}</span>
                                <input type="text" className="form-control" id="ai-theme" defaultValue={aiHints.theme} onChange={(e) => {
                                    updateAiHints('theme', e.target.value)
                                }}/>
                            </div>
                        </div>

                        <div className={'form-group py-2'}>
                            <label htmlFor="ai-keywords" className="form-label fw-semibold mb-0">{t('Keywords')}</label>
                            <div>
                                <span className="fs-2 d-block mb-2">{t('You can list a few meaningful keywords to add.')}</span>
                                <textarea id={'ai-keywords'} className={'form-control'} rows={3} defaultValue={aiHints.keywords} onChange={(e) => {
                                    updateAiHints('keywords', e.target.value)
                                }}></textarea>
                            </div>
                        </div>

                        <div className={'form-group py-2'}>
                            <label htmlFor="ai-additional" className="form-label fw-semibold mb-0">{t('Additional suggestions')}</label>
                            <div>
                                <span className="fs-2 d-block mb-2">{t('Some more information, that you wanted to add.')}</span>
                                <textarea id={'ai-additional'} className={'form-control'} rows={3} defaultValue={aiHints.additional} onChange={(e) => {
                                    updateAiHints('additional', e.target.value)
                                }}></textarea>
                            </div>
                        </div>

                        <div className={'d-flex align-items-center justify-content-between py-2 gap-3 '}>
                            <button onClick={onGenerate} className="d-inline-flex align-items-center justify-content-center btn btn-dark"
                                    disabled={(['STANDARD', 'EVENT', 'OFFER', 'ALERT'].indexOf(data.topicType) === -1) || generationLoading}>
                                <span
                                    className={'spinner-border spinner-border-sm me-2 ' + ((generationLoading) ? ('') : ('d-none'))}
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <i className={'fs-4 ti ti-code me-2 ' + ((generationLoading) ? ('d-none') : (''))}></i>
                                {t('Generate now')}
                            </button>
                        </div>
                    </SimpleCardContainer>
                </div>
            </div>
        </>
    );
}

export default BusinessesPostAdd;
