import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function BusinessFormModal(props) {
    const { t} = useTranslation();

    const onMoreClick = (url) => {
        window.open(url, '_blank');
    }


    return (
        <div className={'modal fade'+(!!props.visible ? ' show' : '')} tabIndex="-1" aria-labelledby="vertical-center-modal" aria-hidden={!props.visible} style={{display: (!!props.visible ? 'block' : '')}}>
            <div className="modal-dialog modal-md">
                <div className="modal-content modal-filled bg-light-warning">
                    <div className="modal-body p-4">

                        <div className="text-center text-warning">
                            <i className="ti ti-checklist fs-7"></i>
                            <h4 className="mt-2">Pro Tip!</h4>
                            <p className="mt-3 text-dark text-start w-100 mb-1">
                                {t('How to improve your score')}:
                            </p>
                            {props.recommendations && props.recommendations.map((item, index) => {
                                let text = t(item.text);
                                if (item.replacements) {
                                    Object.keys(item.replacements).map((key) => {
                                        text = text.replace(key, t(item.replacements[key]));
                                    });
                                }

                                return (
                                    <p key={index} className={'text-dark text-start mb-1'}>
                                        <i className="ti ti-check me-2 fs-3"></i>
                                        {text}
                                        {item.cost && (
                                            <span className="text-danger fw-bold ms-2">+{Math.round(item.cost*100)}%</span>
                                        )}
                                        {item.link && (
                                        <a href={item.link} className="ms-3" onClick={() => {onMoreClick(item.link)}}><i className="ti ti-external-link fs-3 text-dark"></i></a>
                                        )}
                                    </p>
                                )
                            })}

                            <button type="button" className="btn btn-warning mt-3 mb-2" onClick={props.onClose}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}