import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {Link, useParams} from "react-router-dom";
import TableEditableRow from "../../ui/TableEditableRow";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessViewHeader from "./../BusinessViewHeader";
import BusinessViewMenu from "../BusinessViewMenu";
import BusinessesReviewCard from "./BusinessesReviewCard";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";
import Pagination from "../../ui/Pagination";

function BusinessesReviews(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const notificationsContext = useNotifications();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);


    const breadcrumbs = useBreadcrumbs();
    const [reviews, setReviews] = React.useState([]);
    const [unansweredReviewsCount, setUnansweredReviewsCount] = React.useState(0);
    const [replyLoading, setReplyLoading] = React.useState(false);

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const pageSize = 15;


    const fetchReviews = () => {
        businessesModel.getReviews(params.locationId).then((response) => {
            if (response !== false) {
                setReviews(response);

                let unansweredReviews = 0;
                response.map((review) => {
                    if (!review.reviewReply) {
                        unansweredReviews++;
                    }
                });
                setUnansweredReviewsCount(unansweredReviews);

                setTotalPages(Math.ceil(response.length / pageSize));
            }
        });
    }


    const setMetaData = (profile) => {
        if (profile !== false) {
            breadcrumbs.update({
                title: profile.title,
                breadcrumbs: [
                    {label: t('Businesses List'), url: '/businesses'},
                    {label: profile.title, url: '/businesses/'+params.locationId},
                    {label: t('Reviews')}
                ]
            });

            document.title = t('Reviews')+' | '+profile.title+' | LocalBoost';
        }
    }


    const onProfileRefresh = (profile, lastUpdated) => {
        setMetaData(profile);
        fetchReviews();
    }


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                setMetaData(response.data);
            }
        });


        fetchReviews();
    }, [language]);


    return (
        <>
			<BusinessViewHeader
				locationId={params.locationId}
				onProfileRefresh={onProfileRefresh}
			/>
			<BusinessViewMenu locationId={params.locationId} active="reviews" />			
            {(unansweredReviewsCount > 0) && (
                <div className="alert alert-warning">
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                            <i className="ti ti-alert"></i>
                        </div>
                        <div className="flex-grow-1 ms-3">
                            <p className="mb-0">
                                {t('You have {{unansweredReviewsCount}} unanswered review(s). You can answer them automatically now.', {unansweredReviewsCount: unansweredReviewsCount})}

                                <Link to={'/businesses/'+params.locationId+'/reviews-rules'} className="text-warning fw-semibold ms-2">{t('Check your response rules.')}</Link>
                            </p>
                            <p className="mb-0 mt-2">
                                <Link to={'/businesses/'+params.locationId+'/reviews/publish-replies'} className="btn btn-warning d-inline-flex align-items-center">
                                    <span
                                        className={'spinner-border spinner-border-sm me-2 '+((replyLoading)?(''):('d-none'))}
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <i className={'ti ti-message-plus fs-4 me-2'+((replyLoading)?' d-none':'')}></i>
                                    {t('Publish replies now')}
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <div className={'row'}>
                {reviews.map((review, index) => {
                    if (index < (currentPage-1)*pageSize || index >= currentPage*pageSize) {
                        return false;
                    }
                    return (
                        <div className={'col-lg-6 col-xxl-4'} key={'review-'+index}><BusinessesReviewCard data={review} /></div>
                    );
                })}
            </div>

            <div className="my-3">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => {
                        setCurrentPage(page);
                    }}
                />
            </div>
        </>
    );
}

export default BusinessesReviews;
