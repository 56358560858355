import React from "react";

export default function TableWrapper(props) {
    return (
        <div className="table-responsive rounded-2">
            <table className="table border text-nowrap customize-table mb-0 align-middle">
                <thead className="text-dark fs-4">
                <tr>
                    {props.header.map((item, index) => {
                        return (
                            <th key={index} className={item.cellClassName} style={item.cellStyle}><h6 className="fs-4 fw-semibold mb-0">{item.label}</h6></th>
                        )
                    })}
                </tr>
                </thead>
                <tbody>
                {props.children}
                </tbody>
            </table>
        </div>
    )
}