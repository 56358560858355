import React, {useEffect} from "react";

function DangerModal(props) {

    return (
        <div className={'modal fade'+(!!props.visible ? ' show' : '')} tabIndex="-1" aria-labelledby="vertical-center-modal" aria-hidden={!props.visible} style={{display: (!!props.visible ? 'block' : '')}}>
            <div className="modal-dialog modal-sm">
                <div className="modal-content modal-filled bg-light-danger">
                    <div className="modal-body p-4">
                        {props.children}
                        {/*<div className="text-center text-danger">
                            <i className="ti ti-hexagon-letter-x fs-7"></i>
                            <h4 className="mt-2">Oh snap!</h4>
                            <p className="mt-3">
                                Cras mattis consectetur purus sit amet
                                fermentum.Cras justo odio, dapibus ac
                                facilisis in, egestas eget quam.
                            </p>
                            <button
                                type="button"
                                className="btn btn-light my-2"
                                data-bs-dismiss="modal"
                            >
                                Continue
                            </button>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DangerModal;


