import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import {RequireAuth} from "./providers/AuthProvider";
import Home from "./components/common/Home";
import Auth from "./components/auth/Auth";
import Login from "./components/auth/Login";
import React from "react";
import Users from "./components/users/Users";
import ForgotPassword from "./components/auth/ForgotPassword";
import Error404 from "./components/errors/Error404";
import Logout from "./components/auth/Logout";
import Businesses from "./components/businesses/Businesses";
import BusinessesList from "./components/businesses/BusinessesList";
import BusinessAdd from "./components/businesses/BusinessAdd";
import BusinessReviews from "./components/businesses/reviews/BusinessesReviews";
import BusinessReviewReply from "./components/businesses/reviews/BusinessesReviewReply";
import Pricing from "./components/common/Pricing";
import MainLayout from "./components/layout/MainLayout";
import Faq from "./components/common/Faq";
import AccountSettings from "./components/account/AccountSettings";
import AccountInfoTab from "./components/account/AccountInfoTab";
import ConnectedAccountsTab from "./components/account/ConnectedAccountsTab";
import BillingInfoTab from "./components/account/BillingInfoTab";
import NotificationsTab from "./components/account/NotificationsTab";
import ForgotPasswordConfirm from "./components/auth/ForgotPasswordConfirm";
import Register from "./components/auth/Register";
import BusinessView from "./components/businesses/BusinessView";
import BusinessesPosts from "./components/businesses/posts/BusinessesPosts";
import BusinessesPostAdd from "./components/businesses/posts/BusinessesPostAdd";
import BusinessesPostView from "./components/businesses/posts/BusinessesPostView";
import BusinessesPostEdit from "./components/businesses/posts/BusinessesPostEdit";
import Reports from "./components/reports/Reports";
import ReviewsRules from "./components/businesses/rules/ReviewsRules";
import ReportsActivity from "./components/reports/ReportsActivity";
import BusinessesInsights from "./components/businesses/insights/BusinessesInsights";
import BusinessesWidget from "./components/businesses/widget/BusinessesWidget";
import BusinessesMedia from "./components/businesses/media/BusinessesMedia";
import BusinessesMediaAdd from "./components/businesses/media/BusinessesMediaAdd";
import UsersList from "./components/users/UsersList";
import UserEdit from "./components/users/UserEdit";
import BusinessesReviewsPublishReplies from "./components/businesses/reviews/publishReplies/BusinessesReviewsPublishReplies";
import AccountTypes from "./components/accountTypes/AccountTypes";
import AccountTypesList from "./components/accountTypes/AccountTypesList";
import AccountTypeCreate from "./components/accountTypes/AccountTypeCreate";
import AccountTypeEdit from "./components/accountTypes/AccountTypeEdit";
import BusinessesInsightsReporting from "./components/businesses/insights/BusinessesInsightsReporting";
import BusinessesInsightsPrint from "./components/businesses/insights/BusinessesInsightsPrint";

export const router = createBrowserRouter([
    {
        path: "/",
        Component: App,
        children: [
            {
                index: true,
                //loader: homeLoader,
                element: (<RequireAuth><MainLayout><Home/></MainLayout></RequireAuth>),
            },
            {
                path: "auth",
                Component: Auth,
                children: [
                    {
                        path: "login",
                        Component: Login,
                    },
                    {
                        path: "register",
                        Component: Register,
                    },
                    {
                        path: "forgot-password",
                        children: [
                            {
                                path: "confirm",
                                Component: ForgotPasswordConfirm,
                            },
                            {
                                index: true,
                                Component: ForgotPassword,
                            }
                        ],
                    },
                    {
                        path: "logout",
                        Component: Logout,
                    },
                ],
            },
            {
                path: "users",
                element: (<RequireAuth><Users /></RequireAuth>),
                children: [
                    {
                        path: ":userId/edit",
                        Component: UserEdit,
                    },
                    {
                        index: true,
                        Component: UsersList,
                    },
                ],
            },
            {
                path: "account-types",
                element: (<RequireAuth><AccountTypes /></RequireAuth>),
                children: [
                    {
                        path: "create",
                        Component: AccountTypeCreate,
                    },
                    {
                        path: ":accountTypeId/edit",
                        Component: AccountTypeEdit,
                    },
                    {
                        index: true,
                        Component: AccountTypesList,
                    },
                ],
            },
            {
                path: "businesses/:locationId/insights/print",
                Component: BusinessesInsightsPrint,
            },
            {
                path: "businesses",
                element: (<RequireAuth><Businesses /></RequireAuth>),
                children: [
                    {
                        path: "add",
                        Component: BusinessAdd,
                    },
                    {
                        path: ":locationId/widget",
                        Component: BusinessesWidget,
                    }, 
					{
                        path: ":locationId/insights",
                        Component: BusinessesInsights,
                    },
					{
                        path: ":locationId/insights/reporting",
                        Component: BusinessesInsightsReporting,
                    },
                    {
                        path: ":locationId/publications/create",
                        Component: BusinessesPostAdd,
                    },
                    {
                        path: ":locationId/publications/:postId/edit",
                        Component: BusinessesPostEdit,
                    },
                    {
                        path: ":locationId/publications/:postId",
                        Component: BusinessesPostView,
                    },
                    {
                        path: ":locationId/publications",
                        Component: BusinessesPosts,
                    },
                    {
                        path: ":locationId/media",
                        Component: BusinessesMedia,
                    },
                    {
                        path: ":locationId/media/add",
                        Component: BusinessesMediaAdd,
                    },
                    {
                        path: ":locationId/media/:category",
                        Component: BusinessesMedia,
                    },
                    {
                        path: ":locationId/reviews/:reviewId/reply",
                        Component: BusinessReviewReply,
                    },
                    {
                        path: ":locationId",
                        Component: BusinessView,
                    },
                    {
                        path: ":locationId/reviews",
                        Component: BusinessReviews,
                    },
                    {
                        path: ":locationId/reviews/publish-replies",
                        Component: BusinessesReviewsPublishReplies,
                    },
                    {
                        path: ":locationId/reviews-rules",
                        Component: ReviewsRules,
                    },
                    {
                        index: true,
                        Component: BusinessesList,
                    },
                ],
            },
            {
                path: "pricing",
                element: (<RequireAuth><MainLayout><Pricing /></MainLayout></RequireAuth>),
            },
            {
                path: "faq",
                element: (<RequireAuth><MainLayout><Faq /></MainLayout></RequireAuth>),
            },
            {
                path: "reports",
                element: (<RequireAuth><Reports /></RequireAuth>),
                children: [
                    {
                        index: true,
                        Component: ReportsActivity,
                    },
                ],
            },
            {
                path: "settings",
                element: (<RequireAuth><MainLayout><AccountSettings /></MainLayout></RequireAuth>),
                children: [
                    {
                        index: true,
                        Component: AccountInfoTab,
                    },
                    {
                        path: "connected-accounts",
                        Component: ConnectedAccountsTab,
                    },
                    {
                        path: "billing-info",
                        Component: BillingInfoTab,
                    },
                    {
                        path: "notifications",
                        Component: NotificationsTab,
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        Component: Error404,
    }
]);
