import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {Link, Navigate, useParams} from "react-router-dom";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import StarRating from "../../ui/StarRating";
import BusinessViewHeader from "../BusinessViewHeader";
import BusinessViewMenu from "../BusinessViewMenu";
import BusinessesReviewReplyForm from "./BusinessesReviewReplyForm";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";
import StrHelper from "../../../helpers/StrHelper";

function BusinessesReviewReply(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();


    const [editVisible , setEditVisible] = React.useState(false);
    const [reviewInfo, setReviewInfo] = React.useState({});
    const [reviewReply, setReviewReply] = React.useState('');
    const [reviewSuccessfullyResponded, setReviewSuccessfullyResponded] = React.useState(false);


    const setMetaData = (profile) => {
        if (profile !== false) {
            breadcrumbs.update({
                title: profile.title,
                breadcrumbs: [
                    {label: t('Businesses List'), url: '/businesses'},
                    {label: profile.title, url: '/businesses/'+params.locationId},
                    {label: t('Reviews'), url: '/businesses/'+params.locationId+'/reviews'},
                    {label: t('Reply to the Review')}
                ]
            });

            document.title = t('Reply to the Review')+' | '+profile.title+' | LocalBoost';
        }
    }


    const processReviewResponse = (response) => {
        if (response.starRating) {
            switch (response.starRating) {
                case 'ONE': response.numberRating = 1; break;
                case 'TWO': response.numberRating = 2; break;
                case 'THREE': response.numberRating = 3; break;
                case 'FOUR': response.numberRating = 4; break;
                case 'FIVE': response.numberRating = 5; break;
                default: response.numberRating = 0; break;
            }
        }

        return response;
    }


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                setMetaData(response.data);
            }
        });


        businessesModel.getReviewInfo(params.locationId, params.reviewId).then((response) => {
            if (response !== false) {
                response = processReviewResponse(response);

                setReviewInfo(response);
                if (response.reviewReply) {
                    let reviewReplyClean = StrHelper.filterGoogleTranslatedText(response.reviewReply.comment);
                    setReviewReply(reviewReplyClean);
                }
            }
        });
    }, [language]);


    useEffect(() => {
        let reviewReplyClean = StrHelper.filterGoogleTranslatedText(props.reviewReply);

        setReviewReply(reviewReplyClean);
    }, [props.reviewReply]);


    const onProfileRefresh = (profile, lastUpdated) => {
        setMetaData(profile);
    }


    const showEditForm = (e) => {
        e.preventDefault();
        setEditVisible(true);
    }


    const onSubmit = (value) => {
        businessesModel.reviewReply(params.locationId, params.reviewId, value).then((response) => {
            if (response !== false) {
                setReviewSuccessfullyResponded(true);
            }
        });
    }


    const onDelete = () => {
        businessesModel.reviewReplyDelete(params.locationId, params.reviewId).then((response) => {
            if (response !== false) {
                setReviewSuccessfullyResponded(true);
            }
        });
    }



    return (
        <>
        {reviewSuccessfullyResponded && (<Navigate to={'/businesses/'+params.locationId+'/reviews'} />)}

        <BusinessViewHeader
            locationId={params.locationId}
            onProfileRefresh={onProfileRefresh}
        />

        <BusinessViewMenu locationId={params.locationId} active="reviews" />

        <div className="d-flex m-block-gap">
            <Link to={'/businesses/'+params.locationId+'/reviews'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                {t('Back to Reviews')}
            </Link>
        </div>

        {reviewInfo.error ? (
            <div className="alert alert-danger">
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                        <i className="ti ti-message-off fs-4"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <p className="mb-0">{(reviewInfo.error.message) ? reviewInfo.error.message : t('This review is not available at the moment.')}</p>
                    </div>
                </div>
            </div>
        ) : (
        <SimpleCardContainer title={'Reply to the Review'}>
            {reviewInfo.reviewer && (
            <div className="d-flex flex-row flex-md-column flex-lg-row comment-row">
                <div className="pe-3">
                    <span className="">
                        <img src={reviewInfo.reviewer.profilePhotoUrl} className="rounded-circle" alt={reviewInfo.reviewer.displayName} width="50" />
                    </span>
                </div>
                <div className="comment-text w-100 pt-1">
                    <h5 className="font-weight-medium mb-0">{reviewInfo.reviewer.displayName}</h5>

                    <div className="d-flex justify-content-between align-items-center mb-1">
                        {reviewInfo.numberRating && (
                            <StarRating rating={reviewInfo.numberRating} />
                        )}
                    </div>
                </div>
            </div>
            )}

            {reviewInfo.comment && (
                <div className="lead mb-4">{reviewInfo.comment.split('\n').map((str, index, array) =>
                    ((index === array.length - 1) || (!str.length)) ? str : <p key={index} className="mb-1">{str}</p>
                )}</div>
            ) || !reviewInfo.comment && (
                <div className="lead text-muted mb-4">{t('No comment.')}</div>
            )}

            {reviewReply && (
            <>
                <h5 className="mt-0 mb-2">{t('Response to the review')}:</h5>
                <div className="mb-2">{reviewReply.split('\n').map((str, index, array) =>
                    ((index === array.length - 1) || (!str.length)) ? str : <p key={index} className="mb-1">{str}</p>
                )}</div>
                <div className="d-flex align-items-center fs-2 fw-semibold mb-4">
                    <i className="ti ti-message-2 fs-4 me-2"></i>
                    {t('Replied')}: {(new Date(reviewInfo.reviewReply.updateTime)).toLocaleString()}
                </div>

                {!editVisible && (
                <button className="btn btn-light-primary text-primary d-inline-flex align-items-center" onClick={showEditForm}>
                    <i className="ti ti-pencil fs-4 me-2"></i>
                    {t('Edit your Response')}
                </button>
                )}
            </>
            )}

            {(!reviewInfo.reviewReply || reviewInfo.reviewReply && editVisible) && (
            <BusinessesReviewReplyForm
                reviewReply={reviewReply}
                onSubmit={onSubmit}
                onDelete={onDelete}
            />
            )}
        </SimpleCardContainer>
        )}
    </>
    );
}

export default BusinessesReviewReply;
