import React, {useEffect} from 'react';

import {useTranslation} from "react-i18next";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useApi} from "../../../providers/ApiProvider";
import ReviewsRulesModel from "../../../models/ReviewsRulesModel";
import {useParams} from "react-router-dom";



export default function RequestSampleResponseAlert({starRating, onResponseGenerated, reviewId, rule}) {
    const { t} = useTranslation();
    const params = useParams();
    const notificationsContext = useNotifications();
    const apiContext = useApi();
    const reviewsRulesModel = new ReviewsRulesModel(apiContext.api);

    const [generatedResponse, setGeneratedResponse] = React.useState('');
    const [generatedResponseLoading, setGeneratedResponseLoading] = React.useState(false);


    useEffect(() => {
        setGeneratedResponse('');
    }, [starRating, reviewId]);


    const onGenerateResponse = (e) => {
        e.preventDefault();

        const data = {
            stars: starRating
        };

        setGeneratedResponseLoading(true);

        reviewsRulesModel.generateResponse(params.locationId, data).then((response) => {
            if (response && response.result) {
                setGeneratedResponse(response.result);
                notificationsContext.notify(t('Please, check your sample response'), 'success');

                if (onResponseGenerated) {
                    onResponseGenerated(response.result);
                }
            }
        }).finally(() => {
            setGeneratedResponseLoading(false);
        });
    }


    return (
        <div className="alert alert-light mt-4 mb-0">
            {rule && rule.template && rule.template.is_active ? (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <strong>{t('You have a template response for this review')}:</strong>
                    </div>

                    {rule.template.response_template && (
                        <>
                            <div className="pt-2 p-small-margins">{rule.template.response_template.split('\n').map((str, index, array) =>
                                ((index === array.length - 1) || (!str.length)) ? str : <p key={index}>{str}</p>
                            )}</div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <strong>{t('Request a sample response')}</strong>

                        <button className="btn btn-light-primary btn-circle btn-sm d-flex align-items-center justify-content-center"
                                onClick={onGenerateResponse}>
                            <span
                                className={'spinner-border spinner-border-sm text-primary ' + ((generatedResponseLoading) ? ('') : ('d-none'))}
                                role="status"
                                aria-hidden="true"
                            ></span>
                                    <i className={'ti ti-refresh fs-5 text-primary ' + ((generatedResponseLoading) ? ('d-none') : (''))}></i>
                                </button>
                    </div>
                    {t('Our smart AI tailors replies to each review, ensuring a personalized touch while saving you time.')}

                    {generatedResponse && (
                        <>
                            <div className="fw-semibold mt-3">{t('Generated Response')}</div>
                            <div className="pt-2 p-small-margins">{generatedResponse.split('\n').map((str, index, array) =>
                                ((index === array.length - 1) || (!str.length)) ? str : <p key={index}>{str}</p>
                            )}</div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
