import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import BusinessViewMenu from "../BusinessViewMenu";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import BusinessesMediaModel from "../../../models/BusinessesMediaModel";
import {useTranslation} from "react-i18next";


function BusinessesMediaNav(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [sortedCategories, setSortedCategories] = React.useState([]);


    useEffect(() => {
        let sortedKeys = props.data.sort((a, b) => {
            //sort by label, as they go in props.keysToLabels
            if (props.keysToLabels[a] < props.keysToLabels[b]) {
                return 1;
            }
            if (props.keysToLabels[a] > props.keysToLabels[b]) {
                return -1;
            }
            return 0;
        });

        setSortedCategories(sortedKeys);
    }, [props.data]);


    return (
        <nav className="business-media-nav">
            <Link to={'/businesses/'+params.locationId+'/media'} className={'btn btn-sm '+((props.active === 'all')?('btn-primary text-white'):('btn-light-primary text-primary'))}>
                {t('All business media')}
            </Link>
            {sortedCategories && sortedCategories.map((key, index) => {
                return (
                    <Link to={'/businesses/'+params.locationId+'/media/'+key.toLowerCase()} className={'btn btn-sm '+((props.active === key.toLowerCase())?('btn-primary text-white'):('btn-light-primary text-primary'))} key={index}>{props.keysToLabels[key]}</Link>
                )
            }
            )}
            <Link to={'/businesses/'+params.locationId+'/media/customers'} className={'btn btn-sm '+((props.active === 'customers')?('btn-primary text-white'):('btn-light-primary text-primary'))}>{t('Customers Activity')}</Link>
        </nav>
    );
}

export default BusinessesMediaNav;