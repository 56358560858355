import * as React from "react";

const googleMapsApiStructure = {
    gmAPILoaded: false,
    update: () => {}
}

const GoogleMapsApiContext = React.createContext(googleMapsApiStructure);


function GoogleMapsApiProvider({children}) {
    let [googleMapsApi, setGoogleMapsApi] = React.useState(googleMapsApiStructure);

    const updateGoogleMapsAPI = (apiLoaded) => {
        let newGoogleMapsApi = {...googleMapsApi};
        newGoogleMapsApi.gmAPILoaded = apiLoaded;

        setGoogleMapsApi(newGoogleMapsApi);
    };

    let value = {...googleMapsApi};
    value.update = updateGoogleMapsAPI;

    return <GoogleMapsApiContext.Provider value={value}>{children}</GoogleMapsApiContext.Provider>;
}


function useGoogleMapsApi() {
    return React.useContext(GoogleMapsApiContext);
}


export {GoogleMapsApiProvider, useGoogleMapsApi};