import React, {Fragment, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import Chart from "react-apexcharts";
import BusinessViewMenu from "../BusinessViewMenu";
import BusinessesInsightsChart from "./BusinessesInsightsChart";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";
// import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import StrHelper from "../../../helpers/StrHelper";
import {useAuth} from "../../../providers/AuthProvider";
import UsersModel from "../../../models/UsersModel";

export default function BusinessesInsightsPrint(props) {

    const authContext = useAuth();
    const user = authContext.user;
    const defaultDateStart 		= (new Date(Date.now() - (30 + 3) * 24 * 60 * 60 * 1000)).toISOString().slice(0,10);
    const defaultDateEnd 		= (new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)).toISOString().slice(0,10);	
	const defaultDateDiff 		= Math.round((new Date(defaultDateEnd).getTime() - new Date(defaultDateStart).getTime())/ (1000 * 3600 * 24));
	const defaultDateStartComp 	= new Date(Date.parse(defaultDateStart) - (defaultDateDiff * 24 * 60 * 60 * 1000)).toISOString().slice(0,10);
	const defaultDateEndComp 	= new Date(Date.parse(defaultDateStart)).toISOString().slice(0,10);	
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();	
    const [dateStart, setDateStart] = React.useState(defaultDateStart);
    const [dateEnd, setDateEnd] = React.useState(defaultDateEnd);	
    const [dateStartComp, setDateStartComp] = React.useState(defaultDateStartComp);
    const [dateEndComp, setDateEndComp] = React.useState(defaultDateEndComp);	
    const businessesModel = new BusinessesModel(apiContext.api);
    const userModel = new UsersModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();
    const [insights, setInsights] = React.useState({});	
    const [insightsComp, setInsightsComp] = React.useState({});
	const [reviews, setReviews] = React.useState([]);
    const [insightsSearchKeywords, setInsightsSearchKeywords] = React.useState({});
	const [metricValuesImp, setMetricValuesImp] = React.useState([]);
    const [metricLabelSelected, setMetricLabelSelected] = React.useState('Total');
	const [loading, setLoading] = React.useState(true);	
	const [compare, setCompare] = React.useState(false);
    const [userAuthenticated, setUserAuthenticated] = React.useState(false);
    const onProfileRefresh = (profile, lastUpdated) => {
        return true;
    }
    
	const metricLabels = {
        'Total': t('All Business interactions'),
        'BUSINESS_BOOKINGS': t('Bookings'),
        'BUSINESS_CONVERSATIONS': t('Messages'),
        'BUSINESS_DIRECTION_REQUESTS': t('Directions'),
        'BUSINESS_FOOD_MENU_CLICKS': t('Food Menu Clicks'),
        'BUSINESS_FOOD_ORDERS': t('Food Orders'),
        // 'BUSINESS_IMPRESSIONS_DESKTOP_MAPS': t('Desktop Maps Impressions'),
		// 'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH': t('Desktop Search Impressions'),
        // 'BUSINESS_IMPRESSIONS_MOBILE_MAPS': t('Mobile Maps Impressions'),
        // 'BUSINESS_IMPRESSIONS_MOBILE_SEARCH': t('Mobile Search Impressions'),
        'CALL_CLICKS': t('Calls'),
        'WEBSITE_CLICKS': t('Website Clicks'),
    };

    const metricLabelsImp = {
		
        'BUSINESS_IMPRESSIONS_MOBILE_SEARCH': t('Google Search – mobile'),
		'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH': t('Google Search – desktop'),
        'BUSINESS_IMPRESSIONS_MOBILE_MAPS': t('Google Maps – mobile'),
		'BUSINESS_IMPRESSIONS_DESKTOP_MAPS': t('Google Maps – desktop'), 
    };

	const getInsights = () => {
	 
		setLoading(true);		
				
		const diff 		=  Math.round((new Date(dateEnd).getTime() - new Date(dateStart).getTime())/ (1000 * 3600 * 24));
		
		setDateStartComp(new Date(Date.parse(dateStart) - (diff * 24 * 60 * 60 * 1000)).toISOString().slice(0,10));
		setDateEndComp(new Date(Date.parse(dateStart)).toISOString().slice(0,10));		
        
		businessesModel.insights(params.locationId, dateStart, dateEnd).then((response) => {
            if (response !== false) {
                setInsights(response);
            }
        });

		businessesModel.ightsSearchKeywords(params.locationId, dateStart, dateEnd).then((response) => {
            if (response !== false) {
                setInsightsSearchKeywords(response);
            }
        });
		
        businessesModel.getReviews(params.locationId).then((response) => {
            if (response !== false) {
				
				let reviewsArr = [];
                response.map((review, index) => {
					let starRatingNumeric = 0;
					if (review) {
						switch (review.starRating) {
							case 'ONE'	: starRatingNumeric = 1; break;
							case 'TWO'	: starRatingNumeric = 2; break;
							case 'THREE': starRatingNumeric = 3; break;
							case 'FOUR'	: starRatingNumeric = 4; break;
							case 'FIVE'	: starRatingNumeric = 5; break;
						}
					}
					review.rating = starRatingNumeric; 
					reviewsArr[index] = review;
                });
				
                setReviews(reviewsArr.sort((a,b) => (b.rating > a.rating) ? 1 : ((a.rating > b.rating) ? -1 : 0)));
            }
        });
		
		compareInsights();
    }
	
	const compareInsights = () => {		
		
        businessesModel.insights(params.locationId, dateStartComp, dateEndComp).then((response) => {
            if (response !== false) {
                setInsightsComp(response);
            }
        }).finally(() => {
            setLoading(false);

            setTimeout(checkAutomatedPrinting, 2000);
        });	
	}


    const getTemporaryAccess = async () => {
        if (user.type) {return;}
        const response = await businessesModel.getTemporaryAccess(params.locationId);

        if (response !== false) {
            let user = userModel.processAuthResponse(response);

            authContext.login(user);
            setUserAuthenticated(true);
            window.location.reload();
        }
    }


    getTemporaryAccess();

    
    useEffect(() => {
        if (!user.type) {return;}

        // apply printing styling
        document.querySelectorAll('[data-html2canvas-ignore="true"]').forEach((element) => {
            element.style.display = 'none';
        });
        let gap = document.querySelector('.m-block-gap');
        gap.style.marginBottom = "15px";

        let cards = document.querySelectorAll('.card');
        for (let index = 0; index < cards.length; index++) {
            cards[index].style.border = "1px solid #c7ccd0";
            cards[index].style.borderRadius = "7px";
        }


        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                breadcrumbs.update({
                    title: response.data.title,
                    breadcrumbs: [
                        {label: t('Businesses List'), url: '/businesses'},
                        {label: response.data.title, url: '/businesses/' + params.locationId},
                        {label: t('Insights')}
                    ]
                });

                document.title = t('Insights') + ' | ' + response.data.title + ' | LocalBoost';
            }
        }).finally(() => {
            getInsights();
            setCompare(false);
        });

        setDateStart(defaultDateStart);
        setDateEnd(defaultDateEnd);
    }, [userAuthenticated]);


    const checkAutomatedPrinting = () => {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('print')) {
            html2canvas(document.getElementById("insightsWrap"), {

                onclone: function (clonedDoc) {
                    // clonedDoc.getElementById('businessesReviews').style.display = 'block';
                    let gap = clonedDoc.querySelector('.m-block-gap');
                    gap.style.marginBottom = "15px";

                    let cards = clonedDoc.querySelectorAll('.card');
                    for (let index = 0; index < cards.length; index++) {
                        cards[index].style.border = "1px solid #c7ccd0";
                        cards[index].style.borderRadius = "7px";
                    }
                },
                scale: 3,

            }).then(canvas => {
                var imgData 	= canvas.toDataURL("PNG", 1.0);

                document.body.innerHTML = '';
                var img = new Image();
                img.src = imgData;
                document.body.appendChild(img);
                img.style.width = '100%';
                img.style.height = 'auto';

                // add padding 3% to body
                document.body.style.padding = '3%';

            });
        }
    }


    useEffect(() => {
        if (!user.type) {return;}
        getInsights();
		
    }, [dateStart, dateEnd]);
	
    useEffect(() => {
        if (!user.type) {return;}
        compareInsights();
		
    }, [dateStartComp, dateEndComp]);
	
    const getMetricSummary = (metricLabel, past=false) => {
	
		let data = past ? insightsComp : insights;
        let value = 0;
        if (data[metricLabel]) {
            Object.values(data[metricLabel]).forEach((item) => {
                value += parseInt(item);
				});
        } else if (metricLabel === 'Total') {
            ['BUSINESS_BOOKINGS', 'BUSINESS_CONVERSATIONS', 'BUSINESS_DIRECTION_REQUESTS', 'BUSINESS_FOOD_MENU_CLICKS', 'BUSINESS_FOOD_ORDERS', 'CALL_CLICKS', 'WEBSITE_CLICKS'].forEach((item) => {
                if (data[item]) {
                    Object.values(data[item]).forEach((item) => {
                        value += parseInt(item);
                    });
                }
            });
        }

        return value;
    }
	
	useEffect(() => {
        if (!user.type) {return;}
	
		let obj = [];
		
		Object.entries(metricLabelsImp).forEach(([key, value]) => {	

			obj.push(getMetricSummary(key));
		});

        setMetricValuesImp(obj);
    }, [insights]);
	
	const [donutOptions, setDonutOptions] = React.useState({
		
		series: Object.values(metricValuesImp),
		labels: Object.values(metricLabelsImp),
        chart: {
            type: "donut",
            fontFamily: '"Plus Jakarta Sans", "Open Sans", Arial, sans-serif',
			
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '80%'                    
                },
			},
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: false,
        },
        legend: {
            show: true,
			itemMargin: {
				horizontal: 5,
				vertical: 5
			},
			showForNullSeries: true,
			fontSize: '14px',
			formatter: function(seriesName, opts, impessionTotal) {
				
				let tot = opts.w.globals.series.reduce((a, b) => a + b, 0);
				let val = opts.w.globals.series[opts.seriesIndex];
				let pct = tot > 0 ? Math.round((val/tot)*100) : 0;
				return [
				
					'<b style="width:70px;display:inline-block;">',	val,' · ',pct,'%','</b>','',seriesName,			
				]
			},	
        },
		colors: ['#f29900', '#1a73e8', '#d93025', '#1e8e3e'],
        tooltip: {
            theme: "dark",
            fillSeriesColor: false,
        },
    });	
	
	const printDocument = async () => {
	
		setLoading(true);
		
		const pdf = new jsPDF('p', 'mm', 'a4',true);
		const element 	= document.getElementById('insightsWrap');
		
		html2canvas(document.getElementById("insightsWrap"), {
		
			onclone: function (clonedDoc) {
				// clonedDoc.getElementById('businessesReviews').style.display = 'block';				
				let gap = clonedDoc.querySelector('.m-block-gap');
				gap.style.marginBottom = "15px";
				
				let cards = clonedDoc.querySelectorAll('.card');
				for (let index = 0; index < cards.length; index++) {
					cards[index].style.border = "1px solid #c7ccd0";
					cards[index].style.borderRadius = "7px";
				}
			},
			scale: 3,
			
		}).then(canvas => {
		
			var imgData 	= canvas.toDataURL("PNG", 1.0);
			var data 		= canvas.toDataURL("image/png");
			let imgWidth 	= 190;
			let imgHeight 	= (canvas.height * imgWidth) / canvas.width;
			
			pdf.addImage(data, "PNG", 10, 10, imgWidth, imgHeight,'','FAST');
			pdf.save("report_"+new Date().toLocaleString()+".pdf");
			
			/*			
			var imgData = canvas.toDataURL('image/png');
			var imgWidth = 190; 
			var pageHeight = 295;  
			var imgHeight = canvas.height * imgWidth / canvas.width;
			var heightLeft = imgHeight;
			// var doc = new jsPDF('p', 'mm');
			var position = 10; // give some top padding to first page

			pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;

			while (heightLeft >= 0) {
			  position += heightLeft - imgHeight + 10; // top padding for other pages
			  pdf.addPage();
			  pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
			  heightLeft -= pageHeight;
			}
			pdf.save( 'file.pdf');			
			*/
			
		}).finally(() => {
            setLoading(false);
        });
		
	};	
	
	const formatDate =(date) =>{
	
		let monthNames = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
		let dt = new Date(date);
		return dt.getDate() + ' ' + monthNames[dt.getMonth()] + ' ' + dt.getFullYear();
	}
	
	const getMetricsDiff =(metricLabel) =>{
	
		let value = 0;
		let nowVal 	=	getMetricSummary(metricLabel);
		let pastVal =	getMetricSummary(metricLabel, true);
		
		if(pastVal == 0 && nowVal == 0){
		
			value = 0;
		}else if(pastVal > 0){
				
			value = Math.round(((nowVal - pastVal)/pastVal) * 10000)/100;
		}else{
			
			value = Math.round(((nowVal - 0.0001)/0.0001) * 10000)/100;
		}
        
		return value;
	}

    return (
        <>
            <div id="insightsWrap" style={{padding: '3%'}}>
				<div id="businessHeader">
					<BusinessViewHeader
						locationId={params.locationId}
						onProfileRefresh={onProfileRefresh}
					/>
				</div>				
				<div id="businessMenu" data-html2canvas-ignore="true" className="d-none">
					<BusinessViewMenu locationId={params.locationId} active="insights" />
				</div>				
				{insights && (
				<>
				<div id="insightsFilters" className={'row'}>
					<div className={'col-lg-12 d-flex align-items-stretch'}>
						<SimpleCardContainer
                            title={t('Date Range')}
                            loading={loading}
                            titleRight={user.type === 'admin' ? (<Link to={"/businesses/"+params.locationId+"/insights/reporting"} data-html2canvas-ignore="true" className="btn btn-sm btn-light-primary text-primary d-none">{t('Schedule Reporting')}</Link>) : ''}
                        >
							<div className={'row'}>
								<div className="col-lg-5">
									<div className="row">
										<div className="col-lg-6">
											<div className={'form-group py-2'}>
												<label htmlFor="cf-summary" className="form-label fw-semibold">{t('Start Date')}</label>
												<input type="date" className={'form-control'} defaultValue={dateStart} onChange={(e) => {setDateStart(e.target.value);/*document.getElementById('dateStartComp').dispatchEvent(new Event('change', { 'bubbles': true }));*/}} />
											</div>
										</div>
										<div className="col-lg-6">
											<div className={'form-group py-2'}>
												<label htmlFor="cf-summary" className="form-label fw-semibold">{t('End Date')}</label>
												<input type="date" className={'form-control'} defaultValue={dateEnd} onChange={(e) => {setDateEnd(e.target.value);/*document.getElementById().value=dateStartComp;*/}} />
											</div>
										</div>
									</div>
								</div>
								 <div className="col-lg-4" style={{'position':'relative'}} >
									<div className="custom-control custom-checkbox d-none" style={{'position':'absolute','left':'9px','top':'-20px'}} data-html2canvas-ignore={compare ? "false" : "true"}>
										<input type="checkbox" className="custom-control-input me-1" checked={compare} onChange={(e) => {setCompare(e.target.checked ? true : false);}}/>
										<label className="custom-control-label" htmlFor="custom-checkbox">{t('Compare to previous period')}</label>
									</div>
									<div className="row {compare ? '' : 'bg-light-primary'}" style={{'position':'relative', 'paddingLeft':'20px','borderRadius':'7px','opacity': compare ? '1' : '0.25'}}>
										<span style={{'position':'absolute','bottom':'17px','left':'9px','width':'auto','padding':'0'}}>vs</span>
										<div className="col-lg-6">
											<div className={'form-group py-2'}>
												<label htmlFor="cf-summary" className="form-label fw-semibold">{t('Start Date')}</label>
												<input id="dateStartComp" type="date" className={'form-control'} defaultValue={dateStartComp} onChange={(e) => {setDateStartComp(e.target.value)}} disabled={compare ? '' : 'disabled'} />
											</div>
										</div>
										<div className="col-lg-6">
											<div className={'form-group py-2'}>
												<label htmlFor="cf-summary" className="form-label fw-semibold">{t('End Date')}</label>
												<input id="dateEndComp" type="date" className={'form-control'} defaultValue={dateEndComp} onChange={(e) => {setDateEndComp(e.target.value)}}  disabled={compare ? '' : 'disabled'} />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-3 d-none" data-html2canvas-ignore="true">
									<button className={'d-inline-flex align-items-center btn btn-primary'} onClick={(e) => {printDocument()}} style={{'position':'absolute','bottom':'33px','right':'25px'}}>{t('Download Report')}</button>
								</div>
							</div>
						</SimpleCardContainer>
					</div>
				</div>				
				<div id="insightsCards" className={'row m-block-gap'}>
					{Object.entries(metricLabels).map(([key, label]) => (
					<div className="col-lg-3 m-block-gap" key={key}>
						<div className={'card border-0 mb-0 h-100 metrics-tabs zoom-in shadow-none '+(metricLabelSelected === key ? 'bg-primary' : 'bg-light-primary')} onClick={(e) => {setMetricLabelSelected(key)}}>
							<div className="card-body">
								<div className={'text-center '+(metricLabelSelected === key ? 'text-white' : 'text-primary')}>
									<i className="ti ti-report-analytics fs-6"></i>
									<p className="fw-semibold fs-3 mb-1">{label}</p>
									<h5 className={'fw-semibold mb-0 '+(metricLabelSelected === key ? 'text-white' : 'text-primary')}>{getMetricSummary(key)}</h5>
									{compare && (
										<div className={'mt-1 pt-1 '+(metricLabelSelected === key ? 'text-white' : 'text-primary')} style={{borderTop: '1px solid'}}>
											<i className={"ti ti-stairs-" + (getMetricsDiff(key) > 0 ? 'up' : 'down') + " fs-4"}  style={{/*fontSize : '15px !important', 'marginRight': '2px'*/}}></i>{getMetricsDiff(key)+'%'} 
											<div className="fs-1">(vs {formatDate(dateStartComp) + ' - ' + formatDate(dateEndComp)})</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					))}
				</div>
				<div id="insightsChart" className={'row'}>
					<div className="col-lg-12 d-flex align-items-stretch chartStretch">
						<div className="card w-100 position-relative overflow-hidden">
							<div className="card-body">
								<div className="d-flex align-items-end justify-content-between">
									<div>
										<p className="mb-0">{metricLabels[metricLabelSelected]}</p>
										<h4 className="mb-0 fw-semibold">{getMetricSummary(metricLabelSelected)}</h4>
									</div>
								</div>
								<BusinessesInsightsChart insights={insights} metricLabel={metricLabelSelected} />
							</div>
						</div>
					</div>
				   
				</div>			
				</>)}			
				<div id="insightsImpressions" className={'row'}>
					<div className="col-lg-6 d-flex align-items-stretch">
						<SimpleCardContainer title={t('Impressions')}>
							<div className={'row'}><b>{t('Platform and device breakdown')}</b></div>
							<div className={'row'}><p>{t('Platform and devices that people used to find your profile')}</p></div>
							<div className={'row'}>
								<div className="col-lg-12">							
									<Chart type={'donut'} options={donutOptions} series={metricValuesImp} width={'100%'} height={180} className={''}/>
								</div>							
							</div>
						</SimpleCardContainer>
					</div>
					{insightsSearchKeywords && (
					<>
						<div className={'col-lg-6 d-flex align-items-stretch'}>
							<SimpleCardContainer title={t('Search Keywords')}>
								<div className={'row'}><b>{t('Searches breakdown')}</b></div>
								<div className={'row'}><p>{t('Search terms that showed your Business Profile in the search results')}</p></div>
								<div className={'row'}>
									{Object.entries(insightsSearchKeywords).map(([key, searchkeyword]) => {									
										return (
											
											<Fragment key={key}>
												<div className="col-lg-10"><b style={{'display':'inline-block','width':'25px'}}>{key}. </b>{searchkeyword.title}</div>
												<div className="col-lg-2">{searchkeyword.threshold}</div>
											</Fragment>
										)
									})}
								</div>
							</SimpleCardContainer>
						</div>
					</>)}
					<div id="businessesReviews" style={{'display':'block'}}>
						<SimpleCardContainer title={t('Top Reviews')}>
							<div className={'row mb-2'}>
								<div className="col-lg-10">{t('Review')}</div>
								<div className="col-lg-2">{t('Rating')}</div>
							</div>
								{reviews.map((review, index) => {
									
									if(index < 5){
										
										return (									
										
											<div className={'row review-'+index} key={index}>
												<div className={'col-lg-10'}>
													<i className="ti ti-message fs-3 me-1"></i>
													<b className="fs-3">{review.reviewer.displayName}</b> -&nbsp;
													<span className="text-muted">"
													{review.comment && (
														StrHelper.filterGoogleTranslatedText(review.comment).split(' ').map((word, wordIndex, words) => (
															(((wordIndex < 10)?(word+' '):('')) + ((wordIndex === 10 && words.length > 10)?('...'):('')))
														))
													) || !review.comment && (
														<span>{t('No text.')}</span>
													)}
													"</span>
												</div>
												<div className={'col-lg-2'}>{review.rating}.0</div>
											</div>
										
										);										
									}
								})}
								
						</SimpleCardContainer>
					</div>
				</div>
			</div>
        </>
    );
}