import React, {useState, useCallback} from "react";

function StarRating(props) {

    let classesDefault = 'list-unstyled d-flex align-items-end';
    if (props.className) {
        classesDefault += ' ' + props.className;
    }

    let rating = props.rating ? props.rating : 0;

    return (
        <ul className={classesDefault}>
            {[...Array(Math.ceil(rating)).keys()].map((item, index) => {
                let starContainerClass = 'ms-1';
                if (index === 0) {
                    starContainerClass = '';
                }

                let starClass = 'ti-star-filled';
                if (index === Math.floor(rating) && index !== rating) {
                    starClass = 'ti-star-half-filled';
                } else if (index >= Math.ceil(rating) && index !== rating) {
                    starClass = 'd-none';
                }

                return (
                    <li key={index} className={starContainerClass}><i className={'ti text-warning '+starClass}></i></li>
                );
            })}
            {(rating > 0) && (
                <li className="ms-1"><span className="fs-1 fw-semibold lh-lg text-warning">{rating}</span></li>
            )}
        </ul>
    )
}

export default StarRating;