import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import Alert from "../ui/Alert";
import {useTranslation} from "react-i18next";

function PersonalInfoCard(props) {
    const { t} = useTranslation();
    const [userName, setUserName] = React.useState(props.name);
    const [userEmail, setUserEmail] = React.useState(props.email);
    const [alertNotification, setAlertNotification] = React.useState({type: null, text: null});


    useEffect(() => {
        setUserName(props.name);
        setUserEmail(props.email);
    }, [props.name, props.email]);


    const onSubmit = (e) => {
        e.preventDefault();

        const newPersonalInfo = {
            name: userName,
            email: userEmail
        };

        props.accountModel.changePersonalInfo(newPersonalInfo).then((response) => {
            if (response !== false) {
                setAlertNotification({
                    type: 'success',
                    text: t('Information changed successfully.')
                });

                props.resetPersonalInfo();
            } else {
                setAlertNotification({
                    type: 'danger',
                    text: t('Information change failed.')
                });
            }
        });
    }


    const onCancel = (e) => {
        e.preventDefault();

        props.resetPersonalInfo();
    }


    return (
        <div className="card w-100 position-relative overflow-hidden">
            <div className="card-body p-4">
                <h5 className="card-title fw-semibold">{t('Personal Details')}</h5>
                <p className="card-subtitle mb-4">{t('To change your personal detail, edit and save from here.')}</p>

                {alertNotification.text && (
                    <Alert
                        type={alertNotification.type}
                        text={alertNotification.text}
                    />
                )}

                <form>
                    <div className="mb-4">
                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('Your Name')}</label>
                        <input type="text" className="form-control" id="exampleInputtext" defaultValue={userName} onChange={(e) => {setUserName(e.target.value)}} />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('Email')}</label>
                        <input type="email" className="form-control" id="exampleInputtext" defaultValue={userEmail} onChange={(e) => {setUserEmail(e.target.value)}} />
                    </div>

                    <div className="d-flex align-items-center justify-content-end mt-4 gap-3">
                        <button className="btn btn-primary" onClick={onSubmit}>{t('Save')}</button>
                        <button className="btn btn-light-danger text-danger" onClick={onCancel}>{t('Cancel')}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PersonalInfoCard;
