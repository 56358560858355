import React from 'react';
import {useTranslation} from "react-i18next";

function BusinessFormSaveCancel(props) {
    const { t} = useTranslation();

    return (
        <div className="d-flex align-items-center justify-content-start mt-3 gap-3">
            <button onClick={props.onSubmit} className="d-inline-flex align-items-center justify-content-center btn btn-primary">
                <span
                    className={'spinner-border spinner-border-sm me-2 '+((props.saveLoading)?(''):('d-none'))}
                    role="status"
                    aria-hidden="true"
                ></span>
                {t('Save')}
            </button>

            <button className="btn btn-light-danger text-danger" onClick={props.onCancel}>{t('Cancel')}</button>
        </div>
    );
}

export default BusinessFormSaveCancel;
