import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";

import imgProfileDefault from 'modernize-essentials/dist/images/profile/user-1.jpg';
import {Link} from "react-router-dom";
import Alert from "../ui/Alert";
import AccountModel from "../../models/AccountModel";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

function ConnectedAccountsTab(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const apiContext = useApi();
    const accountModel = new AccountModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();

    const [alertNotification, setAlertNotification] = React.useState({type: null, text: null});
    const [gAccounts, setGAccounts] = React.useState([]);


    const resetGAccounts = () => {
        accountModel.getGAccounts().then((response) => {
            if (response !== false) {
                let newGAccounts = [];
                for (let i = 0; i < response.length; i++) {
                    let gAccount = response[i];
                    newGAccounts.push({
                        id: gAccount.id,
                        email: gAccount.email,
                        businessesCount: gAccount.businesses_count,
                        requiresReauth: gAccount.requires_reauth,
                    });
                }

                setGAccounts(newGAccounts);
            }
        });
    };


    useEffect(() => {
        breadcrumbs.update({
            title: t('Connected Accounts'),
            breadcrumbs: [
                {label: t('Account Settings'), url: '/settings'},
                {label: t('Connected Accounts')}
            ]
        });

        document.title = t('Connected Accounts')+' | LocalBoost';

        resetGAccounts();
    }, [language]);


    const onNewClick = (e) => {
        e.preventDefault();
    }


    const onGAccountDelete = (e, gAccountId) => {
        e.preventDefault();

        accountModel.deleteGAccount(gAccountId).then((response) => {
            if (response !== false) {
                let gAccount = gAccounts.find((gAccount) => {
                    return gAccount.id === gAccountId;
                });

                if (gAccount) {
                    setAlertNotification({
                        type: 'success',
                        text: t('Google Account {{email}} deleted successfully.', {email: gAccount.email})
                    });
                }

                resetGAccounts();
            } else {
                setAlertNotification({
                    type: 'danger',
                    text: t('There was an error deleting the Google Account.')
                });
            }
        });
    }


    return (
        <div className="card">
            <div className="card-body p-4">
                <div className="row">
                    <div className="col-lg-10">
                        <h4 className="fw-semibold mb-3">{t('Connected Google Accounts')}</h4>
                        <div className="d-flex align-items-center justify-content-between pb-7">
                            <p className="mb-0">{t('These accounts are used to update businesses list.')}</p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="d-flex justify-content-end pb-3">
                            <button className="d-inline-flex align-items-center justify-content-center btn btn-info btn-circle btn-lg" onClick={onNewClick}>
                                <i className="fs-5 ti ti-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>

                {alertNotification.text && (
                    <Alert
                        type={alertNotification.type}
                        text={alertNotification.text}
                    />
                )}

                {gAccounts.length > 0 && gAccounts.map((gAccount, index) => (
                <div className="d-flex align-items-center justify-content-between py-3 border-top" key={index}>
                    <div>
                        <h5 className="fs-4 fw-semibold mb-0">{gAccount.email}</h5>
                        <p className="mb-0">{t('Tracking Businesses')}: {gAccount.businessesCount}</p>
                    </div>
                    <button className="btn btn-light-danger text-danger" onClick={(e) => {onGAccountDelete(e, gAccount.id)}}><i className="ti ti-trash"></i></button>
                </div>
                ))}
            </div>
        </div>
    );
}

export default ConnectedAccountsTab;
