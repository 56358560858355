import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {Link} from "react-router-dom";

import BusinessCard from "../businesses/BusinessCard";
import {useApi} from "../../providers/ApiProvider";
import BusinessesModel from "../../models/BusinessesModel";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

function Home(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const [dataItems, setDataItems] = React.useState({});


    useEffect(() => {
        breadcrumbs.update({
            title: t('Welcome to Localboost'),
            breadcrumbs: []
        });

        document.title = t('Welcome')+' | LocalBoost';


        businessesModel.list().then((response) => {
            if (response !== false) {
                setDataItems(response);
            }
        });
    }, [language]);


    return (
        <>
            <div className="row">
                {dataItems && Object.keys(dataItems).map((accountKey, accIndex) => {
                    return Object.keys(dataItems[accountKey]).map((businessId, bIndex) => {
                        return (
                            <div className="col-sm-6 col-xl-3" key={accIndex+'-'+bIndex}>
                                <BusinessCard item={dataItems[accountKey][businessId]} />
                            </div>
                        );
                    });
                })}
                <div className="col-sm-6 col-xl-3">
                    <div className="card card-add">
                        <div className="align-items-center card-body d-flex flex-column justify-content-center text-center">
                            <i className="ti ti-plus mb-5"></i>

                            <h5 className="fw-semibold fs-5 mb-2">{t('Connect a new Business')}</h5>
                            <p className="mb-3">{t('Sync existing location or create a new one.')}</p>
                            <div className="d-flex flex-column gap-3 w-100">
                                {/*<Link to="/businesses/create" className="align-items-center justify-content-center btn waves-effect waves-light btn-light-primary text-primary">
                                    <i className="fs-4 ti ti-plus me-2"></i> {t('Create a new Business')}
                                </Link>*/}
                                <Link to="/businesses/add" className="align-items-center justify-content-center btn btn-primary">
                                    <i className="fs-4 ti ti-link me-2"></i> {t('Connect a Location')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
);
}

export default Home;
