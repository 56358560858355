import React, {useCallback, useEffect} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import BusinessViewMenu from "../BusinessViewMenu";
import BusinessesMediaModel from "../../../models/BusinessesMediaModel";
import {useDropzone} from 'react-dropzone'
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import BusinessesMediaAddFile from "./BusinessesMediaAddFile";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";


function BusinessesMediaAdd(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();
    const onDrop = useCallback(acceptedFiles => {
        setFilesToUpload(acceptedFiles);
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const [selectedCategory, setSelectedCategory] = React.useState('ADDITIONAL');
    const [uploadRunning, setUploadRunning] = React.useState(false);
    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [fileUploadStatuses, setFileUploadStatuses] = React.useState({});
    const [uploadComplete, setUploadComplete] = React.useState(false);


    const onUploadClick = () => {
        let statuses = {};
        for (let i = 0; i < filesToUpload.length; i++) {
            statuses[i] = 'pending';
        }
        setFileUploadStatuses(statuses);

        setUploadRunning(true);
    }


    const onCategoryChanged = (category) => {
        setSelectedCategory(category);
    }


    const keysToLabels = {
        'COVER': t('Cover photos'),
        'PROFILE': t('Profile photos'),
        'LOGO': t('Logos'),
        'EXTERIOR': t('Exterior media'),
        'INTERIOR': t('Interior media'),
        'PRODUCT': t('Products media'),
        'AT_WORK': t('At-work'),
        'FOOD_AND_DRINK': t('Food and drink'),
        'MENU': t('Menu'),
        'COMMON_AREA': t('Common area'),
        'ROOMS': t('Rooms'),
        'TEAMS': t('Teams'),
        'ADDITIONAL': t('Additional')
    };


    const onProfileRefresh = (profile, lastUpdated) => {
        return true;
    }


    const onPerformUpload = (index, status) => {
        setFileUploadStatuses(prevStatus => ({
            ...prevStatus,
            [index]: status
        }));
    }


    useEffect(() => {
        onUploadClick();
    }, [filesToUpload]);


    useEffect(() => {
        let statuses = Object.values(fileUploadStatuses);
        if (statuses.length === 0) {return;}

        if (statuses.indexOf('pending') === -1 && statuses.indexOf(false) === -1) {
            setUploadComplete(true);
        }

        if (statuses.indexOf('pending') === -1) {
            setUploadRunning(false);
        }
    }, [fileUploadStatuses]);


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                breadcrumbs.update({
                    title: response.data.title,
                    breadcrumbs: [
                        {label: t('Businesses List'), url: '/businesses'},
                        {label: response.data.title, url: '/businesses/'+params.locationId},
                        {label: t('All business media'), url: '/businesses/'+params.locationId+'/media'},
                        {label: t('Add a new media')}
                    ]
                });

                document.title = t('Add a new media')+' | '+response.data.title+' | LocalBoost';
            }
        });
    }, [language]);


    useEffect(() => {
        setFileUploadStatuses({});
        setUploadComplete(false);
        setUploadRunning(false);
        setFilesToUpload([]);
    }, [selectedCategory]);


    return (
        <>
        {/*uploadComplete && (
            <Navigate to={'/businesses/'+params.locationId+'/media'} />
        )*/}

        <BusinessViewHeader
            locationId={params.locationId}
            onProfileRefresh={onProfileRefresh}
        />

        <BusinessViewMenu locationId={params.locationId} active="media" />

        <div className="d-flex m-block-gap">
            <Link to={'/businesses/'+params.locationId+'/media'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                {t('Back to all media')}
            </Link>
        </div>

        <SimpleCardContainer title={t('Add a new media')}>
            <div className="row">
                <div className="col-lg-4">
                    <p className="mb-2">{t('Category')}:</p>
                    <select className="form-control form-select" value={selectedCategory} onChange={(e) => {onCategoryChanged(e.target.value)}}>
                        {Object.keys(keysToLabels).map((key, index) => {
                            return (
                                <option
                                    value={key}
                                    key={index}
                                >{keysToLabels[key]}</option>
                            )
                        })}
                    </select>

                    <p className="mb-0 mt-3">{t('Please, uploaded media')}:</p>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-lg-6">
                    <div {...getRootProps()} className={'drop-area'+((isDragActive)?' active':'')}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p className="m-0">{t('Drop the files here...')}</p> :
                                <p className="m-0">{t('Drag \'n\' drop some files here, or click to select files')}</p>
                        }
                    </div>
                    {selectedCategory === 'COVER' && (
                        <p className="mt-2 fs-2 fw-semibold text-warning">{t('Should be in 16:9 ratio, e.g. 1920×1080.')}</p>
                    )}

                    <div className="mt-3">
                        {filesToUpload && filesToUpload.map((file, index) => (
                            <BusinessesMediaAddFile
                                key={index}
                                file={file}
                                selectedCategory={selectedCategory}
                                performUpload={uploadRunning}
                                onPerformUpload={(status) => {
                                    onPerformUpload(index, status);
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/*
            <ButtonWithLoading
                className="btn-primary justify-content-center text-white mt-3"
                iconClassName="ti-upload"
                label="Upload"
                onClick={onUploadClick}
                loading={uploadRunning}
            />*/}

        </SimpleCardContainer>
        </>
    );
}

export default BusinessesMediaAdd;
