/**
 * Businesses Widgets model
 * TODO - change widget managment, add delete function and multiple items
 * @param {ApiHelper} api
 */
class WidgetsModel {
	
    constructor(api) {
        this.api = api;
    }

    async get(locationId) {
	
        const url = '/widgets/' + locationId + '/widget';
		
        const response = await this.api.getRequest(url);
		
        return (response.success === true)?(response.data):([]);
    }
	
	async update(locationId, widgetId, data, errorHandler) {
        const url = '/widgets/' + locationId + '/widget/' + widgetId + '/update';

        const response = await this.api.postRequestFile(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }
	
	// async delete(locationId, widgetId, data, errorHandler) {
        
		// const url = '/widgets/' + locationId + '/widget/' + widgetId + '/delete';
		// const response = await this.api.deleteRequest(url);
        
		// return (response !== false);
    // }
}

export default WidgetsModel;