import React from "react";

export default function TableLoading(props) {
    if (props.loading === true) {
        return (
            <tr>
                {
                    [...Array(props.columns)].map((item, index) => {
                        return (
                            <td className="align-middle text-nowrap" key={index}>
                                <div className="progress mt-4">
                                    <div className="progress-bar progress-bar-striped bg-dark progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}></div>
                                </div>
                            </td>
                        );
                    })
                }
            </tr>
        );
    } else {
        return (<></>);
    }
}