import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";

import AccountModel from "../../models/AccountModel";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

function BillingInfoTab(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const apiContext = useApi();
    const accountModel = new AccountModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();


    useEffect(() => {
        breadcrumbs.update({
            title: t('Billing Information'),
            breadcrumbs: [
                {label: t('Account Settings'), url: '/settings'},
                {label: t('Billing Information')}
            ]
        });

        document.title = t('Billing Information')+' | LocalBoost';
    }, [language]);


    const onEdit = (pk) => {
    }


    return (
        <div className="row justify-content-center">
            <div className="col-lg-9">
                <div className="card">
                    <div className="card-body p-4">
                        <h4 className="fw-semibold mb-3">{t('Billing Information')}</h4>
                        <form>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('Business Name')}*</label>
                                        <input type="text" className="form-control" id="exampleInputtext" placeholder={t('Visitor Analytics')} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('Business Address')}*</label>
                                        <input type="text" className="form-control" id="exampleInputtext" placeholder=""/>
                                    </div>
                                    <div className="">
                                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('First Name')}*</label>
                                        <input type="text" className="form-control" id="exampleInputtext" placeholder=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('Business Sector')}*</label>
                                        <input type="text" className="form-control" id="exampleInputtext" placeholder={t('Arts, Media & Entertainment')}/>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('Country')}*</label>
                                        <input type="text" className="form-control" id="exampleInputtext" placeholder={t('Romania')}/>
                                    </div>
                                    <div className="">
                                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('Last Name')}*</label>
                                        <input type="text" className="form-control" id="exampleInputtext" placeholder=""/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-lg-9">
                <div className="card">
                    <div className="card-body p-4">
                        <h4 className="fw-semibold mb-3">{t('Current Plan')}: <span className="text-success">{t('Executive')}</span></h4>
                        <p>{t('Thanks for being a premium member and supporting our development.')}</p>
                        <div className="d-flex align-items-center justify-content-between mt-7 mb-3">
                            <div className="d-flex align-items-center gap-3">
                                <div className="bg-light rounded-1 p-6 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-package text-dark d-block fs-7" width="22" height="22"></i>
                                </div>
                                <div>
                                    <p className="mb-0">{t('Current Plan')}</p>
                                    <h5 className="fs-4 fw-semibold">{t('750.000 Monthly Visits')}</h5>
                                </div>
                            </div>
                            <a className="text-dark fs-6 d-flex align-items-center justify-content-center bg-transparent p-2 fs-4 rounded-circle"
                               href="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={t('Add')}>
                                <i className="ti ti-circle-plus"></i>
                            </a>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                            <button className="btn btn-primary">{t('Change Plan')}</button>
                            <button className="btn btn-outline-danger">{t('Reset Plan')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-9">
                <div className="card">
                    <div className="card-body p-4">
                        <h4 className="fw-semibold mb-3"></h4>
                        <p>On 26 December, 2023</p>
                        <div className="d-flex align-items-center justify-content-between mt-7">
                            <div className="d-flex align-items-center gap-3">
                                <div className="bg-light rounded-1 p-6 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-credit-card text-dark d-block fs-7" width="22" height="22"></i>
                                </div>
                                <div>
                                    <h5 className="fs-4 fw-semibold">Visa</h5>
                                    <p className="mb-0 text-dark">*****2102</p>
                                </div>
                            </div>
                            <a className="text-dark fs-6 d-flex align-items-center justify-content-center bg-transparent p-2 fs-4 rounded-circle"
                               href="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Edit">
                                <i className="ti ti-pencil-minus"></i>
                            </a>
                        </div>
                        <p className="my-2">{t('If you updated your payment method, it will only be dislpayed here after your next billing cycle.')}</p>
                        <div className="d-flex align-items-center gap-3">
                            <button className="btn btn-outline-danger">{t('Cancel Subscription')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="d-flex align-items-center justify-content-end gap-3">
                    <button className="btn btn-primary">{t('Save')}</button>
                    <button className="btn btn-light-danger text-danger">{t('Cancel')}</button>
                </div>
            </div>
        </div>
    );
}

export default BillingInfoTab;
