/**
 * Users model
 *
 * @param {ApiHelper} api
 */
class AccountModel {
    constructor(api) {
        this.api = api;
    }


    async changePassword(oldPassword, newPassword) {
        const url = '/accounts/update-password';
        const data = {
            old_password: oldPassword,
            new_password: newPassword
        };

        const response = await this.api.postRequest(url, data);
        return (response.success === true)?(response.data):false;
    }


    async changeAvatar(formData) {
        const url = '/accounts/update-avatar';

        const response = await this.api.postRequestFile(url, formData);
        return (response.success === true)?(response.data):false;
    }


    async getPersonalInfo() {
        const url = '/accounts/get-personal-info';

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):false;
    }


    async changePersonalInfo(newPersonalInfo) {
        const url = '/accounts/update-personal-info';
        const data = {
            name: newPersonalInfo.name,
            email: newPersonalInfo.email
        };

        const response = await this.api.postRequest(url, data);
        return (response.success === true)?(response.data):false;
    }

    async getGeneralInfo() {
        const url = '/accounts/get-general-info';

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):false;
    }


    async getGAccounts() {
        const url = '/accounts/gaccounts/get-all';

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):false;
    }

    async deleteGAccount(gAccountId) {
        const url = '/accounts/gaccounts/delete/'+gAccountId

        const response = await this.api.deleteRequest(url);
        return (response.success === true)?(response.data):false;
    }
}

export default AccountModel;