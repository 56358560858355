import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";

import imgProfileDefault from '../../assets/img/blank.png';
import Alert from "../ui/Alert";
import {useTranslation} from "react-i18next";

function ProfilePhotoCard(props) {
    const { t} = useTranslation();
    const userAvatar = props.personalInfo.avatar ? props.personalInfo.avatar : imgProfileDefault;

    const [alertNotification, setAlertNotification] = React.useState({type: null, text: null});


    const onFileChange = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        props.accountModel.changeAvatar(formData).then((response) => {
            if (response !== false) {
                setAlertNotification({
                    type: 'success',
                    text: t('Information changed successfully.')
                });

                props.resetPersonalInfo();
            } else {
                setAlertNotification({
                    type: 'danger',
                    text: t('Information change failed.')
                });
            }
        });
    }


    const triggerFileChange = (e) => {
        e.preventDefault();

        document.querySelector('#avatar-change-file').click();
    }


    return (
        <div className="card w-100 position-relative overflow-hidden">
            <div className="card-body p-4">
                <h5 className="card-title fw-semibold">{t('Change Profile')}</h5>
                <p className="card-subtitle mb-4">{t('Change your profile picture from here.')}</p>

                {alertNotification.text && (
                    <Alert
                        type={alertNotification.type}
                        text={alertNotification.text}
                    />
                )}

                <div className="text-center">
                    <img src={userAvatar} alt="Profile pic" className="img-fluid rounded-circle" width="120" height="120"/>

                    <form>
                        <input type="file" className="d-none" id="avatar-change-file" onChange={onFileChange} />

                        <div className="d-flex align-items-center justify-content-center my-4 gap-3">
                            <button className="btn btn-primary" onClick={triggerFileChange}>{t('Upload')}</button>
                        </div>
                    </form>

                    <p className="mb-0">{t('Allowed JPG, GIF or PNG. Max size of 2 MB.')}</p>
                </div>
            </div>
        </div>
    );
}

export default ProfilePhotoCard;
