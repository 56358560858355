import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useParams} from "react-router-dom";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useTranslation} from "react-i18next";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";
import ErrorsHelper from "../../../helpers/ErrorsHelper";

function BusinessFormDescription(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [description, setDescription] = React.useState('');
    const [suggestionLoading, setSuggestionLoading] = React.useState(false);
    const [saveLoading, setSaveLoading] = React.useState(false);


    useEffect(() => {
        setDescription(props.data);
    }, [props.data]);


    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        businessesModel.update(params.locationId, {profile: {description: description}}, saveErrorHandler).then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
            }

            setSaveLoading(false);
            setEditMode(false);
        });
    }


    const saveErrorHandler = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
    }


    const onCancel = (e) => {
        e.preventDefault();

        setDescription(props.data);
        setEditMode(false);
    }


    const onSuggest = (e) => {
        e.preventDefault();

        setSuggestionLoading(true);

        businessesModel.suggestDescription(params.locationId).then((response) => {
            if (response !== false) {
                setDescription(response);
            }
        }).finally(() => {
            setSuggestionLoading(false);
        });
    }


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <BusinessFormHeadingProgress
                score={props.score}
                editMode={editMode}
                title={t('Description')}
                id="description"
            />

            {editMode === false && (
                <div className="pt-2 p-small-margins">{description.split('\n').map((str, index, array) =>
                    ((index === array.length - 1) || (!str.length)) ? str : <p key={index}>{str}</p>
                )}</div>
            )}
            {editMode === true && (
                <>
                <div className="fs-2 mb-3 mt-1">{t('Describe your business to customers on Google.')}</div>
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <textarea className={'form-control'+((description.length <= 750)?(''):(' is-invalid'))} rows="10" id="cf-description" value={description} onChange={(e) => {setDescription(e.target.value)}}></textarea>
                            <div className="text-end"><small className="form-text text-muted">{description.length} / 750</small></div>
                        </div>

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel} />
                    </div>
                    <div className="col-md-6">
                        <div className="bordered-tip pb-4">
                            <div className="alert alert-info" role="alert">
                                <strong>{t('Improve your visibility with AI')}</strong>
                                <span className="d-block fs-2 mt-2">{t('Use our suggestions, based on your business name, categories and other data.')}</span>
                            </div>

                            <div className={'d-flex align-items-center justify-content-between gap-3 mt-3'}>
                                <button onClick={onSuggest} className="d-inline-flex align-items-center justify-content-center btn btn-dark">
                                    <span
                                        className={'spinner-border spinner-border-sm me-2 '+((suggestionLoading)?(''):('d-none'))}
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <i className={'fs-4 ti ti-code me-2 '+((suggestionLoading)?('d-none'):(''))}></i>
                                    {t('Suggest with AI')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </div>
        </>
    );
}

export default BusinessFormDescription;
