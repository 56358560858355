/**
 * Businesses Posts model
 *
 * @param {ApiHelper} api
 */
class BusinessesPostsModel {
    constructor(api) {
        this.api = api;
    }


    async list(locationId) {
        const url = '/businesses/' + locationId + '/posts';

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):([]);
    }


    async generate(locationId, data) {
        const url = '/businesses/' + locationId + '/posts/generate';

        const response = await this.api.postRequest(url, data);
        return (response.success === true)?(response.data):false;
    }


    async sync(locationId) {
        const url = '/businesses/' + locationId + '/posts/sync';

        const response = await this.api.postRequest(url, {});
        return (response.success === true)?(response.data):false;
    }


    async view(locationId, postId) {
        let url = '/businesses/' + locationId + '/posts/' + postId;

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):false;
    }


    async resync(locationId, postId) {
        let url = '/businesses/' + locationId + '/posts/' + postId + '?reSync=true';

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):(false);
    }


    async create(locationId, data, errorHandler) {
        const url = '/businesses/' + locationId + '/posts/create';

        const response = await this.api.postRequestFile(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async update(locationId, postId, data, errorHandler) {
        const url = '/businesses/' + locationId + '/posts/' + postId + '/update';

        const response = await this.api.postRequestFile(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async delete(locationId, postId) {
        const url = '/businesses/' + locationId + '/posts/' + postId + '/delete';

        const response = await this.api.deleteRequest(url);
        return (response !== false);
    }


    validatePostData(data) {
        let errors = {};

        if (!data.summary || data.summary.length < 1) {
            errors.summary = ['Summary is required'];
        } else if (data.summary.length > 1500) {
            errors.summary = ['Summary must be less than 1500 characters'];
        }

        if (data.callToAction && data.callToAction.actionType && !['ACTION_TYPE_UNSPECIFIED', 'CALL'].includes(data.callToAction.actionType) && (!data.callToAction.url || data.callToAction.url.length < 1)) {
            errors['call_to_action.url'] = ['Link for your button is required'];
        }


        if (data.event && data.event.schedule) {
            const schedule = data.event.schedule;
            if (schedule.startDate && schedule.endDate) {
                if (new Date(schedule.startDate) > new Date(schedule.endDate)) {
                    errors['event.schedule.startDate'] = ['Start date must be before end date'];
                }
            }
        }

        return errors;
    }
}

export default BusinessesPostsModel;