import React from 'react';


export default function Pagination({currentPage, totalPages, onPageChange}) {
    currentPage = parseInt(currentPage);
    totalPages = parseInt(totalPages);

    const onPageClick = (e, page) => {
        e.preventDefault();
        onPageChange(page);
    }

    // Generate page numbers
    const pages = [];
    let startPage, endPage;

    if (totalPages <= 5) {
        // less than 5 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 5 total pages so calculate start and end pages
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }

    for(let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }

    return (
        <nav>
            <ul className="pagination justify-content-center mb-0 mt-4">
                <li className="page-item">
                    <a href="#" onClick={(e) => {onPageClick(e, currentPage-1)}}
                       className={'page-link mx-1 border-0 rounded-circle text-dark round-32 d-flex align-items-center justify-content-center' + ((currentPage < 2) ? ' disabled' : '')}>
                        <i className="ti ti-chevron-left"></i>
                    </a>
                </li>

                {pages.map((page) => (
                    <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                        <a href="#" onClick={(e) => {onPageClick(e, page)}}
                           className="page-link border-0 rounded-circle round-32 mx-1 d-flex align-items-center justify-content-center">
                            {page}
                        </a>
                    </li>
                ))}

                <li className="page-item">
                    <a href="#" onClick={(e) => {onPageClick(e, currentPage+1)}}
                       className={'page-link mx-1 border-0 rounded-circle text-dark round-32 d-flex align-items-center justify-content-center' + ((currentPage >= totalPages) ? ' disabled' : '')}>
                        <i className="ti ti-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    );
}