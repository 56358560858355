import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {AttributeValueType} from "../../../helpers/AttributeValueType";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";
import BusinessFormServiceCheckbox from "./businessFormServiceInputs/BusinessFormServiceCheckbox";
import BusinessFormServiceTextInput from "./businessFormServiceInputs/BusinessFormServiceTextInput";
import BusinessFormServiceRepeatedEnum from "./businessFormServiceInputs/BusinessFormServiceRepeatedEnum";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import ErrorsHelper from "../../../helpers/ErrorsHelper";

export default function BusinessFormSocialProfiles({onUpdate, attributes, categories}) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [socialLinks, setSocialLinks] = React.useState([]);
    const [saveLoading, setSaveLoading] = React.useState(false);


    useEffect(() => {
        updateSocialLinks();
    }, [attributes, categories]);


    const updateSocialLinks = () => {
        let newSocialLinks = [];

        if (attributes && attributes.potential) {
            attributes.potential.forEach((attribute) => {
                if (attribute.deprecated || !attribute.parent.match(/^attributes\/url_/)) {return;}

                if (attributes.actual) {
                    attributes.actual.forEach((actualAttribute) => {
                        if (actualAttribute.name === attribute.parent) {
                            attribute.values = AttributeValueType.getValue(attribute, actualAttribute);
                        }
                    });
                }

                newSocialLinks.push(attribute);
            });
        }

        setSocialLinks(newSocialLinks);
    }


    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        businessesModel.update(params.locationId, {attributes: socialLinks}, saveErrorHandler).then((response) => {
            if (response !== false) {
                onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
            }

            setSaveLoading(false);
            setEditMode(false);
        });
    }


    const saveErrorHandler = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
    }


    const onCancel = (e) => {
        e.preventDefault();

        updateSocialLinks();
        setEditMode(false);
    }


    const onLinksChanged = (newLinks, index) => {
        let newSocialLinks = [...socialLinks];
        newSocialLinks[index].values = newLinks;

        setSocialLinks(newSocialLinks);
    }


    return (
        <>
            <div className={'py-2' + (editMode ? '' : ' editable')} onClick={(e) => {
                e.preventDefault();
                !editMode && setEditMode(true)
            }}>
                <BusinessFormHeadingProgress
                    score={null}
                    editMode={editMode}
                    title={t('Social profiles')}
                    id="social-profiles"
                />

                <div className="row">
                    <div className="col-lg-6">
                        {editMode === false && (
                            <div className="pt-2 p-small-margins">{socialLinks.length ? socialLinks.map((socialLink, index) => socialLink.values && (
                                <div key={index} className="d-flex flex-column gap-1">
                                    <BusinessFormServiceTextInput
                                        attribute={socialLink}
                                        editMode={false}
                                    />
                                </div>
                                )) : (<span>{t('No links provided.')}</span>)}
                            </div>
                        )}

                        {editMode === true && (<>
                        <div className="fs-2 mb-3 mt-1">{t('Build customer trust by sharing your social profiles.')}</div>

                        {socialLinks.map((socialLink, index) => (
                            <div key={index} className="my-2 d-flex flex-column gap-1">
                                <BusinessFormServiceTextInput
                                    attribute={socialLink}
                                    editMode={true}
                                    onChange={(values) => {onLinksChanged(values, index)}}
                                />
                            </div>
                        ))}

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel}/>
                        </>)}
                    </div>
                </div>
            </div>
        </>
    );
}
