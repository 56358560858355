import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import BusinessesModel from "../../models/BusinessesModel";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import DangerModal from "../ui/DangerModal";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";

export default function BusinessDisconnect(props) {
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [operationProcessing, setOperationProcessing] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);


    useEffect(() => {
    }, [props.data]);


    const onDisconnect = (e) => {
        e.preventDefault();

        setOperationProcessing(true);
        setModalVisible(true);

        businessesModel.disconnect(params.locationId).then((response) => {
            if (response !== false) {
                notificationsContext.notify(t('Business disconnected successfully.'), 'success');
                setOperationProcessing(false);
                setModalVisible(false);

                window.location.href = '/businesses';
            }
        });
    }


    const onCancel = (e) => {
        e.preventDefault();

        setModalVisible(false);
        setOperationProcessing(false);
    }


    return (
        <>
        <div className="d-flex align-items-center justify-content-start mt-5 gap-3">
            <button onClick={(e) => {e.preventDefault(); setModalVisible(true);}} className="d-inline-flex align-items-center justify-content-center btn btn-light-danger text-danger">
                {t('Disconnect')}
            </button>

            <DangerModal visible={modalVisible}>
                <div className="text-center text-danger">
                    <i className="ti ti-hexagon-letter-x fs-7"></i>
                    <h4 className="mt-2">{t('Are you sure?')}</h4>
                    <p className="mt-3">{t('Please, confirm you want to disconnect this location from your account.')}</p>

                    <div className="d-flex align-items-center justify-content-center gap-4 my-3">
                        <button type="button" className="btn btn-light" onClick={onCancel}>
                            {t('Cancel')}
                        </button>

                        <ButtonWithLoading
                            className="btn-danger justify-content-center"
                            iconClassName="ti ti-link-off fs-4 me-2"
                            noOriginalIconClassName={true}
                            label={t('Confirm')}
                            onClick={onDisconnect}
                            loading={operationProcessing}
                        />
                    </div>
                </div>
            </DangerModal>
        </div>
        </>
    );
}