import React, {useEffect} from 'react';

import {AsyncTypeahead, Typeahead} from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useTranslation} from "react-i18next";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";
import ErrorsHelper from "../../../helpers/ErrorsHelper";

function BusinessFormCategories(props) {
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();

    const [editMode, setEditMode] = React.useState(false);
    const [data, setData] = React.useState({
        primaryCategory: null,
        additionalCategories: []
    });
    const [isLoadingPrimaryCategory, setIsLoadingPrimaryCategory] = React.useState(false);
    const [primaryCategoriesOptions, setPrimaryCategoriesOptions] = React.useState([]);
    const [isLoadingAdditionalCategory, setIsLoadingAdditionalCategory] = React.useState(false);
    const [additionalCategoriesOptions, setAdditionalCategoriesOptions] = React.useState([]);
    const [suggestionLoading, setSuggestionLoading] = React.useState(false);
    const [suggestedCategories, setSuggestedCategories] = React.useState({});
    const [saveLoading, setSaveLoading] = React.useState(false);


    const suggestionTips = [
        t('Gathering Business Details'),
        t('Analyzing Industry Trends'),
        t('Processing Keywords'),
        t('Matching Categories'),
        t('Optimizing for Visibility'),
        t('Refining Suggestions'),
        t('Finalizing Best Matches'),
        t('Preparing an AI Suggestion'),
        t('Almost There')
    ];
    const [suggestionTip, setSuggestionTip] = React.useState(suggestionTips[0]);
    const [suggestionTipIndex, setSuggestionTipIndex] = React.useState(0);
    const averageSuggestionRequestTime = 27000; // 27 seconds
    const suggestionTipIntervalTime = Math.round(averageSuggestionRequestTime / suggestionTips.length);
    // show tips while suggestion is loading
    useEffect(() => {
        let suggestionTipInterval = null;

        if (suggestionLoading) {
            suggestionTipInterval = setInterval(() => {

                setSuggestionTipIndex((prevIndex) => {
                    const nextIndex = prevIndex + 1;
                    if (nextIndex === suggestionTips.length) {
                        clearInterval(suggestionTipInterval);
                        return prevIndex;
                    }

                    setSuggestionTip(suggestionTips[nextIndex]);
                    return nextIndex;
                });
            }, suggestionTipIntervalTime);
        }

        return () => {
            if (suggestionTipInterval) clearInterval(suggestionTipInterval);
        };
    }, [suggestionLoading]);



    useEffect(() => {
        setData(props.data);
        setEditMode(false);
    }, [props.data]);



    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        businessesModel.update(params.locationId, {categories: data}, saveErrorHandler).then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
            } else {
                notificationsContext.notify(t('Information change failed.'), 'error');
            }

            setSaveLoading(false);
        });
    }


    const saveErrorHandler = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
    }


    const onCancel = (e) => {
        e.preventDefault();

        setData(props.data);
        setEditMode(false);
    }


    const removeCategory = (categoryName) => {
        const newAdditionalCategories = data.additionalCategories.filter((category) => {
            return category.name !== categoryName;
        });
        setData({
            ...data,
            additionalCategories: newAdditionalCategories
        });
    }


    const addCategory = () => {
        setData({
            ...data,
            additionalCategories: [...data.additionalCategories, {name: '', displayName: ''}]
        });
    }


    const onSuggest = (e) => {
        e.preventDefault();

        setSuggestionLoading(true);

        let additionalCategories = [];
        if (data.additionalCategories) {
            additionalCategories = data.additionalCategories.map((category) => {return category.name});
        }

        businessesModel.suggestCategories(params.locationId, data.primaryCategory.name, additionalCategories).then((response) => {
            if (response && response.primaryCategory && response.additionalCategories) {
                setSuggestedCategories({
                    primaryCategory: response.primaryCategory,
                    additionalCategories: response.additionalCategories
                });
            }
        }).finally(() => {
            setSuggestionLoading(false);
            setSuggestionTipIndex(0);
            setSuggestionTip(suggestionTips[0]);
        });
    }


    const onApplySuggestion = (e) => {
        e.preventDefault();

        setData({
            primaryCategory: suggestedCategories.primaryCategory,
            additionalCategories: suggestedCategories.additionalCategories
        });

        setSuggestedCategories({});

        notificationsContext.notify(t('Categories applied. Please, save them before leaving.'), 'success');
    }


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <BusinessFormHeadingProgress
                score={props.score}
                editMode={editMode}
                title={t('Business category')}
                id="category"
            />

            {editMode === false && (
                <div className="pt-2">
                    {data.primaryCategory && (
                        <div className="fw-semibold">{data.primaryCategory.displayName}</div>
                    )}
                    {data.additionalCategories && data.additionalCategories.map((category, index) => {
                        return (
                            <div className="" key={index}>{category.displayName}</div>
                        );
                    })}
                </div>
            )}

            {editMode === true && (
                <>
                <div className="fs-2 mb-3 mt-1">{t('Help customers find your business by industry.')}</div>

                <div className="mb-3 pt-2">
                    <span className="fs-3 fw-semibold d-block mb-1">{t('Primary Category')}</span>
                    <div className="row">
                        <div className="col-md-12">
                            <AsyncTypeahead
                                onSearch={(query) => {
                                    setIsLoadingPrimaryCategory(true);

                                    businessesModel.autocompleteCategories(params.locationId, query).then((response) => {
                                        if (response !== false) {
                                            setPrimaryCategoriesOptions(response);
                                            setIsLoadingPrimaryCategory(false);
                                        }
                                    });
                                }}
                                minLength={3}
                                onChange={(selected) => {
                                    if (selected.length > 0) {
                                        setData({
                                            ...data,
                                            primaryCategory: {
                                                name: selected[0].value,
                                                displayName: selected[0].label
                                            }
                                        });
                                    } else {
                                        setData({
                                            ...data,
                                            primaryCategory: {
                                                name: '',
                                                displayName: null
                                            }
                                        });
                                    }
                                }}
                                onBlur={(e) => {
                                    if (data.primaryCategory.name === '' && props.data.primaryCategory.name !== null) {
                                        setData({
                                            ...data,
                                            primaryCategory: {
                                                name: props.data.primaryCategory.name,
                                                displayName: props.data.primaryCategory.displayName
                                            }
                                        });
                                    }
                                }}
                                selected={data.primaryCategory.displayName ? [data.primaryCategory.displayName] : []}
                                options={primaryCategoriesOptions}
                                isLoading={isLoadingPrimaryCategory}
                                id="cf-category"
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        {data.additionalCategories && data.additionalCategories.map((category, index) => {
                            return (
                                <div className="mb-3" key={index}>
                                    <span className="fs-2 fw-semibold d-block mb-1">{t('Additional Category')}</span>
                                    <div className="d-flex">
                                        <AsyncTypeahead
                                            onSearch={(query) => {
                                                setIsLoadingAdditionalCategory(category.name);

                                                businessesModel.autocompleteCategories(params.locationId, query).then((response) => {
                                                    if (response !== false) {
                                                        setAdditionalCategoriesOptions(response);
                                                        setIsLoadingAdditionalCategory(false);
                                                    }
                                                });
                                            }}
                                            minLength={3}
                                            onChange={(selected) => {
                                                if (selected.length > 0) {
                                                    const newAdditionalCategories = data.additionalCategories.map((exCategory) => {
                                                        if (exCategory.name === category.name) {
                                                            return {
                                                                name: selected[0].value,
                                                                displayName: selected[0].label
                                                            };
                                                        }
                                                        return exCategory;
                                                    });
                                                    setData({
                                                        ...data,
                                                        additionalCategories: newAdditionalCategories
                                                    });
                                                } else {
                                                    const newAdditionalCategories = data.additionalCategories.map((exCategory) => {
                                                        if (exCategory.name === category.name) {
                                                            return {
                                                                name: '',
                                                                displayName: null
                                                            };
                                                        }
                                                        return exCategory;
                                                    });
                                                    setData({
                                                        ...data,
                                                        additionalCategories: newAdditionalCategories
                                                    });
                                                }
                                            }}
                                            selected={category.displayName ? [category.displayName] : []}
                                            options={additionalCategoriesOptions}
                                            isLoading={isLoadingAdditionalCategory === category.name}
                                            id={'cf-category-'+index}
                                            className={'flex-grow-1'}
                                        />
                                        <button className="btn btn-light-danger text-danger ms-2" onClick={(e) => {removeCategory(category.name)}}><i className={'ti ti-x'}></i></button>
                                    </div>
                                </div>
                            );
                        })}

                        {data.additionalCategories && data.additionalCategories.length < 9 && (
                        <div className="mb-3">
                            <button className="btn btn-light-primary text-primary" onClick={addCategory}>{t('Add an Additional Category')}</button>
                        </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <div className="bordered-tip pb-4 pt-4">
                            <div className="alert alert-warning fs-2"><strong>{t('Pro Tip!')}</strong> {t('Use first capital letter, if you want to search for categories from the beginning.')}</div>

                            <div className="alert alert-info my-3" role="alert">
                                <strong>{t('Improve your visibility with AI')}</strong>
                                <span className="d-block fs-2 mt-2">{t('Use our suggestions, based on your business name, description and primary category.')}</span>
                            </div>

                            {suggestionLoading === true && (
                                <div className="fs-2 my-3 fw-semibold">{suggestionTip}...</div>
                            )}

                            {suggestedCategories.primaryCategory && (
                                <div className="my-2 fs-2">
                                    {t('Primary category')}:
                                    <span className="d-block fs-3 fw-semibold lh-sm mt-1">{suggestedCategories.primaryCategory.displayName}</span>
                                </div>
                            )}

                            {suggestedCategories.additionalCategories && suggestedCategories.additionalCategories.length > 0 && (
                                <div className="my-2 fs-2">
                                    {t('Additional categories')}:
                                    <ol>
                                    {suggestedCategories.additionalCategories.map((category, index) => {
                                        let alreadyAdded = false;
                                        if (data.additionalCategories) {
                                            for (let i = 0; i < data.additionalCategories.length; i++) {
                                                if (data.additionalCategories[i].name === category.name) {
                                                    alreadyAdded = true;
                                                    break;
                                                }
                                            }
                                        }

                                        return (
                                            <li key={index} className={alreadyAdded ? 'text-default fw-semibold' : 'text-success fw-bolder'}>{category.displayName}</li>
                                        );
                                    })}
                                    {data.additionalCategories && data.additionalCategories.length > 0 && data.additionalCategories.map((category, index) => {
                                        let notSuggested = true;
                                        for (let i = 0; i < suggestedCategories.additionalCategories.length; i++) {
                                            if (suggestedCategories.additionalCategories[i].name === category.name) {
                                                notSuggested = false;
                                                break;
                                            }
                                        }

                                        return (
                                            notSuggested && (
                                                <li key={index} className="text-danger fw-bolder">{category.displayName}</li>
                                            )
                                        );
                                    })}
                                    </ol>
                                </div>
                            )}

                            <div className={'d-flex align-items-center justify-content-between gap-3 mt-3'}>
                                <button onClick={onApplySuggestion} className={'align-items-center justify-content-center btn btn-light-primary text-primary '+(suggestedCategories.primaryCategory ? 'd-inline-flex' : 'd-none')}>
                                    <i className={'fs-4 ti ti-check me-2 d-none d-md-inline-block'}></i>
                                    {t('Apply')}
                                </button>

                                <button onClick={onSuggest} className="d-inline-flex align-items-center justify-content-center btn btn-dark">
                                    <span
                                        className={'spinner-border spinner-border-sm me-2 '+((suggestionLoading)?(''):('d-none'))}
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <i className={'fs-4 ti ti-code me-2 '+((suggestionLoading)?('d-none'):(''))}></i>
                                    {t('Suggest with AI')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-7">
                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel} />
                    </div>
                </div>
                </>
            )}
        </div>
        </>
    );
}

export default BusinessFormCategories;
