import * as React from "react";
import {toast} from "react-toastify";


const notificationsStructure = {
    defaultOptions: {
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
        timeOut: 2000
    },
    notify: () => {}
}

const NotificationsContext = React.createContext(notificationsStructure);


function NotificationsProvider({children}) {
    const notify = (content, type, options) => {
        if (!options) {options = {}}
        options = {...notificationsStructure.defaultOptions, ...options};

        switch (type) {
            case 'error': toast.error(content, options); break;
            case 'info': toast.info(content, options); break;
            case 'warning': toast.warn(content, options); break;
            default: toast.success(content, options); break;
        }
    };


    let value = JSON.parse(JSON.stringify(notificationsStructure));
    value.notify = notify;

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
}


function useNotifications() {
    return React.useContext(NotificationsContext);
}


export {NotificationsProvider, useNotifications};