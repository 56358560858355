import React, {useEffect, useRef} from 'react';

import SimpleBar from "simplebar-react";
import {useApi} from "../../../../providers/ApiProvider";
import BusinessesModel from "../../../../models/BusinessesModel";
import {Link, useParams} from "react-router-dom";
import {useBreadcrumbs} from "../../../../providers/BreadcrumbsProvider";
import BusinessViewHeader from "../../BusinessViewHeader";
import BusinessViewMenu from "../../BusinessViewMenu";
import {useNotifications} from "../../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../../providers/TranslationProvider";
import BusinessesReviewsPublishRepliesMenu from "./BusinessesReviewsPublishRepliesMenu";
import StarRating from "../../../ui/StarRating";
import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";
import RequestSampleResponseAlert from "../../rules/RequestSampleResponseAlert";

export default function BusinessesReviewsPublishRepliesDetails({review, onReplySent, rules, reviewQueueInfo}) {
    const {t} = useTranslation();
    const params = useParams();
    const notificationsContext = useNotifications();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const simpleBarRef = useRef();

    const [reviewRating, setReviewRating] = React.useState(null);
    const [replyLoading, setReplyLoading] = React.useState(false);
    const [generatedResponse, setGeneratedResponse] = React.useState('');


    useEffect(() => {
        let starRatingNumeric = 0;
        if (review) {
            switch (review.starRating) {
                case 'ONE': starRatingNumeric = 1; break;
                case 'TWO': starRatingNumeric = 2; break;
                case 'THREE': starRatingNumeric = 3; break;
                case 'FOUR': starRatingNumeric = 4; break;
                case 'FIVE': starRatingNumeric = 5; break;
            }
            setReviewRating(starRatingNumeric);
        }

        let baseResponse = '';
        if (rules && rules[starRatingNumeric] && rules[starRatingNumeric].template && rules[starRatingNumeric].template.is_active) {
            baseResponse = rules[starRatingNumeric].template.response_template;
        }
        setGeneratedResponse(baseResponse);

        simpleBarRef.current.recalculate();
    }, [review, rules]);


    const onReplyClick = () => {
        if (!generatedResponse) {return false;}

        setReplyLoading(true);

        businessesModel.reviewReply(params.locationId, review.reviewId, generatedResponse).then((response) => {
            if (response !== false) {
                notificationsContext.notify(t('Reply sent successfully'), 'success');

                if (onReplySent) {
                    onReplySent();
                }
            }
        }).finally(() => {
            setReplyLoading(false);
        });
    }


    const onResponseGenerated = (response) => {
        setGeneratedResponse(response);
    }


    const getQueueStatusLabel = () => {
        if (!reviewQueueInfo) {return '';}

        switch (reviewQueueInfo.status) {
            case 'pending': return (<><i className={'ti ti-hourglass fs-6'}></i> <span>{t('Processing')}</span></>);
            case 'publishing': return (<><i className={'ti ti-outbound fs-6'}></i> <span>{t('Responding')}</span></>);
            case 'published': return (<><i className={'ti ti-checks fs-6'}></i> <span>{t('Responded')}</span></>);
            case 'error': return (<><i className={'ti ti-alert-triangle fs-6'}></i> <span>{t('Error')}</span></>);
        }
    }


    const getQueeueStatusClasses = () => {
        if (!reviewQueueInfo) {return '';}

        switch (reviewQueueInfo.status) {
            case 'pending': return 'bg-light-primary text-primary';
            case 'publishing': return 'bg-light-warning text-warning';
            case 'published': return 'bg-light-success text-success';
            case 'error': return 'bg-light-danger text-danger';
        }
    }


    return (
        <SimpleBar autoHide={true} className={'simplescroll-maxheight'} ref={simpleBarRef}>
            <div className="chat-box p-9">
                {review && (
                    <div className="chat-list chat active-chat">
                        <div className="hstack align-items-center mb-7 pb-1 justify-content-between flex-column flex-lg-row-reverse">
                            <div className="d-flex align-items-center justify-content-end w-100">
                                <span className={'d-flex align-items-center gap-2 px-3 py-2 ' + getQueeueStatusClasses()}>{getQueueStatusLabel()}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-start gap-3 w-100">
                                <img src={review.reviewer.profilePhotoUrl} alt={review.reviewer.displayName} width="64" height="64" className="rounded-circle"/>
                                <div>
                                    <h6 className="fw-semibold fs-4 mb-0">{review.reviewer.displayName}</h6>
                                    <StarRating rating={reviewRating} className="mb-0"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8 mb-7">
                                <p className="mb-1 fs-2">Review text</p>
                                {review.comment && (
                                    <h6 className="fw-semibold mb-0">{review.comment.split('\n').map((str, index, array) =>
                                        ((index === array.length - 1) || (!str.length)) ? str : <p key={index} className="mb-1">{str}</p>
                                    )}</h6>
                                ) || !review.comment && (
                                    <h6 className="fw-semibold mb-0 text-muted">{t('No text provided.')}</h6>
                                )}
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-7">
                                    <p className="mb-1 fs-2">{t('Created')}</p>
                                    <h6 className="fw-semibold mb-0">{(new Date(review.createTime)).toLocaleString()}</h6>
                                </div>

                                {(review.createTime !== review.updateTime) && (
                                <div className="mb-7">
                                    <p className="mb-1 fs-2">{t('Last Updated')}</p>
                                    <h6 className="fw-semibold mb-0">{(new Date(review.updateTime)).toLocaleString()}</h6>
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="border-bottom pb-7 mb-3">
                            <RequestSampleResponseAlert
                                reviewId={review.reviewId}
                                rule={rules[reviewRating]}
                                starRating={reviewRating}
                                onResponseGenerated={onResponseGenerated}
                            />
                        </div>
                        <div className={'d-flex flex-column align-items-start'+(!generatedResponse ? ' d-none' : '')}>
                            <p className={'fs-2'}>{t('If you like this sample response, you can publish it right now.')}</p>

                            <ButtonWithLoading
                                className="btn-light-primary justify-content-center text-primary"
                                iconClassName="ti-messages text-primary"
                                label={t('Publish now')}
                                onClick={onReplyClick}
                                loading={replyLoading}
                            />
                        </div>
                    </div>
                )}
            </div>
        </SimpleBar>
    );
}
