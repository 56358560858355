import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import StrHelper from "../../../helpers/StrHelper";

function BusinessesReviewReplyForm(props) {
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);

    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [generationLoading, setGenerationLoading] = React.useState(false);
    const [reviewReply, setReviewReply] = React.useState('');


    const suggestionTips = [
        t('Analyzing Feedback'),
        t('Gathering Insights'),
        t('Tailoring Tone'),
        t('Crafting Response'),
        t('Ensuring Relevance'),
        t('Optimizing for Engagement'),
        t('Personalizing Reply'),
        t('Aligning with Standards'),
        t('Finalizing Content'),
        t('Preparing to Impress')
    ];
    const [suggestionTip, setSuggestionTip] = React.useState(suggestionTips[0]);
    const [suggestionTipIndex, setSuggestionTipIndex] = React.useState(0);
    const averageSuggestionRequestTime = 7000; // 7 seconds
    const suggestionTipIntervalTime = Math.round(averageSuggestionRequestTime / suggestionTips.length);
    // show tips while suggestion is loading
    useEffect(() => {
        let suggestionTipInterval = null;

        if (generationLoading) {
            suggestionTipInterval = setInterval(() => {

                setSuggestionTipIndex((prevIndex) => {
                    const nextIndex = prevIndex + 1;
                    if (nextIndex === suggestionTips.length) {
                        clearInterval(suggestionTipInterval);
                        return prevIndex;
                    }

                    setSuggestionTip(suggestionTips[nextIndex]);
                    return nextIndex;
                });
            }, suggestionTipIntervalTime);
        }

        return () => {
            if (suggestionTipInterval) clearInterval(suggestionTipInterval);
        };
    }, [generationLoading]);


    useEffect(() => {
        let reviewReplyClean = StrHelper.filterGoogleTranslatedText(props.reviewReply);

        setReviewReply(reviewReplyClean);
    }, []);


    const onGenerate = (e) => {
        e.preventDefault();

        setGenerationLoading(true);
        businessesModel.generate(params.locationId, params.reviewId).then((response) => {
            if (response !== false) {
                setReviewReply(response.reply);
            }
        }).finally(() => {
            setGenerationLoading(false);
            setSuggestionTipIndex(0);
            setSuggestionTip(suggestionTips[0]);
        });
    }


    const onSubmit = (e) => {
        e.preventDefault();

        setSubmitLoading(true);
        props.onSubmit(reviewReply);
    }


    const onDelete = (e) => {
        e.preventDefault();

        setDeleteLoading(true);
        props.onDelete();
    }



    return (
        <form>
            <div className="row">
                <div className="col-md-6 col-xl-6">
                    <div className="form-group mb-3">
                        <textarea className="form-control" rows={5} id="review-comment" defaultValue={reviewReply} onChange={(e) => {setReviewReply(e.target.value);}}></textarea>
                    </div>

                    <div className={'d-flex align-items-center justify-content-between gap-3'}>
                        <button onClick={onSubmit} className="btn btn-primary font-medium d-flex align-items-center">
                            <span
                                className={'spinner-border spinner-border-sm me-2 '+((submitLoading)?(''):('d-none'))}
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <i className={'fs-4 ti ti-send me-2 '+((submitLoading)?('d-none'):(''))}></i>
                            {t('Publish')}
                        </button>

                        {props.reviewReply && (
                        <button onClick={onDelete} className="btn btn-danger font-medium d-flex align-items-center">
                            <span
                                className={'spinner-border spinner-border-sm me-2 '+((deleteLoading)?(''):('d-none'))}
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <i className={'fs-4 ti ti-trash me-2 '+((deleteLoading)?('d-none'):(''))}></i>
                            {t('Remove this Response')}
                        </button>
                        )}
                    </div>
                </div>
                <div className="col-md-6 col-xl-6">
                    <div className="bordered-tip pb-4">
                        <div className="alert alert-info" role="alert">
                            <strong>{t('Prepare a balanced answer with AI')}</strong>
                            <span className="d-block fs-2 mt-2">{t('Use industry-leading standards for your business to create a quick and quality response to the customer\'s review.')}</span>
                        </div>

                        {generationLoading === true && (
                            <div className="fs-2 my-3 fw-semibold">{suggestionTip}...</div>
                        )}

                        <div className={'d-flex align-items-center justify-content-between gap-3 mt-3'}>
                            <button onClick={onGenerate} className="d-inline-flex align-items-center justify-content-center btn btn-dark">
                                <span
                                    className={'spinner-border spinner-border-sm me-2 '+((generationLoading)?(''):('d-none'))}
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <i className={'fs-4 ti ti-code me-2 '+((generationLoading)?('d-none'):(''))}></i>
                                {t('Prepare with AI')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default BusinessesReviewReplyForm;
