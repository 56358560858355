import React, {useCallback} from 'react';
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";


export default function BusinessesPostMedia(props) {
    const { t} = useTranslation();
    const onDrop = useCallback(acceptedFiles => {
        props.onFilesChanged(acceptedFiles);
        setFilesToUpload(acceptedFiles);
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false});
    const [uploadRunning, setUploadRunning] = React.useState(false);
    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [uploadComplete, setUploadComplete] = React.useState(false);


    return (
        <div className={'file-uploading-block'}>
            <div {...getRootProps()} className={'drop-area'+((isDragActive)?' active':'')}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>{t('Drop the files here...')}</p> :
                        <p>{t('Drag \'n\' drop some files here, or click to select files')}</p>
                }
            </div>

            <div className="file-uploading-files mt-2">
                {filesToUpload && filesToUpload.map((file, index) => {
                    return (
                        <div className="d-flex gap-3 align-items-center justify-content-between fs-2 fw-semibold text-primary my-1" key={index}>
                            <div>{file.name}</div>

                            <div className="progress flex-grow-1 d-none">
                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-muted" style={{width: '15px', height: '8px'}} role="progressbar"></div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}