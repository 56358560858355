import React from "react";

function ButtonWithLoading(props) {
    return (<>
        <button className={props.className + ((props.noOriginalClassName) ? '' : ' btn d-flex align-items-center')} onClick={props.onClick}>
            <span
                className={'spinner-border spinner-border-sm '+((props.noOriginalSpinnerClassName)?(props.spinnerClassName):(' me-2'))+((props.loading)?(''):(' d-none'))}
                role="status"
                aria-hidden="true"
            ></span>
            <i className={props.iconClassName + ((props.noOriginalIconClassName) ? '' : ' ti ti-message-plus fs-4 me-2')+((props.loading)?' d-none':'')}></i>
            {props.label}
        </button>
    </>)
}

export default ButtonWithLoading;