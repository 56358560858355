import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import TableEditable from "../ui/TableEditable";
import {useApi} from "../../providers/ApiProvider";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import AccountTypesModel from "../../models/AccountTypesModel";
import TableWrapper from "../ui/TableWrapper";
import TableLoading from "../ui/TableLoading";
import {useNotifications} from "../../providers/NotificationsProvider";
import {Link} from "react-router-dom";

export default function AccountTypesList(props) {
    const {language} = useMyTranslation();
    const breadcrumbs = useBreadcrumbs();
    const notificationsContext = useNotifications();
    const apiContext = useApi();
    const accountTypesModel = new AccountTypesModel(apiContext.api);
    const { t} = useTranslation();


    const [dataItems, setDataItems] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    const onEdit = (pk) => {
        window.location.href = '/account-types/' + pk + '/edit';
    }


    const onDelete = (pk) => {
        if (window.confirm(t('Are you sure you want to delete this item?'))) {
            accountTypesModel.delete(pk, onDeleteError).then((response) => {
                if (response !== false) {
                    notificationsContext.notify(t('Item deleted successfully!'), 'success');
                    updateData();
                } else {
                    notificationsContext.notify(t('Couldn\'t delete this account type!'), 'success');
                }
            });
        }
    }


    const updateData = () => {
        accountTypesModel.list().then((response) => {
            if (response !== false) {
                let newData = [];
                Object.keys(response).map((key) => {
                    newData.push({
                        'pk': response[key].id,
                        'data': response[key]
                    });
                });

                setDataItems(newData);
                setLoading(false);
            }
        });
    }


    const onDeleteError = (response) => {
        if (response.error && response.error.details) {
            notificationsContext.notify(t('Please, fix errors before continuing.'), 'error');
        }
    }


    useEffect(() => {
        breadcrumbs.update({
            title: t('Account types'),
            breadcrumbs: []
        });

        document.title = t('Account types')+' | LocalBoost';

        updateData();
    }, [language]);


    return (
        <SimpleCardContainer title={t('Account types')}>
            <div className="d-flex justify-content-end mb-3">
                <Link to={'/account-types/create'} className={'btn btn-light-success text-success'}>{t('Create a new account type')}</Link>
            </div>
            <TableWrapper header={[
                {'label': t('ID'), 'cellStyle': {width: '200px'}},
                {'label': t('Name')},
                {'label': t('Total Users'), 'cellStyle': {width: '150px'}},
                {'label': t('Sorting Order'), 'cellStyle': {width: '150px'}},
                {'label': '', 'cellStyle': {width: '180px'}},
            ]}>
                {loading ? (<TableLoading loading={loading} columns={5}/>) : dataItems.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td style={{width: '200px'}}>{item.data.id}</td>
                            <td>
                                <h6 className="fs-4 fw-semibold mb-1">
                                    <a href="#" className={'text-primary'} onClick={(e) => {e.preventDefault(); onEdit(item.pk)}}>{item.data.name}</a>
                                </h6>
                                Max Profiles: {item.data.max_profiles}
                            </td>
                            <td style={{width: '150px'}}>{item.data.accounts}</td>
                            <td style={{width: '150px'}}>{item.data.order}</td>
                            <td style={{width: '180px'}}>
                                <div className="d-flex align-items-center justify-content-end gap-3">
                                    <button type="button"
                                            className={'btn btn-light-primary btn-circle d-inline-flex align-items-center justify-content-center'}
                                            onClick={(e) => {e.preventDefault(); onEdit(item.pk)}}>
                                        <i className="fs-5 ti ti-edit text-primary"></i>
                                    </button>
                                    <button type="button"
                                            className={'btn btn-light-danger btn-circle d-inline-flex align-items-center justify-content-center' + (item.data.accounts > 0 ? ' disabled' : '')}
                                            onClick={(e) => {e.preventDefault(); onDelete(item.pk)}}>
                                        <i className="fs-5 ti ti-trash text-danger"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </TableWrapper>
        </SimpleCardContainer>
    );
}
