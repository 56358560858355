import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import Chart from "react-apexcharts";
import BusinessViewMenu from "../BusinessViewMenu";
import BusinessesInsightsChart from "./BusinessesInsightsChart";
import BusinessesInsightsChartArea from "./BusinessesInsightsChartArea";
import {useTranslation} from "react-i18next";
import CardDisabled from "../../ui/CardDisabled";
import CardLoading from "../../ui/CardLoading";


export default function BusinessesInsightsAllInteractions(props) {
    const { t} = useTranslation();
    const [total, setTotal] = React.useState(false);


    const recalculateTotal = () => {
        let tmpTotal = 0, metricsAvailable = false;
        if (props.insights[props.metricLabel]) {
            console.log(1);
            metricsAvailable = true;
            Object.entries(props.insights[props.metricLabel]).forEach(([key, value]) => {
                tmpTotal += parseInt(value);
            });
        } else if (props.metricLabel === t('Impressions')) {
            Object.keys(props.insights).forEach((key) => {
                if (key.toLowerCase().includes(('Impressions').toLowerCase())) {
                    metricsAvailable = true;
                    Object.entries(props.insights[key]).forEach(([date, value]) => {
                        tmpTotal += parseInt(value);
                    });
                }
            });
        } else if (props.metricLabel === t('Total')) {
            ['BUSINESS_BOOKINGS', 'BUSINESS_CONVERSATIONS', 'BUSINESS_DIRECTION_REQUESTS', 'CALL_CLICKS', 'WEBSITE_CLICKS'].forEach((item) => {
                if (props.insights[item]) {
                    metricsAvailable = true;
                    Object.entries(props.insights[item]).forEach(([key, value]) => {
                        tmpTotal += parseInt(value);
                    });
                }
            });
        }

        if (metricsAvailable) {
            setTotal(tmpTotal);
        }
    }


    useEffect(() => {
        recalculateTotal();
    }, [props.insights, props.metricLabel]);


    return (
        <div className={'card w-100 position-relative overflow-hidden'+((total === false) ? ' card-disabled' : '')+(props.loading ? ' loading' : '')}>
            <div className="card-body">
                <div className="d-flex align-items-end justify-content-between">
                    <div>
                        <p className="mb-0">{props.label}</p>
                        <h4 className="mb-0 fw-semibold">{total}</h4>
                    </div>
                </div>
            </div>
            <BusinessesInsightsChartArea metricLabel={props.metricLabel} insights={props.insights} />

            {!props.loading && (
                <CardDisabled />
            )}

            <CardLoading loading={props.loading} />
        </div>
    );
}
