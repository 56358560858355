import React, {useEffect} from 'react';

import MainLayout from "../layout/MainLayout";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import TableEditable from "../ui/TableEditable";
import {useApi} from "../../providers/ApiProvider";
import BusinessesModel from "../../models/BusinessesModel";
import {Link} from "react-router-dom";
import TableEditableRow from "../ui/TableEditableRow";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import { toast } from 'react-toastify';
import {useNotifications} from "../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

function BusinessAdd(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const businessesModel = new BusinessesModel(apiContext.api);


    const breadcrumbs = useBreadcrumbs();
    const fieldsLinked = ['id', 'name', 'last_synced'];
    const [processingLocations, setProcessingLocations] = React.useState([]);
    const [linkedItems, setLinkedItems] = React.useState({});
    const [dataItems, setDataItems] = React.useState({});
    const [loading, setLoading] = React.useState(true);


    const onLink = (e, locationId, accountEmail, status) => {
        e.preventDefault();

        if (processingLocations.indexOf(locationId) === -1) {
            let tmpProcessingLocations = JSON.parse(JSON.stringify(processingLocations));
            tmpProcessingLocations.push(locationId)
            setProcessingLocations(tmpProcessingLocations);
        }

        businessesModel.toggleLink(locationId, accountEmail, status).then((response) => {
            if (response !== false) {
                businessesModel.list(fieldsLinked).then((response) => {
                    if (response !== false) {
                        setLinkedItems(response);

                        let tmpProcessingLocations = JSON.parse(JSON.stringify(processingLocations));
                        tmpProcessingLocations.splice(tmpProcessingLocations.indexOf(locationId), 1);
                        setProcessingLocations(tmpProcessingLocations);

                        notificationsContext.notify(t('Location connected.'), 'success');

                        window.location.href = '/businesses/'+locationId;
                    }
                });
            } else {
                setProcessingLocations([]);
                notificationsContext.notify(t('You reached the limit of connected accounts.'), 'error');
            }
        });
    }


    const getAddressString = (storefrontAddress) => {
        let address = [];
        if (storefrontAddress.postalCode) {
            address.push(storefrontAddress.postalCode);
        }
        if (storefrontAddress.locality) {
            address.push(storefrontAddress.locality);
        }
        if (storefrontAddress.addressLines) {
            address.push(storefrontAddress.addressLines.join(', '));
        }
        return address.join(', ');
    }


    useEffect(() => {
        breadcrumbs.update({
            title: t('Businesses'),
            breadcrumbs: [
                {label: t('Businesses List'), url: '/businesses'},
                {label: t('Connect a Location')}
            ]
        });

        document.title = t('Connect a Location')+' | LocalBoost';


        businessesModel.list(fieldsLinked).then((response) => {
            if (response !== false) {
                setLinkedItems(response);
            }

            businessesModel.listFresh().then((response) => {
                if (response !== false) {
                    setDataItems(response);
                    setLoading(false);
                }
            });
        });
    }, [language]);


    return (
        <SimpleCardContainer title={t('Connect a Location')}>
            <div className="table-responsive rounded-2">
                <table className="table border customize-table mb-0 align-middle">
                    <thead className="text-dark fs-4">
                    <tr>
                        <th><h6 className="fs-4 fw-semibold mb-0">{t('Title')}</h6></th>
                        <th className="w-20"></th>
                    </tr>
                    </thead>
                    <tbody>

                    {loading && (
                        <tr>
                            <td>
                                <div className="progress mt-4">
                                    <div className="progress-bar progress-bar-striped bg-dark progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}></div>
                                </div>
                            </td>
                            <td>
                                <div className="progress mt-4">
                                    <div className="progress-bar progress-bar-striped bg-dark progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}></div>
                                </div>
                            </td>
                        </tr>
                    ) ||
                    !loading && Object.keys(dataItems).map((accountEmail, aIndex) => {
                        return dataItems[accountEmail].map((location, index) => {
                            let locationId = location.name.split('/')[1],
                                linked = linkedItems[accountEmail] && linkedItems[accountEmail][locationId];

                            if (linked) {return null;}

                            return (
                                <tr key={aIndex+'-'+index} data-id={locationId}>
                                    <td>
                                        <div>
                                            <h6 className="fs-4 fw-semibold mb-0">{location.title}</h6>
                                            <span className="fw-normal">{location.storefrontAddress && getAddressString(location.storefrontAddress)}</span>
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        <button
                                            className={'d-inline-flex align-items-center btn '+((linked)?('btn-danger'):('btn-primary'))}
                                            disabled={processingLocations.indexOf(locationId) !== -1}
                                            onClick={(e) => {onLink(e, locationId, accountEmail, !linked)}}
                                        >
                                            <span
                                                className={'spinner-border spinner-border-sm me-2 '+((processingLocations.length && processingLocations.indexOf(locationId) !== -1)?(''):('d-none'))}
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            <i className={'ti '+((linked)?('ti-link-off'):('ti-link'))+' fs-4 me-2 '+((processingLocations.length && processingLocations.indexOf(locationId) !== -1)?('d-none'):(''))}></i>
                                            {(linked)?(t('Unlink')):(t('Link'))}
                                        </button>
                                    </td>
                                </tr>
                            )
                        });
                    })}
                    </tbody>
                </table>
            </div>
        </SimpleCardContainer>
);
}

export default BusinessAdd;
