import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessesWidgetsModel from "../../../models/BusinessesWidgetsModel";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessViewHeader from "./../BusinessViewHeader";
import BusinessViewMenu from "../BusinessViewMenu";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";

function BusinessesWidget(props) {
	
    const {language} 				= useMyTranslation()
	;	
    const { t} 						= useTranslation();	
	
    const params 					= useParams();	
	
    const apiContext 				= useApi();
	
    const businessesWidget 			= new BusinessesWidgetsModel(apiContext.api);
	
    const businessesModel 			= new BusinessesModel(apiContext.api);
	
    const [profile, setProfile] 	= React.useState({});
	
    const [code, setCode] 			= React.useState('');
	
    const [widgetId, setWidgetId] 	= React.useState('');
	
	const notifications 			= useNotifications();
	
    const [icons, setIcons] 		= React.useState([]);
	
    const [style, setStyle] 		= React.useState('');
	
    const breadcrumbs 				= useBreadcrumbs();
	
	const [options, setOptions] 	= React.useState({});

    const initWidget = () => {
		
		businessesWidget.get(params.locationId).then((response) => {
		
            if (response !== false) {

				console.log('options');
				console.log(response.options);
				
				setWidgetId(response.widget_id);
				setIcons(response.icons);
				setStyle(response.style);
				setOptions(response.options);
				setCode('<script id="lbGbadge" src="'+process.env.REACT_APP_API_URL + '/widgets/'+response.widget_id+'"></script>');               
            }
        });
		
	}
	
    const setMetaData = (profile) => {
	
        if (profile !== false) {
		
            breadcrumbs.update({
			
                title: profile.title,
                breadcrumbs: [
				
                    {label: t('Businesses List'), url: '/businesses'},
                    {label: profile.title, url: '/businesses/'+params.locationId},
                    {label: t('Widget')}
                ]
            });

            document.title = t('Widget')+' | '+profile.title+' | LocalBoost';
        }
    }

    const onProfileRefresh = (profile, lastUpdated) => {
	
        setMetaData(profile);
    }
	
	
    useEffect(() => {
	
        businessesModel.view(params.locationId).then((response) => {
		
            if (response !== false) {
			
                setMetaData(response.data);
				setProfile(response.data);

				console.log('response');
				console.log(response);
			}
        });
		
		initWidget();
		
    }, [language]);	

    useEffect(() => {
		
		initWidget();
    }, []);
	
	const generateWidget = () =>{	
	
		let position 		= document.getElementById('badge-position');
		let marginTop 		= document.getElementById('badgeTop');
		let marginBottom 	= document.getElementById('badgeBottom');
		let marginLeft 		= document.getElementById('badgeLeft');
		let marginRight 	= document.getElementById('badgeRight');
		
		if(position.value == 'tl'){
			
			marginRight.style.display = 'none';
			marginBottom.style.display = 'none';
			marginTop.style.display = 'block';
			marginLeft.style.display = 'block';
		}
	
		if(position.value == 'tr'){
			
			marginLeft.style.display = 'none';
			marginBottom.style.display = 'none';
			marginTop.style.display = 'block';
			marginRight.style.display = 'block';
		}
	
		if(position.value == 'br'){
			
			marginLeft.style.display = 'none';
			marginTop.style.display = 'none';
			marginBottom.style.display = 'block';
			marginRight.style.display = 'block';
		}
	
		if(position.value == 'bl'){
			
			marginRight.style.display = 'none';
			marginTop.style.display = 'none';
			marginBottom.style.display = 'block';
			marginLeft.style.display = 'block';
		}
	
		let newOptions	= {		

			size 			: document.getElementById('badge-size').value,
			position 		: position.value,
			marginTop 		: document.getElementById('badge-margin-top').value,
			marginBottom	: document.getElementById('badge-margin-bottom').value,
			marginLeft 		: document.getElementById('badge-margin-left').value,
			marginRight 	: document.getElementById('badge-margin-right').value,
			icon 			: document.querySelector('input[name="icon"]:checked').value,
			buttonText		: document.getElementById('badge-text').value, 
			buttonColor		: document.getElementById('badge-color').value,
			link			: profile.metadata.newReviewUri,
			rating			: profile.avgRating,
			border			: document.getElementById('badge-border').value
		};
		
		setOptions(newOptions);
	}
	
	const onSubmitError = (response) => {
       
		notifications.notify(t('Something went wrong, please try again later.'), 'error');        
    }
	
	const onSubmit = (e) => {
	
        e.preventDefault();
		
        businessesWidget.update(params.locationId, widgetId, {data: options}, onSubmitError).then((response) => {
         
			if (response.error) {
			
				onSubmitError(response);
            }else if (response !== false) {
		
                notifications.notify(t('Publication has been updated.'), 'success');

				navigator.clipboard.writeText(code);
            }
        }).finally(() => {
		
        });
    }
	//TODO - change stars rating to more simple and light solution without bg image
	const getRatingRange = (rval) =>{
		
		let formatted = 0;		
			
		if(rval > 0 && rval < 1){
			formatted = 5;
		}
		if(rval == 1){
			formatted = 10;
		}
		if(rval > 1 && rval < 2){
			formatted = 15;
		}
		if(rval == 2){
			formatted = 20;
		}
		if(rval > 2 && rval < 3){
			formatted = 25;
		}
		if(rval == 3){
			formatted = 30;
		}
		if(rval > 3 && rval < 4){
			formatted = 35;
		}
		if(rval == 4){
			formatted = 40;
		}
		if(rval > 4 && rval < 5){
			formatted = 45;
		}
		if(rval == 5){
			formatted = 50;
		}
		
		return formatted;
	}
	
	return (
	
        <>
			
			<BusinessViewHeader locationId={params.locationId} onProfileRefresh={onProfileRefresh} />
			
			<BusinessViewMenu locationId={params.locationId} active="widget" />			
   
			<div className={'row'}>
			
				<div className={'col-lg-12'}>
				
					<SimpleCardContainer title={t('Widget Generator')}>
					
						{options && (
						
							<>
							
								<form action="#" onChange={(e) => {generateWidget()}}>
								
									<div className={'row'}>
									
										<div className={'col-lg-3'}>
										
											<div className={'form-group py-2'}>
											
												<label htmlFor="badge-size" className="form-label fw-semibold">{t('Badge Type')}</label>
												
												<select id={'badge-size'} name="size" className={'form-control form-select'} value={options.size} onChange={(e) => {generateWidget()}}>
												
													<option value={'s'}>{t('Simple')}</option>
													
													<option value={'m'}>{t('Icon & Button')}</option>
													
													<option value={'l'}>{t('Large badge')}</option>
													
													<option value={'xl'}>{t('Rating badge')}</option>
													
												</select>	
												
											</div>
											
										</div>			
										
										<div className={'col-lg-3'}>
										
											<div className={'form-group py-2'}>
											
												<label htmlFor="badge-position" className="form-label fw-semibold">{t('Badge Position')}</label>
												
												<select id={'badge-position'} name="position" className={'form-control form-select'} value={options.position} onChange={(e) => {generateWidget()}}>
												
													<option value={'tl'}>{t('Top Left')}</option>
													
													<option value={'tr'}>{t('Top Right')}</option>
													
													<option value={'br'}>{t('Bottom Right')}</option>
													
													<option value={'bl'}>{t('Bottom Left')}</option>
													
												</select>				
												
											</div>
											
										</div>							
										
										<div id="badgeLeft" className={'col-lg-3'}  style={{display : options.position == 'tr' || options.position == 'br' ? 'none' : 'block'}}>
										
											<div className={'form-group py-2'}>									
											
												<label htmlFor="badge-margin-left" className="form-label fw-semibold">{t('Margin Left')}</label>
												
												<input type="number" id={'badge-margin-left'} name="marginLeft" className={'form-control'}  defaultValue={options.marginLeft} />
												
											</div>
											
										</div>	
										
										<div id="badgeBottom" className={'col-lg-3'}  style={{display : options.position == 'tl' || options.position == 'tr' ? 'none' : 'block'}}>
										
											<div className={'form-group py-2'}>
											
												<label htmlFor="badge-margin-bottom" className="form-label fw-semibold">{t('Margin Bottom')}</label>
												
												<input type="number" id={'badge-margin-bottom'} name="marginBottom" className={'form-control'} defaultValue={options.marginBottom} />
												
											</div>
											
										</div>
										
										<div id="badgeRight" className={'col-lg-3'} style={{display : options.position == 'tl' || options.position == 'bl' ? 'none' : 'block'}}>
										
											<div className={'form-group py-2'}>
											
												<label htmlFor="badge-margin-right" className="form-label fw-semibold">{t('Margin Right')}</label>
												
												<input type="number" id={'badge-margin-right'} name="marginLeft" className={'form-control'}  defaultValue={options.marginLeft} />
												
											</div>
											
										</div>	
										
										<div id="badgeTop" className={'col-lg-3'} style={{display : options.position == 'bl' || options.position == 'br' ? 'none' : 'block'}}>
										
											<div className={'form-group py-2'}>
											
												<label htmlFor="badge-margin-top" className="form-label fw-semibold">{t('Margin Top')}</label>
												
												<input type="number" id={'badge-margin-top'} name="marginTop" className={'form-control'} defaultValue={options.marginTop} />
												
											</div>
											
										</div>
										
									</div>
									
									<div className={'row'}>
									
										<div id="badgeText" className={'col-lg-6'}>
											
											<div className={'form-group py-2'}>
												
												<label htmlFor="badge-text" className="form-label fw-semibold">{t('Button Text')}</label>
											
												<input type="text" id={'badge-text'} name="button[text]" className={'form-control'} defaultValue={options.buttonText} />
											
											</div>
										
										</div>
										
										<div id="badgeBorder" className={'col-lg-3'}>
											
											<div className={'form-group py-2'}>
												
												<label htmlFor="badge-border" className="form-label fw-semibold">{t('Show Border?')}</label>
												
												<select id={'badge-border'} name="border" className={'form-control form-select'} value={options.border ? options.border :  'yes'} onChange={(e) => {generateWidget()}}>
												
													<option value={'yes'}>{t('Yes')}</option>
													
													<option value={'no'}>{t('No')}</option>
													
												</select>				
												
											</div>
											
										</div>
										
										<div id="badgeColor" className={'col-lg-3'}>
											
											<div className={'form-group py-2'}>
												
												<label htmlFor="badge-color" className="form-label fw-semibold">{t('Button Color')}</label>
												
												<input type="color" id={'badge-color'} name="button[color]" className={'form-control form-control-color'} value={options.buttonColor || '#000000'} onChange={console.log('color')}/>
											
											</div>
											
										</div>
										
									</div>
									
									<style>{style}</style>
									
									<div className={'row'}>
										
										<div className={'col-lg-12'}>
											
											<div className={'form-group py-2'}>
												
												<div className={'row'}>
													
													<label htmlFor="badge-icon" className="form-label fw-semibold">{t('Badge view')}</label>
												
													{options.size == 'xl' ? (
													
														<>
														
															<input style={{display:'none'}} type="radio" name="icon" id={'badge-margin-icon-0'} className={'mb-2 form-check-input'}  defaultValue={0} defaultChecked="true" />
																				
															<div className={'col-lg-12'}>
															
																<div className={'lbGbadge-wraper lbGbadge-'+options.position+' lbGbadge-'+options.size+' lbGbadge-border-' + (options.border!=undefined ? options.border : 'yes')}>
																	
																	<a href="#" className={'lbGbadge-link'}>																								
																		<div className={'lbGbadge-rating lbGbadge-rating-'+getRatingRange(profile.avgRating)}><span>{Math.round(profile.avgRating)}</span></div>
																	</a>
																	
																	<a href="#" className={'lbGbadge-btn'} style={{backgroundColor:options.buttonColor}}>{options.buttonText}</a>
																																	
																</div>
																
															</div>
														</>
													
													) : (
													
														<>
															
															{options && icons.map((icon, index) => {
															
																return (
																
																	<div className={'col-lg-'+ (options.size == 'l' ? 6 : 2)} key={index}>
																	
																		<div className={'form-group py-2'}>
																		
																			<input style={{display:'block'}} type="radio" name="icon" id={'badge-margin-icon-'+index} className={'mb-2 form-check-input'}  defaultValue={index} defaultChecked={index == options.icon ? true : false} />
																				
																			<div className={'lbGbadge-wraper lbGbadge-'+options.position+' lbGbadge-'+options.size+' lbGbadge-border-' + (options.border!=undefined ? options.border : 'yes')}>
																				
																				<a href="#" className={'lbGbadge-link'}>
																					<img src={icon}/>													
																					<div className={'lbGbadge-rating lbGbadge-rating-'+getRatingRange(profile.avgRating)}><span>{Math.round(profile.avgRating)}</span></div>
																				</a>
																				
																				<a href="#" className={'lbGbadge-btn'} style={{backgroundColor:options.buttonColor}}>{options.buttonText}</a>
																				
																			</div>
																			
																		</div>
																		
																	</div>
																)
															})}
														
														</>
													
													)}
													
												</div>
												
											</div>
											
										</div>
									
									</div>
									
									<div className={'row mt-4'}>
										
										<div className={'col-lg-12'}>
										
											<button className="btn btn-primary" onClick={onSubmit}>{t('Save & Copy')}</button>
										
										</div>
									
									</div>
								
								</form>
							</>
						)}			
							
						<div className={'row mt-4'}>
						
							<div className={'col-lg-12'}>
							
								<pre id="intSript" style={{whiteSpace:'pre-wrap',background:'whitesmoke',padding:'15px'}}>{code}</pre>
								
							</div>
							
						</div>
						
					</SimpleCardContainer>
					
				</div>
				
            </div> 
			
        </>
    );
}

export default BusinessesWidget;