import React, {useEffect} from 'react';
import {Link, Navigate, NavLink, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useApi} from "../../providers/ApiProvider";
import {useAuth} from "../../providers/AuthProvider";
import BusinessesModel from "../../models/BusinessesModel";

function BusinessViewMenu(props) {
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const [locationInfo, setLocationInfo] = React.useState({});
    const [canOperateLocalPost, setCanOperateLocalPost] = React.useState(false);


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                setLocationInfo(response);
                setCanOperateLocalPost(response.data.metadata.canOperateLocalPost !== false);
            }
        });
    }, []);

    const items = [
        {id: 'profile', label: t('Profile Info'), url: '/businesses/'+props.locationId, icon: 'user-circle'},
        {id: 'rules', label: t('Response Rules'), url: '/businesses/'+props.locationId+'/reviews-rules', icon: 'message-2'},
        {id: 'reviews', label: t('Reviews'), url: '/businesses/'+props.locationId+'/reviews', icon: 'star'},
        {id: 'media', label: t('Media'), url: '/businesses/'+props.locationId+'/media', icon: 'photo'},
        {id: 'publications', label: t('Publications'), url: '/businesses/'+props.locationId+'/publications', icon: 'file-text'},
        {id: 'insights', label: t('Insights'), url: '/businesses/'+props.locationId+'/insights', icon: 'chart-bar'},
        {id: 'widget', label: t('Widget'), url: '/businesses/'+props.locationId+'/widget', icon: 'brand-windows'},
    ];

    if (!canOperateLocalPost) {
        items.splice(4, 1);
    }

    return (
        <ul className="nav nav-pills user-profile-tab justify-content-end m-block-gap bg-light-info rounded-2">
            {items.map((item, index) => {
                const elementClass = 'nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6'+((props.active===item.id)?(' active'):(''));
                return (
                    <li key={index} className="nav-item" role="presentation">
                        <Link to={item.url} className={elementClass}>
                            <i className={'ti ti-'+item.icon+' me-lg-2 fs-6'}></i>
                            <span className="d-none d-lg-block">{item.label}</span>
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
}

export default BusinessViewMenu;
