import * as React from "react";
import i18next from 'i18next';


function isGermanPrioritizedOverEnglish() {
    const languages = navigator.languages;

    const germanIndex = languages.indexOf('de');
    const englishIndex = languages.indexOf('en');

    // Check if German is in the list and comes before English
    return germanIndex !== -1 && (englishIndex === -1 || germanIndex < englishIndex);
}


const LANG_EN = 'en';
const LANG_DE = 'de';
const translationStructure = {
    language: LANG_EN,
    translate: () => {}
}

const TranslationContext = React.createContext(translationStructure);


function TranslationProvider({children}) {
    let savedLanguage = localStorage.getItem('language');
    if (!savedLanguage) {
        if (isGermanPrioritizedOverEnglish()) {
            savedLanguage = LANG_DE;
        } else {
            savedLanguage = LANG_EN;
        }
        localStorage.setItem('language', savedLanguage);
    }
    const [language, setLanguage] = React.useState(savedLanguage);

    const translate = (lang) => {
        let newLang = LANG_EN;
        if (lang === LANG_DE) {
            newLang = LANG_DE;
        }
        i18next.changeLanguage(newLang).then(() => {
            setLanguage(newLang);

            localStorage.setItem('language', newLang);
            window.location.reload();
        });
    };

    let value = {...translationStructure};
    value.language = language;
    value.translate = translate;

    return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>;
}


function useTranslation() {
    return React.useContext(TranslationContext);
}


export {TranslationProvider, useTranslation, isGermanPrioritizedOverEnglish};