import React, {useEffect} from 'react';
import InputMask from 'react-input-mask';
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";
import ErrorsHelper from "../../../helpers/ErrorsHelper";

function BusinessFormPhoneNumbers(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [saveLoading, setSaveLoading] = React.useState(false);
    const [data, setData] = React.useState({
        primaryPhone: null,
        additionalPhones: []
    });


    useEffect(() => {
        let newData = props.data;

        if (newData && newData.primaryPhone) {
            newData.primaryPhone = newData.primaryPhone.replace(/[^0-9]/g, '');
        }

        if (newData && newData.additionalPhones && newData.additionalPhones.length > 0) {
            newData.additionalPhones = newData.additionalPhones.map((phone) => {
                return phone.replace(/[^0-9]/g, '');
            });
        }

        setData(newData);
    }, [props.data]);


    const updatePhone = (type, index, value) => {
        value = value.replace(/[^0-9]/g, '');

        if (type === 'primary') {
            setData({
                ...data,
                primaryPhone: value
            });
        } else {
            const newAdditionalPhones = data.additionalPhones.map((phone, i) => {
                if (i === index) {
                    return value;
                }
                return phone;
            });
            setData({
                ...data,
                additionalPhones: newAdditionalPhones
            });
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();

        //remove empty additional phones
        let newAdditionalPhones = [];
        if (data.additionalPhones && data.additionalPhones.length > 0) {
            newAdditionalPhones = data.additionalPhones.filter((phone) => {
                return phone !== '';
            });
        }

        setData({
            ...data,
            additionalPhones: newAdditionalPhones
        });

        //validate
        let validationError = false;
        if (data.primaryPhone === null || data.primaryPhone === '') {
            validationError = t('Primary phone number is required.')
        } else
        if (validatePhoneNumber(data.primaryPhone) === false) {
            validationError = t('Primary phone number is invalid.')
        } else
        if (data.additionalPhones && data.additionalPhones.length > 0) {
            for (let i = 0; i < data.additionalPhones.length; i++) {
                if (data.additionalPhones[i] === null || data.additionalPhones[i] === '') {
                    validationError = t('Additional phone number is required.')
                }
                if (validatePhoneNumber(data.additionalPhones[i]) === false) {
                    validationError = t('Additional phone number is invalid.')
                }
            }
        }
        if (validationError) {
            notificationsContext.notify(validationError, 'error');
            return;
        }


        setSaveLoading(true);

        businessesModel.update(params.locationId, {phoneNumbers: data}, saveErrorHandler).then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
                setEditMode(false);
            }

            setSaveLoading(false);
        });
    }


    const saveErrorHandler = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
    }


    const onCancel = (e) => {
        e.preventDefault();

        setData(props.data);
        setEditMode(false);
    }


    const removePhoneNumber = (index) => {
        const newAdditionalPhones = data.additionalPhones.filter((phone, i) => {
            return i !== index;
        });
        setData({
            ...data,
            additionalPhones: newAdditionalPhones
        });
    }


    const addPhoneNumber = () => {
        let newAdditionalPhones = [''];
        if (data.additionalPhones) {
            newAdditionalPhones = [...data.additionalPhones, ''];
        }
        setData({
            ...data,
            additionalPhones: newAdditionalPhones
        });
    }


    const validatePhoneNumber = (phone, required) => {
        if (phone === null || phone === '') {
             return (required !== true);
        }
        const regex = new RegExp('^\\d{3,15}$');
        const results = regex.test(phone);

        return results;
    }


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <BusinessFormHeadingProgress
                score={props.score}
                editMode={editMode}
                title={t('Phone number')}
                id="phone-numbers"
            />

            {editMode === false && (
                <div className="pt-2">
                    {data.primaryPhone && (
                        <div className="fw-semibold">{data.primaryPhone}</div>
                    )}
                    {data.additionalPhones && data.additionalPhones.map((phone, index) => {
                        return (
                            <div className="" key={index}>{phone}</div>
                        );
                    })}
                </div>
            )}

            {editMode === true && (
                <>
                <div className="fs-2 mb-3 mt-1">{t('Provide a number that connects directly to your business.')}</div>

                <div className="row">
                    <div className="col-md-6 col-xl-6">
                        <div className="mb-3 mt-2">
                            <span className="fs-2 fw-semibold d-block mb-1">{t('Primary Phone')}</span>
                            <InputMask className={'form-control'+((!validatePhoneNumber(data.primaryPhone, true))?(' is-invalid'):(''))} mask="999999999999999" maskChar={''} value={data.primaryPhone} onChange={(e) => {updatePhone('primary', 0, e.target.value)}} />
                        </div>

                        {data.additionalPhones && data.additionalPhones.map((phone, index) => {
                            return (
                                <div className="mb-3" key={index}>
                                    <span className="fs-2 fw-semibold d-block mb-1">{t('Additional Phone')}</span>
                                    <div className="d-flex">
                                        <InputMask className={'form-control flex-grow-1'+((!validatePhoneNumber(phone, false))?(' is-invalid'):(''))} mask="999999999999999" maskChar={''} value={phone} onChange={(e) => {updatePhone('additional', index, e.target.value)}} />
                                        <button className="btn btn-light-danger text-danger ms-2" onClick={(e) => {removePhoneNumber(index)}}><i className={'ti ti-x'}></i></button>
                                    </div>
                                </div>
                            );
                        })}

                        {(!data.additionalPhones || data.additionalPhones && data.additionalPhones.length < 2) && (
                        <div className="mb-3">
                            <button className="btn btn-light-primary text-primary" onClick={addPhoneNumber}>{t('Add an additional number')}</button>
                        </div>
                        )}

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel} />
                    </div>
                    <div className="col-md-6 col-xl-6">
                        <div className="bordered-tip pb-4">
                            <div className="alert alert-warning" role="alert">
                                <strong>{t('Be aware of changing phone numbers too often!')}</strong>
                                <span className="d-block fs-2 mt-2">{t('Please, try to avoid changing your primary and additional phone numbers more often than several times per hour, and more than 10 times per day.')}</span>
                                <span className="d-block fs-2 mt-2">{t('Violating these rules can result in temporary block for your Business.')}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </div>
        </>
    );
}

export default BusinessFormPhoneNumbers;
