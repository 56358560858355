import React, {useEffect} from 'react';
import AddressAutocomplete from "../../ui/AddressAutocomplete";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import ErrorsHelper from "../../../helpers/ErrorsHelper";

function BusinessFormServiceArea(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [data, setData] = React.useState({});
    const [saveLoading, setSaveLoading] = React.useState(false);


    useEffect(() => {
        setData(props.data);
    }, [props.data]);


    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        businessesModel.update(params.locationId, {serviceArea: data}, saveErrorHandler).then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
                setEditMode(false);
            }

            setSaveLoading(false);
        });
    }


    const saveErrorHandler = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
    }


    const onCancel = (e) => {
        e.preventDefault();

        setData(props.data);
        setEditMode(false);
    }


    const updatePlace = (oldPlace, newPlace) => {
        let newData = {...data};

        newData.places.placeInfos = newData.places.placeInfos.map((place) => {
            if (place.placeId === oldPlace.placeId) {
                return {
                    placeId: newPlace.place_id,
                    placeName: newPlace.formatted_address
                };
            } else {
                return place;
            }
        });

        setData(newData);
    }


    const removePlace = (placeId) => {
        let newData = {...data};

        newData.places.placeInfos = newData.places.placeInfos.filter((place) => {
            return place.placeId !== placeId;
        });

        setData(newData);
    }


    const addPlace = () => {
        let newData = {...data};

        if (newData.places) {
            newData.places.placeInfos.push({
                placeId: '',
                placeName: ''
            });
        } else {
            newData.places = {
                placeInfos: [{
                    placeId: '',
                    placeName: ''
                }]
            };
        }

        setData(newData);
    }


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <div className="d-flex justify-content-between">
                <label htmlFor="cf-title" className="align-items-center d-flex form-label fw-semibold gap-2 justify-content-start mb-0">
                    {t('Service area')}
                    <i className={'fs-5 ti ti-pencil d-editable text-muted'+(editMode?' d-none':'')}></i>
                </label>
            </div>
            {editMode === false && (
                <div className="p-small-margins pt-2">{data && data.places && data.places.placeInfos && data.places.placeInfos.map((place, index) => (
                    <p key={index}>{place.placeName}</p>
                ))}</div>
            )}
            {editMode === true && (
                <>
                <div className="row">
                    <div className="col-md-6 col-xl-6">
                        <div className="fs-2 mb-3 mt-1">{t('Let customers know where your business provides deliveries or services.')}</div>
                        {data && data.places && data.places.placeInfos && data.places.placeInfos.map((place, index) => (
                            <div className="d-flex mt-2" key={index}>
                                <AddressAutocomplete
                                    value={place.placeName ? place.placeName : ''}
                                    onChange={(newPlace) => {updatePlace(place, newPlace)}}
                                    //componentRestrictions={{country: props.regionCode}}
                                    className={'flex-grow-1'}
                                />

                                <button className="btn btn-light-danger text-danger ms-2" onClick={(e) => {removePlace(place.placeId)}}><i className={'ti ti-x'}></i></button>
                            </div>
                        ))}
                        <div className="mt-2">
                            <button className="btn btn-light-primary text-primary" onClick={addPlace}>{t('Add a Service Area')}</button>
                        </div>

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel} />
                    </div>
                </div>
                </>
            )}
        </div>
        </>
    );
}

export default BusinessFormServiceArea;
