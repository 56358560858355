/**
 * Businesses Media model
 *
 * @param {ApiHelper} api
 */
class BusinessesMediaModel {
    constructor(api) {
        this.api = api;
    }


    async listOwn(locationId) {
        const url = '/businesses/' + locationId + '/media/list-own';

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):([]);
    }


    async listFromCustomers(locationId) {
        const url = '/businesses/' + locationId + '/media/list-from-customers';

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):([]);
    }


    async upload(locationId, category, files, errorHandler) {
        const url = '/businesses/' + locationId + '/media/upload';

        const response = await this.api.postRequestFile(url, {
            category: category,
            files: files
        });

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async delete(locationId, pk) {
        const url = '/businesses/' + locationId + '/media/' + pk + '/delete';

        const response = await this.api.deleteRequest(url);
        return (response.success === true)?(response.data):([]);
    }
}

export default BusinessesMediaModel;