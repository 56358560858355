import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";

import ProfilePhotoCard from "./ProfilePhotoCard";
import ChangePasswordCard from "./ChangePasswordCard";
import PersonalInfoCard from "./PersonalInfoCard";
import AccountModel from "../../models/AccountModel";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

function AccountInfoTab(props) {
    const { t} = useTranslation();
    const {language} = useMyTranslation();
    const apiContext = useApi();
    const accountModel = new AccountModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();

    const [personalInfo, setPersonalInfo] = React.useState({
        name: '',
        email: '',
        avatar: ''
    });
    const resetPersonalInfo = () => {
        accountModel.getPersonalInfo().then((response) => {
            if (response !== false) {
                setPersonalInfo({
                    name: response.name,
                    email: response.email,
                    avatar: response.avatar
                });
            }
        });
    }


    useEffect(() => {
        breadcrumbs.update({
            title: t('Account Settings'),
            breadcrumbs: [
                {label: t('Account Settings')}
            ]
        });

        document.title = t('Account Settings')+' | LocalBoost';

        resetPersonalInfo();
    }, [language]);


    return (
        <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch">
                <ProfilePhotoCard
                    setAlertNotification={props.setAlertNotification}
                    accountModel={accountModel}
                    personalInfo={personalInfo}
                    resetPersonalInfo={resetPersonalInfo}
                />
            </div>
            <div className="col-lg-4 d-flex align-items-stretch">
                <PersonalInfoCard
                    setAlertNotification={props.setAlertNotification}
                    accountModel={accountModel}
                    name={personalInfo.name}
                    email={personalInfo.email}
                    resetPersonalInfo={resetPersonalInfo}
                />
            </div>
            <div className="col-lg-4 d-flex align-items-stretch">
                <ChangePasswordCard
                    setAlertNotification={props.setAlertNotification}
                    accountModel={accountModel}
                />
            </div>
        </div>
    );
}

export default AccountInfoTab;
