import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import DangerModal from "../../ui/DangerModal";
import {useTranslation} from "react-i18next";


function BusinessesMediaCard(props) {
    const { t} = useTranslation();
    const params = useParams();

    const [modalVisible, setModalVisible] = React.useState(false);


    useEffect(() => {
    }, []);


    const showModal = () => {
        setModalVisible(true);
    }


    return (
        <div className="card overflow-hidden mb-3">
            <div className="el-card-item pb-3">
                <div className="el-card-avatar mb-3 el-overlay-1 w-100 overflow-hidden position-relative text-center">
                    <Link to={props.data.googleUrl} target="_blank" className="image-popup-vertical-fit">
                        <img src={props.data.googleUrl.replace('=s0', '=s700')} className="d-block position-relative w-100" alt="" />
                    </Link>
                </div>
                <div className="el-card-content text-center">
                    <div className="d-flex align-items-center justify-content-between px-3 mb-2">
                        <Link to={'/businesses/'+params.locationId+'/media/'+((props.data.locationAssociation && props.data.locationAssociation.category) ? props.data.locationAssociation.category.toLowerCase() : '')} className="btn btn-light-primary text-primary btn-sm">{((props.data.locationAssociation && props.data.locationAssociation.category) ? props.keysToLabels[props.data.locationAssociation.category] : 'No category')}</Link>

                        <ButtonWithLoading
                            className="btn-light-danger btn-sm btn-circle justify-content-center text-danger"
                            noOriginalIconClassName={true}
                            iconClassName="ti ti-trash fs-4"
                            noOriginalSpinnerClassName={true}
                            spinnerClassName=""
                            label=""
                            onClick={showModal}
                            loading={props.deleteProcessing}
                        />
                        <DangerModal visible={modalVisible}>
                            <div className="text-center text-danger">
                                <i className="ti ti-hexagon-letter-x fs-7"></i>
                                <h4 className="mt-2">{t('Are you sure?')}</h4>
                                <p className="mt-3">{t('Please, confirm you want to delete this media from your business. This action cannot be undone.')}</p>

                                <div className="d-flex align-items-center justify-content-center gap-4 my-3">
                                    <button type="button" className="btn btn-light" onClick={() => {setModalVisible(false)}}>
                                        {t('Cancel')}
                                    </button>

                                    <ButtonWithLoading
                                        className="btn-danger justify-content-center"
                                        iconClassName="ti-trash"
                                        label={t('Confirm')}
                                        onClick={() => {setModalVisible(false); props.onDelete(props.data.name.split('/').reverse()[0])}}
                                        loading={props.deleteProcessing}
                                    />
                                </div>
                            </div>
                        </DangerModal>
                    </div>
                    <div className="text-muted fs-1">{(new Date(props.data.createTime)).toLocaleString()}</div>
                </div>
            </div>
        </div>
    );
}

export default BusinessesMediaCard;
