import React, {Fragment, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import Chart from "react-apexcharts";
import BusinessViewMenu from "../BusinessViewMenu";
import BusinessesInsightsChart from "./BusinessesInsightsChart";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";
import StrHelper from "../../../helpers/StrHelper";
import BusinessesReportingModel from "../../../models/BusinessesReportingModel";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";

export default function BusinessesInsightsReporting(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const {locationId} = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const businessesReportingModel = new BusinessesReportingModel(apiContext.api, locationId);
    const breadcrumbs = useBreadcrumbs();
    const [loading, setLoading] = React.useState('');

    const repeatTypes = {
        'daily': t('Daily'),
        'weekly': t('Weekly'),
        'biweekly': t('Biweekly'),
        'monthly': t('Monthly')
    };

    const [startDate, setStartDate] = React.useState('');
    const [repeatType, setRepeatType] = React.useState('');
    const [recipients, setRecipients] = React.useState('');
    const [notificationParamsVisible, setNotificationParamsVisible] = React.useState(false);
    const [subject, setSubject] = React.useState('');
    const [content, setContent] = React.useState('');
    const [isActive, setIsActive] = React.useState(false);

    const onProfileRefresh = (profile, lastUpdated) => {
        return true;
    }
    
    useEffect(() => {
        businessesModel.view(locationId).then((response) => {
            if (response !== false) {
                breadcrumbs.update({
                    title: response.data.title,
                    breadcrumbs: [
                        {label: t('Businesses List'), url: '/businesses'},
                        {label: response.data.title, url: '/businesses/'+locationId},
                        {label: t('Insights'), url: '/businesses/'+locationId+'/insights'},
                        {label: t('Reporting')}
                    ]
                });

                document.title = t('Reporting')+' | '+response.data.title+' | LocalBoost';
            }
        }).finally(() => {
        });

        getReporting();
    }, [language]);


    const getReporting = () => {
        businessesReportingModel.view().then((response) => {
            if (response) {
                setStartDate(response.start_date);
                setRepeatType(response.repeat_type);
                setRecipients(response.recipients);
                if (response.notification_parameters) {
                    if (response.notification_parameters.subject) {
                        setSubject(response.notification_parameters.subject);
                    }
                    if (response.notification_parameters.content) {
                        setContent(response.notification_parameters.content);
                    }
                } else {
                    setSubject('');
                    setContent('');
                }
                setIsActive(response.is_active);
            } else {
                setStartDate('');
                setRepeatType('');
                setRecipients('');
                setSubject('');
                setContent('');
                setIsActive(false);
            }
        });
    }


    const cancelReporting = () => {
        setLoading('cancel');

        businessesReportingModel.delete().then((response) => {
            if (response) {
                getReporting();
            }
        }).finally(() => {
            if (loading === 'cancel') {
                setLoading('');
            }
        });
    }


    return (
        <>
            <div id="insightsWrap" >				
				<div id="businessHeader">
					<BusinessViewHeader
						locationId={locationId}
						onProfileRefresh={onProfileRefresh}
					/>
				</div>

				<div id="businessMenu" data-html2canvas-ignore="true">
					<BusinessViewMenu locationId={locationId} active="insights" />
				</div>

				<div className={'row'}>
					<div className={'col-lg-8 d-flex align-items-stretch'}>
                        <SimpleCardContainer
                            title={t('Reporting')}
                            loading={false}
                        >

                            <div className="row">
                                <div className="col-lg-3">
                                    <div className={'form-group mb-3'}>
                                        <label className="form-label fw-semibold mb-2">{t('Start Date')}</label>

                                        <input id={'cd-name'} type="date" className={'form-control'} value={startDate} onChange={(e) => {
                                            setStartDate(e.target.value)
                                        }}/>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className={'form-group mb-3'}>
                                        <label className="form-label fw-semibold mb-2">{t('Repeat Period')}</label>

                                        <select id={'cd-button'} className={'form-control form-select'} value={repeatType} onChange={(e) => {
                                            setRepeatType(e.target.value)
                                        }}>
                                            {Object.keys(repeatTypes).map((key) => {
                                                return <option value={key} key={key}>{repeatTypes[key]}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className={'form-group mb-3'}>
                                <label className="form-label fw-semibold mb-2">{t('Recipient')}</label>

                                <input id={'cd-name'} type="text" className={'form-control'} value={recipients} onChange={(e) => {
                                    setRecipients(e.target.value)
                                }}/>
                            </div>

                            <button className="btn btn-sm btn-light-primary text-primary mb-3"
                                    onClick={(e) => setNotificationParamsVisible(!notificationParamsVisible)}>Set custom email preferences
                            </button>

                            {notificationParamsVisible && (<>

                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className={'form-group mb-3'}>
                                            <label className="form-label fw-semibold mb-2">{t('Email Subject')}</label>

                                            <input id={'cd-name'} type="text" className={'form-control'} value={subject} onChange={(e) => {
                                                setSubject(e.target.value)
                                            }}/>
                                        </div>

                                        <div className={'form-group mb-3'}>
                                            <label className="form-label fw-semibold mb-2">{t('Email Message')}</label>

                                            <textarea id={'cd-name'} className={'form-control'} value={content} onChange={(e) => {
                                                setContent(e.target.value)
                                            }}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </>)}

                            <div className="form-check mb-3">
                                <input
                                    type="checkbox"
                                    id={'closed'}
                                    name={'closed'}
                                    className={'form-check-input'}
                                    checked={isActive}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        setIsActive(!isActive);
                                    }}
                                />
                                <label
                                    className="form-check-label cursor-pointer"
                                    htmlFor={'closed'}>{t('Enable sending scheduled reporting')}</label>
                            </div>

                            <div className="d-flex gap-3">
                                <ButtonWithLoading
                                    className={'btn btn-primary'}
                                    onClick={() => {
                                        setNotificationParamsVisible(true);
                                    }}
                                    noOriginalIconClassName={true}
                                    loading={false}
                                    label={t('Update settings')}
                                />

                                <ButtonWithLoading
                                    className={'btn btn-light-danger text-danger'}
                                    onClick={cancelReporting}
                                    noOriginalIconClassName={true}
                                    loading={false}
                                    label={t('Disable reporting')}
                                />
                            </div>

                        </SimpleCardContainer>
                    </div>
                    <div className={'col-lg-4'}>
                        <strong>Start Date:</strong> {startDate}<br />
                        <strong>Repeat Period:</strong> {repeatTypes[repeatType]}<br />
                        <strong>Recipients:</strong> {recipients}<br />
                        <strong>Email Subject:</strong> {subject}<br />
                        <strong>Email Message:</strong> {content}<br />
                        <strong>Enable sending scheduled reporting:</strong> {isActive ? 'Yes' : 'No'}
                    </div>
                </div>
            </div>
        </>
    );
}