import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useParams} from "react-router-dom";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useTranslation} from "react-i18next";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";

function BusinessFormTitle(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [data, setData] = React.useState('');
    const [errors, setErrors] = React.useState([]);
    const [saveLoading, setSaveLoading] = React.useState(false);


    useEffect(() => {
        setData(props.data);
    }, [props.data]);


    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        businessesModel.update(params.locationId, {title: data}, onSubmitError).then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
                setEditMode(false);
            }

            setSaveLoading(false);
        });
    }


    const onCancel = (e) => {
        e.preventDefault();

        setData(props.data);
        setEditMode(false);
    }


    const onSubmitError = (response) => {
        const categorizedFields = ['summary', 'topic_type', 'event.title', 'event.schedule.start_date', 'event.schedule.start_time', 'event.schedule.end_date', 'event.schedule.end_time', 'call_to_action.action_type', 'call_to_action.url', 'offer.couponCode', 'offer.redeemOnlineUrl', 'offer.termsConditions'];
        if (response.error && response.error.details) {
            let curErrors = {};
            response.error.details.forEach((detail, index) => {
                if (detail.errorDetails) {
                    detail.errorDetails.forEach((errorDetail, index) => {
                        let fieldName = 'uncategorized';
                        if (errorDetail.field && (categorizedFields.indexOf(errorDetail.field) !== -1)) {
                            fieldName = errorDetail.field;
                        }
                        if (curErrors[fieldName] === undefined) {curErrors[fieldName] = []}

                        const fieldIds2Labels = {
                            'summary': t('Summary'),
                            'topic_type': t('Topic type'),
                            'event.title': t('Event title'),
                            'event.schedule.start_date': t('Event start date'),
                            'event.schedule.start_time': t('Event start time'),
                            'event.schedule.end_date': t('Event end date'),
                            'event.schedule.end_time': t('Event end time'),
                            'call_to_action.action_type': t('Call to action type'),
                            'call_to_action.url': t('Call to action URL'),
                        }
                        let message = errorDetail.message;
                        if (fieldIds2Labels[fieldName]) {
                            message = message.replace(fieldName, fieldIds2Labels[fieldName]);
                        }
                        curErrors[fieldName].push(message);
                    });
                }
            });

            setErrors(errors);
            setSaveLoading(false);
            notificationsContext.notify(t('Please, fix errors before continuing.'), 'error');
        }
    }


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <BusinessFormHeadingProgress
                score={props.score}
                editMode={editMode}
                title={t('Business name')}
                id="title"
            />

            {editMode === false && (
                <div className="pt-2">{data}</div>
            )}
            {editMode === true && (
                <>
                <div className="fs-2 mb-3 mt-1">{t('Enter your business name as it appears to customers in the real world.')}</div>
                <div className="row">
                    <div className="col-md-6">
                        <input type="text" className="form-control" id="cf-title" defaultValue={data} onChange={(e) => {
                            setData(e.target.value)
                        }}/>

                        <div className="d-flex justify-content-between">
                            {errors && <div className="form-text text-danger">{errors.join('. ')}</div>}
                            <div className="text-end flex-grow-1"><small className="form-text text-muted">{data ? data.length : 0} /
                                125</small></div>
                        </div>

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel}/>
                    </div>
                </div>
                </>
            )}
        </div>
        </>
    );
}

export default BusinessFormTitle;
