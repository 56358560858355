let BCP47LanguageCodes = {
    'languages': {
        'de': 'Deutsch',
        'en': 'English',
        'es': 'Español',
        'fr': 'Français',
        'it': 'Italiano',
        'uk': 'Українська мова',
        'ja': '日本語',
        'zh': '中文',
        'ru': 'Русский',
        'ar': 'العربية',
        'pt': 'Português',
        'pl': 'Polski',
        'nl': 'Nederlands',
        'cs': 'Čeština',
        'tr': 'Türkçe',
        'ko': '한국어',
        'sv': 'Svenska',
        'da': 'Dansk',
        'fi': 'Suomi',
        'no': 'Norsk',
        'hu': 'Magyar',
        'el': 'Ελληνικά',
        'ro': 'Română',
        'sk': 'Slovenčina',
        'th': 'ไทย',
        'he': 'עברית',
        'id': 'Bahasa Indonesia',
        'hi': 'हिन्दी',
        'ms': 'Bahasa Melayu',
        'vi': 'Tiếng Việt',
        'bg': 'Български',
        'hr': 'Hrvatski',
        'lt': 'Lietuvių',
        'sr': 'Српски',
        'sl': 'Slovenščina',
        'et': 'Eesti',
        'lv': 'Latviešu',
        'fa': 'فارسی',
        'sq': 'Shqip',
        'bs': 'Bosanski',
        'is': 'Íslenska',
        'mk': 'Македонски',
        'sw': 'Kiswahili',
        'tl': 'Tagalog',
        'ta': 'தமிழ்',
        'bn': 'বাংলা',
        'ml': 'മലയാളം',
        'mr': 'मराठी',
        'ne': 'नेपाली',
        'pa': 'ਪੰਜਾਬੀ',
        'si': 'සිංහල',
        'km': 'ខ្មែរ'
    }
}

BCP47LanguageCodes.languages = Object.keys(BCP47LanguageCodes.languages)
    .sort()
    .reduce((acc, key) => ({
        ...acc,
        [key]: BCP47LanguageCodes.languages[key]
    }), {});

export default BCP47LanguageCodes;