import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";

import imgProfileDefault from 'modernize-essentials/dist/images/profile/user-1.jpg';
import AccountModel from "../../models/AccountModel";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

function NotificationsTab(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const apiContext = useApi();
    const accountModel = new AccountModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();


    useEffect(() => {
        breadcrumbs.update({
            title: t('Notifications'),
            breadcrumbs: [
                {label: t('Account Settings'), url: '/settings'},
                {label: t('Notifications')}
            ]
        });

        document.title = t('Notifications')+' | LocalBoost';
    }, [language]);


    const onEdit = (pk) => {
    }


    return (
        <div className="row justify-content-center">
            <div className="col-lg-9">
                <div className="card">
                    <div className="card-body p-4">
                        <h4 className="fw-semibold mb-3">Notification Preferences</h4>
                        <p>
                            Select the notificaitons ou would like to receive via email. Please note that you cannot opt out of receving service
                            messages, such as payment, security or legal notifications.
                        </p>
                        <form className="mb-7">
                            <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Email Address*</label>
                            <input type="text" className="form-control" id="exampleInputtext" placeholder="" required/>
                            <p className="mb-0">Required for notificaitons.</p>
                        </form>
                        <div className="">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="bg-light rounded-1 p-6 d-flex align-items-center justify-content-center">
                                        <i className="ti ti-article text-dark d-block fs-7" width="22" height="22"></i>
                                    </div>
                                    <div>
                                        <h5 className="fs-4 fw-semibold">Our newsletter</h5>
                                        <p className="mb-0">We'll always let you know about important changes</p>
                                    </div>
                                </div>
                                <div className="form-check form-switch mb-0">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="bg-light rounded-1 p-6 d-flex align-items-center justify-content-center">
                                        <i className="ti ti-checkbox text-dark d-block fs-7" width="22" height="22"></i>
                                    </div>
                                    <div>
                                        <h5 className="fs-4 fw-semibold">Order Confirmation</h5>
                                        <p className="mb-0">You will be notified when customer order any product</p>
                                    </div>
                                </div>
                                <div className="form-check form-switch mb-0">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked={true}/>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="bg-light rounded-1 p-6 d-flex align-items-center justify-content-center">
                                        <i className="ti ti-clock-hour-4 text-dark d-block fs-7" width="22" height="22"></i>
                                    </div>
                                    <div>
                                        <h5 className="fs-4 fw-semibold">Order Status Changed</h5>
                                        <p className="mb-0">You will be notified when customer make changes to the order</p>
                                    </div>
                                </div>
                                <div className="form-check form-switch mb-0">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked={true}/>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="bg-light rounded-1 p-6 d-flex align-items-center justify-content-center">
                                        <i className="ti ti-truck-delivery text-dark d-block fs-7" width="22" height="22"></i>
                                    </div>
                                    <div>
                                        <h5 className="fs-4 fw-semibold">Order Delivered</h5>
                                        <p className="mb-0">You will be notified once the order is delivered</p>
                                    </div>
                                </div>
                                <div className="form-check form-switch mb-0">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="bg-light rounded-1 p-6 d-flex align-items-center justify-content-center">
                                        <i className="ti ti-mail text-dark d-block fs-7" width="22" height="22"></i>
                                    </div>
                                    <div>
                                        <h5 className="fs-4 fw-semibold">Email Notification</h5>
                                        <p className="mb-0">Turn on email notificaiton to get updates through email</p>
                                    </div>
                                </div>
                                <div className="form-check form-switch mb-0">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="d-flex align-items-center justify-content-end gap-3">
                    <button className="btn btn-primary">Save</button>
                    <button className="btn btn-light-danger text-danger">Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default NotificationsTab;
