import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

export default function CardDisabled(props) {
    const { t} = useTranslation();

    return (
        <div className={'card-disabled-overlay'}>
            <i className="ti ti-exclamation-circle fs-7"></i>
            {t('This feature is not available for your Business.')}
        </div>
    );
}