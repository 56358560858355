import React, {useEffect} from 'react';

import {Outlet, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

function AccountSettings(props) {
    const { t} = useTranslation();
    const navigate = useNavigate();
    const [tabs, setTabs] = React.useState([
        {
            icon: 'user-circle',
            label: t('Account Info'),
            url: '/settings',
            active: false
        },
        {
            icon: 'plug-connected',
            label: t('Connected Accounts'),
            url: '/settings/connected-accounts',
            active: false
        },
        /*{
            icon: 'article',
            label: t('Billing Information'),
            url: '/settings/billing-info',
            active: false
        },
        {
            icon: 'bell',
            label: t('Notifications'),
            url: '/settings/notifications',
            active: false
        }*/
    ]);

    const setActiveTab = (e, url) => {
        if (e !== null) {
            e.preventDefault();
        }

        tabs.forEach((tab) => {
            tab.active = tab.url === url;
        });

        setTabs(tabs);
        navigate(url);
    }

    useEffect(() => {
        setActiveTab(null, window.location.pathname);
    }, []);


    return (
        <div className="card">
            <ul className="nav nav-pills user-profile-tab">
                {tabs.map((tab, index) => (
                <li className="nav-item" role="presentation" key={index}>
                    <button className={'nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-4'+(tab.active ? ' active': '')} onClick={(e) => {setActiveTab(e, tab.url)}}>
                        <i className={'ti me-2 fs-6 ti-'+tab.icon}></i>
                        <span className="d-none d-md-block">{tab.label}</span>
                    </button>
                </li>
                ))}
            </ul>
            <div className="card-body">
                <div className="tab-content">
                    <div className="tab-pane fade show active">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountSettings;
