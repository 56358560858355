import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useParams} from "react-router-dom";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useTranslation} from "react-i18next";

function BusinessFormTimePeriod(props) {
    const { t} = useTranslation();
    const [period, setPeriod] = React.useState({
        openDay: '',
        openTime: {
            hours: '',
            minutes: ''
        },
        closeDay: '',
        closeTime: {
            hours: '',
            minutes: ''
        }
    });
    const [openTimeString, setOpenTimeString] = React.useState('');
    const [closeTimeString, setCloseTimeString] = React.useState('24 hours');


    useEffect(() => {
        let newData = {...period};
        newData.openDay = props.data.openDay;
        newData.openTime = {
            hours: props.data.openTime.hours ? props.data.openTime.hours : 0,
            minutes: props.data.openTime.minutes ? props.data.openTime.minutes : 0
        };
        newData.closeDay = props.data.closeDay;
        newData.closeTime = {
            hours: props.data.closeTime.hours ? props.data.closeTime.hours : 0,
            minutes: props.data.closeTime.minutes ? props.data.closeTime.minutes : 0
        }
        setPeriod(newData);

        if (newData.openTime.hours === 0 && newData.openTime.minutes === 0 && newData.closeTime.hours === 24 && newData.closeTime.minutes === 0) {
            setOpenTimeString('24 hours');
            setCloseTimeString('24 hours');
        } else {
            setOpenTimeString(objectToString(props.data.openTime));
            setCloseTimeString(objectToString(props.data.closeTime));
        }
    }, [props.data]);


    const onChange = (type, value) => {
        let newPeriod = {...period};

        if (value === '24 hours') {
            newPeriod.openTime = {hours: 0, minutes: 0};
            newPeriod.closeTime = {hours: 24, minutes: 0};
            setOpenTimeString('24 hours');
            setCloseTimeString('24 hours');
        } else {
            newPeriod[type] = stringToObject(value);

            if (type === 'openTime') {
                setOpenTimeString(value);
                if (closeTimeString === '24 hours') {
                    const defaultCloseTime = {hours: 17, minutes: 0};
                    newPeriod.closeTime = defaultCloseTime;
                    setCloseTimeString(objectToString(defaultCloseTime));
                }
            }
            if (type === 'closeTime') {
                setCloseTimeString(value);

                if (openTimeString === '24 hours') {
                    const defaultOpenTime = {hours: 0, minutes: 0};
                    newPeriod.openTime = defaultOpenTime;
                    setOpenTimeString(objectToString(defaultOpenTime));
                }
            }
        }

        setPeriod(newPeriod);
        props.onChange(newPeriod);
    }


    const objectToString = (object) => {
        let hours = object.hours ? object.hours : 0;
        let minutes = object.minutes ? object.minutes : 0;

        return padTo2(hours)+':'+padTo2(minutes);
    }


    const stringToObject = (string) => {
        let stringParts = string.split(':');
        return {
            hours: stringParts[0] ? parseInt(stringParts[0]) : 0,
            minutes: stringParts[1] ? parseInt(stringParts[1]) : 0
        }
    }


    const padTo2 = (number) => {
        return (number < 10 ? '0' : '') + number;
    }


    return (
        <div className={'d-flex align-items-center justify-content-start gap-3 pb-2'}>
            <div className={'form-group w-40'}>
                <select className={'form-select'} value={openTimeString} onChange={(e) => {onChange('openTime', e.target.value)}}>
                    <option value={'24 hours'}>{t('24 hours')}</option>
                    {[...Array(24).keys()].map((hour, index) => {
                        return (
                            <React.Fragment key={index+'|'+hour}>
                            <option value={padTo2(hour)+':00'}>{padTo2(hour)}:00</option>
                            <option value={padTo2(hour)+':30'}>{padTo2(hour)}:30</option>
                            </React.Fragment>
                        );
                    })}
                </select>
            </div>
            {(closeTimeString !== '24 hours') && (
            <div className={'form-group w-40'}>
                <select className={'form-select'} value={closeTimeString} onChange={(e) => {onChange('closeTime', e.target.value)}}>
                    <option value={'24 hours'}>{t('24 hours')}</option>
                    {[...Array(24).keys()].map((hour, index) => {
                        return (
                            <React.Fragment key={index+'|'+hour}>
                            <option value={padTo2(hour)+':00'}>{padTo2(hour)}:00</option>
                            <option value={padTo2(hour)+':30'}>{padTo2(hour)}:30</option>
                            </React.Fragment>
                        );
                    })}
                </select>
            </div>
            )}
            <div className={'form-group'}>
                {props.index === 0 && (
                <button className="btn btn-circle btn-light-primary text-primary d-inline-flex justify-content-center align-items-center" onClick={() => {props.onAddClick(period.openDay)}}>
                    <i className={'ti ti-plus fs-4'}></i>
                </button>
                ) || props.index > 0 && (
                <button className="btn btn-circle btn-light-danger d-inline-flex justify-content-center align-items-center" onClick={() => {props.onDeleteClick(period.openDay, props.index)}}>
                    <i className={'ti ti-trash text-danger fs-4'}></i>
                </button>
                )}
            </div>
        </div>
    );
}

export default BusinessFormTimePeriod;
