import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useParams} from "react-router-dom";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useTranslation} from "react-i18next";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";
import ErrorsHelper from "../../../helpers/ErrorsHelper";
import BusinessFormServiceCheckbox from "./businessFormServiceInputs/BusinessFormServiceCheckbox";
import BusinessFormServiceTextInput from "./businessFormServiceInputs/BusinessFormServiceTextInput";
import {AttributeValueType} from "../../../helpers/AttributeValueType";
import BusinessFormServiceRepeatedEnum from "./businessFormServiceInputs/BusinessFormServiceRepeatedEnum";

export default function BusinessFormServices({onUpdate, attributes, categories}) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [groupedAttributes, setGroupedAttributes] = React.useState({});
    const [saveLoading, setSaveLoading] = React.useState(false);


    useEffect(() => {
        //setDescription(props.data);
        groupAttributes();
    }, [attributes, categories]);


    const groupAttributes = () => {
        let newGroupedAttributes = {};
        if (attributes && attributes.potential) {
            attributes.potential.forEach((attribute) => {
                if (attribute.deprecated || attribute.parent.match(/^attributes\/url_/)) {return;}

                let key = t('Others');
                if (attribute.groupDisplayName) {
                    key = attribute.groupDisplayName;
                }

                if (!newGroupedAttributes[key]) {
                    newGroupedAttributes[key] = [];
                }

                if (attributes.actual) {
                    attributes.actual.forEach((actualAttribute) => {
                        if (actualAttribute.name === attribute.parent) {
                            if (attribute['valueType'] === AttributeValueType.REPEATED_ENUM) {
                                attribute.repeatedEnumValue = {
                                    setValues: AttributeValueType.getValue(attribute, actualAttribute)
                                };
                            } else {
                                attribute.values = AttributeValueType.getValue(attribute, actualAttribute);
                            }
                        }
                    });
                }

                newGroupedAttributes[key].push(attribute);
            });
        }

        setGroupedAttributes(newGroupedAttributes);
    }


    const onSubmit = (e) => {
        e.preventDefault();

        let preparedAttributes = [];
        Object.keys(groupedAttributes).forEach((key) => {
            groupedAttributes[key].forEach((attribute) => {
                preparedAttributes.push(attribute);
            });
        });

        setSaveLoading(true);

        businessesModel.update(params.locationId, {attributes: preparedAttributes}, saveErrorHandler).then((response) => {
            if (response !== false) {
                onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
            }

            setSaveLoading(false);
            setEditMode(false);
        });
    }


    const saveErrorHandler = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
    }


    const onCancel = (e) => {
        e.preventDefault();

        //setDescription(props.data);
        setEditMode(false);
    }


    const updateServices = (value, key, attrIndex) => {
        let newGroupedAttributes = {...groupedAttributes};
        newGroupedAttributes[key][attrIndex].values = (typeof value === 'object' ? value : [value]);

        setGroupedAttributes(newGroupedAttributes);
    }


    const updateEnumServices = (repeatedEnumValue, key, attrIndex) => {
        let newGroupedAttributes = {...groupedAttributes};
        newGroupedAttributes[key][attrIndex].repeatedEnumValue = {
            setValues: (typeof repeatedEnumValue === 'object' ? repeatedEnumValue : [repeatedEnumValue])
        };

        setGroupedAttributes(newGroupedAttributes);
    }


    return (
        <>
            <div className={'py-2' + (editMode ? '' : ' editable')} onClick={(e) => {
                e.preventDefault();
                !editMode && setEditMode(true)
            }}>
                <BusinessFormHeadingProgress
                    score={null}
                    editMode={editMode}
                    title={t('Additional Information')}
                    id="services"
                />

                <div className="fs-2 mb-3 mt-1">{t('Add your services.')}</div>
                <div className="row">
                    <div className="col-xl-4">
                        <div className="">
                        {groupedAttributes && Object.keys(groupedAttributes).map((key, index) => {
                            if (!editMode) {
                                let hasValues = false;
                                groupedAttributes[key].forEach((attribute) => {
                                    if (attribute.valueMetadata && attribute.valueMetadata.length) {
                                        attribute.valueMetadata.forEach((meta) => {
                                            if (meta.value === (attribute.values ? attribute.values[0] : false)) {
                                                hasValues = true;
                                            }
                                        });
                                    }
                                });

                                if (!hasValues) {return;}
                            }

                            return (
                                <div key={index} className="mt-2 mb-3 d-flex flex-column gap-1">
                                    <h4 className="fs-3 fw-semibold text-uppercase mb-1">{key}</h4>
                                    {groupedAttributes[key].map((attribute, attrIndex) => {
                                        let uInd = index + '-' + attrIndex;

                                        if (attribute['valueType'] === AttributeValueType.BOOL) {
                                            return (<BusinessFormServiceCheckbox
                                                key={uInd}
                                                editMode={editMode}
                                                className={'form-group'}
                                                value={attribute.values ? attribute.values[0] : false}
                                                metaData={attribute.valueMetadata}
                                                label={attribute.displayName}
                                                onChange={(value) => {
                                                    updateServices(value, key, attrIndex);
                                                }}
                                            />);
                                        } else if (attribute['valueType'] === AttributeValueType.URL) {
                                            return (<BusinessFormServiceTextInput
                                                key={uInd}
                                                editMode={editMode}
                                                className={'form-group'}
                                                attribute={attribute}
                                                onChange={(values) => {
                                                    let newGroupedAttributes = {...groupedAttributes};
                                                    newGroupedAttributes[key][attrIndex].values = values;
                                                    setGroupedAttributes(newGroupedAttributes);
                                                }}
                                            />);
                                        } else if (attribute['valueType'] === AttributeValueType.REPEATED_ENUM) {
                                            return (<BusinessFormServiceRepeatedEnum
                                                key={uInd}
                                                editMode={editMode}
                                                className={'form-group'}
                                                attribute={attribute}
                                                onChange={(repeatedEnumValue) => {
                                                    updateEnumServices(repeatedEnumValue, key, attrIndex);
                                                }}
                                            />);
                                        }
                                    })}
                                </div>
                            )
                        })}
                        </div>

                        {editMode && (
                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel}/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
