import React, {useEffect} from 'react';

import Chart from 'react-apexcharts';
import BusinessesInsightsAllInteractions from "./insights/BusinessesInsightsAllInteractions";
import {useParams} from "react-router-dom";
import {useApi} from "../../providers/ApiProvider";
import BusinessesModel from "../../models/BusinessesModel";
import {useTranslation} from "react-i18next";
import CardLoading from "../ui/CardLoading";


function BusinessViewProfileQuality(props) {
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const [insights, setInsights] = React.useState({});
    const [profileQuality, setProfileQuality] = React.useState([0, 100]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        getInsights();
    }, []);


    useEffect(() => {
        let score = 0;
        if (props.locationInfo && props.locationInfo.score && props.locationInfo.score.totalScore) {
            score = Math.round(props.locationInfo.score.totalScore * 100);
        }
        setProfileQuality([score, 100 - score]);
    }, [props.locationInfo]);


    const getInsights = () => {
        setLoading(true);

        const defaultDateEnd = (new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)).toISOString().slice(0,10);
        const defaultDateStart = (new Date(Date.now() - (30 + 3) * 24 * 60 * 60 * 1000)).toISOString().slice(0,10);

        businessesModel.insights(params.locationId, defaultDateStart, defaultDateEnd).then((response) => {
            if (response !== false) {
                setInsights(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const [donutOptions, setDonutOptions] = React.useState({
        color: "#adb5bd",
        labels: [t('Current'), t('Total')],
        series: profileQuality,
        chart: {
            type: "donut",
            fontFamily: '"Plus Jakarta Sans", "Open Sans", Arial, sans-serif',
            foreColor: "#adb0bb",
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '89%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            offsetY: 7,
                        },
                        value: {
                            show: false,
                        },
                        total: {
                            show: true,
                            color: '#5A6A85',
                            fontSize: '20px',
                            fontWeight: "600",
                            label: profileQuality[0]+' / 100',
                        },
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: false,
        },
        legend: {
            show: false,
        },
        colors: ["var(--bs-primary)", "#EAEFF4", "var(--bs-secondary)"],
        tooltip: {
            theme: "dark",
            fillSeriesColor: false,
        },
    });


    useEffect(() => {
        let donutOptionsNew = {...donutOptions};
        donutOptionsNew.series = profileQuality;
        donutOptionsNew.plotOptions.pie.donut.labels.total.label = profileQuality[0]+' / 100';
        setDonutOptions(donutOptionsNew);
    }, [profileQuality]);


    return (
        <>
            <div className="row">
                <div className="col-md-4 d-flex align-items-stretch">
                    <div className={'card w-100 position-relative overflow-hidden'+(loading ? ' loading' : '')}>
                        <div className="card-body pb-0">
                            <div className="d-flex align-items-end justify-content-around">
                                <div>
                                    <p className="mb-0 text-center">{t('Profile Quality')}</p>
                                </div>
                            </div>
                        </div>
                        <Chart type={'donut'} options={donutOptions} series={profileQuality} width={'100%'} height={160} className={'py-4'}/>
                        <CardLoading loading={loading} />
                    </div>
                </div>
                <div className="col-md-4 d-flex align-items-stretch">
                    <BusinessesInsightsAllInteractions metricLabel={t('Impressions')} label={t('Views for 30 days')} insights={insights} loading={loading} />
                </div>
                <div className="col-md-4 d-flex align-items-stretch">
                    <BusinessesInsightsAllInteractions metricLabel={t('Total')} label={t('Interactions for 30 days')} insights={insights} loading={loading} />
                </div>
            </div>
        </>
    );
}

export default BusinessViewProfileQuality;
