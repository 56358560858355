import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";

function BusinessesPostAddEvent(props) {
    const { t} = useTranslation();


    useEffect(() => {
        if (
            !props.data.event.schedule.startTime || !props.data.event.schedule.startTime.hours && !props.data.event.schedule.startTime.minutes
        ) {
            props.onFieldChange('event.schedule.startTime', {
                hours: 0,
                minutes: 0,
                seconds: 0
            });
        }

        if (
            !props.data.event.schedule.endTime || !props.data.event.schedule.endTime.hours && !props.data.event.schedule.endTime.minutes
        ) {
            props.onFieldChange('event.schedule.endTime', {
                hours: 23,
                minutes: 59,
                seconds: 59
            });
        }
    }, []);


    const pad = (num, length) => {
        if (!length) {length = 2;}

        let str = '' + (num ? num : 0);
        while (str.length < length) {
            str = '0' + str;
        }

        return str;
    }


    const dateToString = (date) => {
        if (!date || !isValidDate(date.year, date.month, date.day)) {
            return '';
        } else {
            return date.year+'-'+pad(date.month)+'-'+pad(date.day);
        }
    }
    const stringToDate = (date) => {
        let parts = date.split('-');
        let results = {
            day: parseInt(parts[2]),
            month: parseInt(parts[1]),
            year: parseInt(parts[0])
        };

        if (!isValidDate(results.year, results.month, results.day)) {
            return null;
        } else {
            return results;
        }
    }


    const isValidDate = (year, month, day) => {
        const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        return date.getFullYear() === year &&
               date.getMonth() === month - 1 &&
               date.getDate() === day;
    }


    const timeToString = (time) => {
        if (!time || !time.hours && !time.minutes || !isValidTime(time.hours, time.minutes)) {
            return '00:00';
        } else {
            return pad(time.hours)+':'+pad(time.minutes);
        }
    }
    const stringToTime = (time) => {
        let parts = time.split(':');
        let results = {
            hours: parseInt(parts[0]),
            minutes: parseInt(parts[1]),
            seconds: 0,
            nanos: 0
        };

        if (!isValidTime(results.hours, results.minutes)) {
            return null;
        } else {
            return results;
        }
    }


    const isValidTime = (hours, minutes) => {
        return parseInt(hours) >= 0 && parseInt(hours) < 24 && parseInt(minutes) >= 0 && parseInt(minutes) < 60;
    }


    const onDateChange = (field, value) => {
        return props.onFieldChange(field, stringToDate(value));
    }


    const onTimeChange = (field, value) => {
        return props.onFieldChange(field, stringToTime(value));
    }


    return (
        <div className="row">
            <div className="col-md-8">
                <div className={'form-group py-2'}>
                    <label htmlFor="cf-event-title" className="form-label fw-semibold">{t('Event Title')}</label>
                    <input type="text" className={'form-control'} value={props.data.event.title} onChange={(e) => {
                        props.onFieldChange('event.title', e.target.value)
                    }}/>
                    <div className="d-flex justify-content-between">
                        {props.errors['event.title'] && <div className="form-text text-danger">{props.errors['event.title'].join('. ')}</div>}
                        <div className="text-end flex-grow-1"><small className="form-text text-muted">{props.data.event.title.length} / 58</small></div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-6">
                        <div className={'form-group py-2'}>
                            <label htmlFor="cf-start-date" className="form-label fw-semibold">{t('Start Date')}</label>
                            <input type="date" id={'cf-start-date'} className={'form-control'} value={dateToString(props.data.event.schedule.startDate)} onChange={(e) => {onDateChange('event.schedule.startDate', e.target.value)}} />
                            {props.errors['event.schedule.start_date'] && <div className="form-text text-danger">{props.errors['event.schedule.start_date'].join('. ')}</div>}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className={'form-group py-2'}>
                            <label htmlFor="cf-end-date" className="form-label fw-semibold">{t('End Date')}</label>
                            <input type="date" id={'cf-end-date'} className={'form-control'} value={dateToString(props.data.event.schedule.endDate)} onChange={(e) => {onDateChange('event.schedule.endDate', e.target.value)}} />
                            {props.errors['event.schedule.end_date'] && <div className="form-text text-danger">{props.errors['event.schedule.end_date'].join('. ')}</div>}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className={'form-group py-2'}>
                            <label htmlFor="cf-start-time" className="form-label fw-semibold">{t('Start Time (optional)')}</label>
                            <input type="time" id={'cf-start-time'} className={'form-control'} value={timeToString(props.data.event.schedule.startTime)} onChange={(e) => {onTimeChange('event.schedule.startTime', e.target.value)}} />
                            {props.errors['event.schedule.start_time'] && <div className="form-text text-danger">{props.errors['event.schedule.start_time'].join('. ')}</div>}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className={'form-group py-2'}>
                            <label htmlFor="cf-end-time" className="form-label fw-semibold">{t('End Time (optional)')}</label>
                            <input type="time" id={'cf-end-time'} className={'form-control'} value={timeToString(props.data.event.schedule.endTime)} onChange={(e) => {onTimeChange('event.schedule.endTime', e.target.value)}} />
                            {props.errors['event.schedule.end_time'] && <div className="form-text text-danger">{props.errors['event.schedule.end_time'].join('. ')}</div>}
                        </div>
                    </div>
                </div>

                <h6 className="mt-4">{t('Add more details (Optional)')}</h6>

                <div className={'form-group py-2'}>
                    <label htmlFor="cf-offer-details" className="form-label fw-semibold">{t('Event details')}</label>
                    <textarea id={'cd-offer-details'} className={'form-control'} rows={10} value={props.data.summary} onChange={(e) => {
                        props.onFieldChange('summary', e.target.value)
                    }}></textarea>
                    <div className="d-flex justify-content-between">
                        {props.errors.summary && <div className="form-text text-danger">{props.errors.summary.join('. ')}</div>}
                        <div className="text-end flex-grow-1"><small className="form-text text-muted">{props.data.summary ? props.data.summary.length : 0} / 1500</small></div>
                    </div>
                </div>

                <div className={'form-group py-2'}>
                    <label htmlFor="cf-button" className="form-label fw-semibold">{t('Add a button')}</label>
                    <div className="row">
                        <div className="col-md-6">
                            <select id={'cd-button'} className={'form-control form-select'} value={props.data.callToAction ? props.data.callToAction.actionType : ''} onChange={(e) => {
                                props.onFieldChange('callToAction.actionType', e.target.value)
                            }}>
                                <option value={'ACTION_TYPE_UNSPECIFIED'}>{t('None')}</option>
                                <option value={'BOOK'}>{t('Book')}</option>
                                <option value={'ORDER'}>{t('Order online')}</option>
                                <option value={'SHOP'}>{t('Buy')}</option>
                                <option value={'LEARN_MORE'}>{t('Learn more')}</option>
                                <option value={'SIGN_UP'}>{t('Sign up')}</option>
                                <option value={'CALL'}>{t('Call now')}</option>
                            </select>
                            {props.errors['call_to_action.action_type'] &&
                                <div className="form-text text-danger">{props.errors['call_to_action.action_type'].join('. ')}</div>}

                            <div className={'mt-3' + ((props.data.callToAction && props.data.callToAction.actionType && props.data.callToAction.actionType !== 'ACTION_TYPE_UNSPECIFIED') ? '' : ' d-none')}>
                                <input type="text" className={'form-control'} value={props.data.callToAction ? props.data.callToAction.url : ''} onChange={(e) => {
                                    props.onFieldChange('callToAction.url', e.target.value)
                                }} placeholder={t('Link for your button *')}/>

                                {props.errors['call_to_action.url'] &&
                                    <div className="form-text text-danger">{props.errors['call_to_action.url'].join('. ')}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BusinessesPostAddEvent;
