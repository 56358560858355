import React, {useEffect} from 'react';

import {useTranslation} from "react-i18next";

export default function BusinessFormServiceRepeatedEnum({attribute, onChange, editMode}) {
    const {t} = useTranslation();
    const uid = Math.random().toString(36).substring(7);


    const onValueChange = (meta) => {
        attribute.valueMetadata.forEach((m) => {
            if (m.value === meta.value) {
                let values = attribute.repeatedEnumValue && attribute.repeatedEnumValue.setValues ? [...attribute.repeatedEnumValue.setValues] : [];
                let index = values.indexOf(meta.value);
                if (index === -1) {
                    values.push(meta.value);
                } else {
                    values.splice(index, 1);
                }

                onChange(values);
            }
        });
    }


    const getValueLabels = () => {
        let valueLabels = false;

        if (attribute.repeatedEnumValue && attribute.repeatedEnumValue.setValues && attribute.repeatedEnumValue.setValues.length) {
            attribute.repeatedEnumValue.setValues.forEach((value) => {
                attribute.valueMetadata.forEach((meta) => {
                    if (meta.value === value) {
                        if (!valueLabels) {valueLabels = [];}
                        valueLabels.push(meta.displayName);
                    }
                });
            });
        }

        return valueLabels;
    }


    const isChecked = (value) => {
        let checked = false;

        if (attribute.repeatedEnumValue && attribute.repeatedEnumValue.setValues && attribute.repeatedEnumValue.setValues.length) {
            attribute.repeatedEnumValue.setValues.forEach((v) => {
                if (v === value) {
                    checked = true;
                }
            });
        }

        return checked;
    }


    return editMode ? (
        <div className="my-1 d-flex flex-column gap-1">
            <h5 className="fs-3 fw-semibold text-uppercase">{attribute.displayName}</h5>
            <div className="ms-3 ps-3 border-start border-0 border-opacity-10 border-black d-flex flex-column gap-1">
            {attribute.valueMetadata.map((meta, index) => {
                return (
                    <div key={index} className="form-check form-check-inline">
                        <input
                            type="checkbox"
                            id={'closed-' + uid + '-' + index}
                            name={'closed-' + uid + '-' + index}
                            className={'form-check-input danger'}
                            checked={isChecked(meta.value)}
                            onChange={() => {onValueChange(meta, index)}}
                        />
                        <label
                            className="form-check-label cursor-pointer"
                            htmlFor={'closed-' + uid + '-' + index}
                            onClick={() => {onValueChange(meta, index)}}>{meta.displayName}</label>
                    </div>
                );
            })}
            </div>
        </div>
    ) : (getValueLabels() ? (
        <>
        {getValueLabels().map((valueLabel, vIndex) => {
            <div className="d-flex align-items-center" key={vIndex}><i className="ti ti-checks fs-3 me-2 ms-1"></i>{valueLabel.displayName}</div>
        })}
        </>
    ) : (''));
}
