import React from "react";

export default function CardLoading(props) {
    return (<>
        {props.loading && (
            <div className="card-loading">
                <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path
                            d="m66.087 0a14.273 14.273 0 1 0 14.273 14.27 14.289 14.289 0 0 0 -14.273-14.27zm0 25.046a10.773 10.773 0 1 1 10.773-10.776 10.785 10.785 0 0 1 -10.773 10.773z"/>
                        <path
                            d="m66.087 8a6.273 6.273 0 1 0 6.273 6.27 6.279 6.279 0 0 0 -6.273-6.27zm0 9.046a2.773 2.773 0 1 1 2.773-2.776 2.775 2.775 0 0 1 -2.773 2.773z"/>
                        <path
                            d="m66.087 107.806a10.1 10.1 0 1 0 10.1 10.1 10.11 10.11 0 0 0 -10.1-10.1zm0 16.7a6.6 6.6 0 1 1 6.6-6.6 6.606 6.606 0 0 1 -6.6 6.594z"/>
                        <path
                            d="m102.727 36.412a6.946 6.946 0 0 0 4.927-2.038 6.976 6.976 0 1 0 -4.928 2.038zm-2.452-9.412a3.468 3.468 0 1 1 0 4.9 3.457 3.457 0 0 1 0-4.9z"/>
                        <path
                            d="m21.568 94.849a11.142 11.142 0 1 0 15.758 0 11.069 11.069 0 0 0 -15.758 0zm13.283 13.282a7.638 7.638 0 1 1 0-10.808 7.591 7.591 0 0 1 0 10.808z"/>
                        <path
                            d="m117.9 58.076a8.012 8.012 0 1 0 8.011 8.011 8.02 8.02 0 0 0 -8.011-8.011zm0 12.523a4.512 4.512 0 1 1 4.511-4.512 4.516 4.516 0 0 1 -4.511 4.513z"/>
                        <path
                            d="m26.456 66.087a12.186 12.186 0 1 0 -12.186 12.186 12.2 12.2 0 0 0 12.186-12.186zm-12.186 8.686a8.686 8.686 0 1 1 8.686-8.686 8.7 8.7 0 0 1 -8.686 8.686z"/>
                        <path
                            d="m102.727 93.672a9.056 9.056 0 1 0 6.4 2.653 8.992 8.992 0 0 0 -6.4-2.653zm3.929 12.984a5.556 5.556 0 1 1 0-7.857 5.563 5.563 0 0 1 0 7.857z"/>
                        <path
                            d="m20.092 20.092a13.229 13.229 0 1 0 18.709 0 13.244 13.244 0 0 0 -18.709 0zm16.235 16.235a9.725 9.725 0 1 1 0-13.76 9.742 9.742 0 0 1 0 13.76z"/>
                        <path
                            d="m29.447 24.217a5.23 5.23 0 1 0 3.7 1.532 5.215 5.215 0 0 0 -3.7-1.532zm1.223 6.453a1.731 1.731 0 1 1 0-2.446 1.726 1.726 0 0 1 0 2.446z"/>
                        <path d="m15.583 64.337h-2.625a1.75 1.75 0 1 0 0 3.5h2.625a1.75 1.75 0 0 0 0-3.5z"/>
                        <path d="m30.759 100.977h-2.625a1.75 1.75 0 1 0 0 3.5h2.625a1.75 1.75 0 0 0 0-3.5z"/>
                        <path d="m67.4 116.154h-2.626a1.75 1.75 0 0 0 0 3.5h2.626a1.75 1.75 0 0 0 0-3.5z"/>
                        <path d="m104.04 100.977h-2.625a1.75 1.75 0 1 0 0 3.5h2.625a1.75 1.75 0 0 0 0-3.5z"/>
                    </g>
                </svg>
            </div>
        )}
    </>)
}