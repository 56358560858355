import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";

import {Link, useParams} from "react-router-dom";
import BusinessViewHeader from "../BusinessViewHeader";
import BusinessViewMenu from "../BusinessViewMenu";
import ReviewsRulesModel from "../../../models/ReviewsRulesModel";
import ReviewsRulesTabs from "./ReviewsRulesTabs";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";
import RequestSampleResponseAlert from "./RequestSampleResponseAlert";



function ReviewsRules(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const breadcrumbs = useBreadcrumbs();
    const notificationsContext = useNotifications();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const reviewsRulesModel = new ReviewsRulesModel(apiContext.api);

    const [currentStars, setCurrentStars] = React.useState(5);
    const [saveLoading, setSaveLoading] = React.useState(false);
    const [aiCustomInstructionsVisible, setAiCustomInstructionsVisible] = React.useState(false);
    const [ruleTemplate, setRuleTemplate] = React.useState('');
    const [typeEditing, setTypeEditing] = React.useState('ai');
    const [rules, setRules] = React.useState([]);


    const onProfileRefresh = (profile, lastUpdated) => {
        return true;
    }


    const onSetActive = (e) => {
        e.preventDefault();

        const data = {
            is_active: true,
            response_type: typeEditing,
            response_template: ruleTemplate,
            stars: currentStars
        };

        reviewsRulesModel.toggleActive(params.locationId, data).then((response) => {
            if (response !== false) {
                setRules(response);
            }
        });
    }


    const toggleType = (type) => {
        setTypeEditing(type);
    }


    const onRuleToggle = (e) => {
        const data = {
            is_active: e.target.checked,
            response_type: typeEditing,
            response_template: ruleTemplate,
            stars: currentStars
        };

        reviewsRulesModel.toggleActive(params.locationId, data).then((response) => {
            if (response !== false) {
                setRules(response);
            }
        });
    }


    const setMetaData = (profile) => {
        if (profile !== false) {
            breadcrumbs.update({
                title: t('Responses Rules'),
                breadcrumbs: [
                    {label: t('Businesses List'), url: '/businesses'},
                    {label: profile.title, url: '/businesses/'+params.locationId},
                    {label: t('Responses Rules')}
                ]
            });

            document.title = t('Responses Rules')+' | LocalBoost';
        }
    }


    const onStarsRowClick = (stars) => {
        setCurrentStars(stars);
    }


    const setRule = () => {
        const data = {
            response_type: typeEditing,
            response_template: ruleTemplate,
            stars: currentStars
        };

        setSaveLoading(true);

        reviewsRulesModel.setRule(params.locationId, data).then((response) => {
            if (response !== false) {
                setRules(response);
            }

            notificationsContext.notify(t('Information saved successfully.'), 'success');
        }).finally(() => {
            setSaveLoading(false);
        });
    }


    const updateRuleTemplate = () => {
        let newTemplate = '';
        if (rules && rules[currentStars] && rules[currentStars].hasOwnProperty(typeEditing)) {
            newTemplate = rules[currentStars][typeEditing].response_template;
        }
        setRuleTemplate(newTemplate);

        if (ruleTemplate) {
            setAiCustomInstructionsVisible(true);
        }
    }


    const onResetClick = (e) => {
        e.preventDefault();

        const initialRuleTemplate = rules[currentStars][typeEditing].response_template;

        setRuleTemplate(initialRuleTemplate);
    }


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                setMetaData(response.data);
            }
        });
        reviewsRulesModel.list(params.locationId).then((response) => {
            if (response !== false) {
                setRules(response);
            }
        });
    }, [language]);


    useEffect(() => {
        updateRuleTemplate();
    }, [rules, typeEditing, currentStars]);


    useEffect(() => {
        if (rules && rules[currentStars] && rules[currentStars]['ai'] && rules[currentStars]['ai'].is_active && (typeEditing !== 'ai')) {
            setTypeEditing('ai');
        } else if (rules && rules[currentStars] && rules[currentStars]['template'] && rules[currentStars]['template'].is_active && (typeEditing !== 'template')) {
            setTypeEditing('template');
        }
    }, [rules]);


    return (
        <>
            <BusinessViewHeader
                locationId={params.locationId}
                onProfileRefresh={onProfileRefresh}
            />

            <BusinessViewMenu locationId={params.locationId} active="rules" />

            <div className="row g-4">
                    <div className="col-xl-6 col-xxl-5">
                        <ReviewsRulesTabs
                            data={rules}
                            active={currentStars}
                            onRowClick={onStarsRowClick}
                        />
                    </div>
                    <div className="col-xl-6 col-xxl-7">
                        <div className="card w-100 position-relative overflow-hidden">
                            <div className="px-4 d-flex justify-content-between align-items-center" style={{paddingTop: '17px', paddingBottom: '17px'}}>
                                <h5 className="card-title fw-semibold mb-0 lh-sm">{currentStars}{t('-star review response strategy')}</h5>

                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" checked={!!(rules && rules[currentStars] && (rules[currentStars]['ai'] && !!rules[currentStars]['ai'].is_active || rules[currentStars]['template'] && !!rules[currentStars]['template'].is_active))} onChange={onRuleToggle} />
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="row g-0">
                                    <div className="col-6">
                                        <a href="#" className={'p-2 text-center card rounded-0 m-0 shadow-none gap-2 '+(typeEditing === 'ai'?'bg-primary text-white':'bg-light-primary text-primary')} onClick={(e) => {e.preventDefault(); toggleType('ai')}}>
                                            <p className="fw-semibold mb-1">{t('AI-Generated')}</p>
                                        </a>
                                    </div>
                                    <div className="col-6">
                                        <a href="#" className={'p-2 text-center card rounded-0 m-0 shadow-none gap-2 '+(typeEditing === 'template'?'bg-primary text-white':'bg-light-primary text-primary')} onClick={(e) => {e.preventDefault(); toggleType('template')}}>
                                            <p className="fw-semibold mb-1">{t('Template')}</p>
                                        </a>
                                    </div>
                                </div>

                                {typeEditing === 'ai' && (
                                <div className="p-4">
                                    <div className="p-small-margins card-subtitle">
                                        <p>{t('Leverage AI-generated responses to effortlessly handle your Google My Business location reviews.')}</p>
                                        <p>{t('Our smart AI tailors replies to each review, ensuring a personalized touch while saving you time.')}</p>
                                    </div>

                                    <div className="mt-3 d-flex align-items-center justify-content-between gap-3">
                                        <hr className="flex-grow-1" />
                                        <button className="btn btn-light-primary btn-sm text-primary align-items-center d-flex" onClick={(e) => {setAiCustomInstructionsVisible(!aiCustomInstructionsVisible)}}>
                                            {aiCustomInstructionsVisible ? t('Hide custom instructions') : t('Show custom instructions')}
                                            <i className="ti ti-chevrons-down fs-4 ms-2"></i>
                                        </button>
                                        <hr className="flex-grow-1" />
                                    </div>

                                    <div className={'mt-2' + (aiCustomInstructionsVisible ? '' : ' d-none')}>
                                        <textarea rows={3} className="form-control mb-2" placeholder={t('Add your custom instructions...')} value={ruleTemplate} onChange={(e) => {setRuleTemplate(e.target.value)}}></textarea>

                                        <div className="w-100 d-flex justify-content-end gap-3">
                                            <button className="btn btn-light-danger btn-sm text-danger" onClick={onResetClick}>
                                                {t('Reset')}
                                            </button>

                                            <button className="btn btn-primary btn-sm text-white d-flex align-items-center justify-content-center" onClick={(e) => {setRule()}}>
                                                <span
                                                    className={'spinner-border spinner-border-sm me-2 '+((saveLoading)?(''):('d-none'))}
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                {t('Save changes')}
                                            </button>
                                        </div>
                                    </div>

                                    <RequestSampleResponseAlert
                                        starRating={currentStars}
                                    />
                                </div>
                                )}

                                {typeEditing === 'template' && (
                                <div className="p-4">
                                    <div className="p-small-margins card-subtitle">
                                        <p>{t('Use custom template responses to maintain a consistent tone in replying to your reviews.')}</p>
                                        <p>{t('Craft your templates once, and ensure brand-aligned communication with every response.')}</p>
                                    </div>

                                    <textarea rows={7} className="form-control mt-4 mb-2" value={ruleTemplate} onChange={(e) => {setRuleTemplate(e.target.value)}}></textarea>

                                    <div className="w-100 d-flex justify-content-end gap-3">
                                        <button className="btn btn-light-danger btn-sm text-danger" onClick={onResetClick}>
                                            {t('Reset')}
                                        </button>

                                        <button className="btn btn-primary btn-sm text-white d-flex align-items-center justify-content-center" onClick={(e) => {setRule()}}>
                                            <span
                                                className={'spinner-border spinner-border-sm me-2 '+((saveLoading)?(''):('d-none'))}
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            {t('Save changes')}
                                        </button>
                                    </div>
                                </div>
                                )}

                                <div className="ms-4 mb-4">
                                    {rules && rules[currentStars] && rules[currentStars].hasOwnProperty(typeEditing) && !!rules[currentStars][typeEditing].is_active ? (
                                        <span className="text-success d-inline-flex align-items-center">
                                            <i className="ti ti-checks fs-4 me-2"></i>
                                            {t('Active rule')}
                                        </span>
                                    ) : (
                                        <button className="btn btn-primary text-white d-flex align-items-center" onClick={onSetActive}>
                                            <i className="ti ti-check fs-4 me-2"></i>
                                            {t('Save & Set as active')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default ReviewsRules;
