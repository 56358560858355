import React from 'react';
import Alert from "../../ui/Alert";
import {useTranslation} from "react-i18next";
import Modal from "../../ui/Modal";
import BusinessFormModal from "./BusinessFormModal";

export default function BusinessFormHeadingProgress({score, editMode, title, id}) {
    const [progress, setProgress] = React.useState(0);
    const [progressClassName, setProgressClassName] = React.useState('info');
    const [showTipsModal, setShowTipsModal] = React.useState(false);


    React.useEffect(() => {
        if (!score) return;

        let progress = score && score.progress ? score.progress : 0;
        progress *= 100;
        progress = Math.round(progress);

        let newProgressClassName = 'info';
        if (progress < 50) {
            newProgressClassName = 'danger';
        } else if (progress < 75) {
            newProgressClassName = 'warning';
        } else if (progress < 95) {
            newProgressClassName = 'info';
        } else {
            newProgressClassName = 'success';
        }

        setProgress(progress);
        setProgressClassName(newProgressClassName);
    }, [score]);


    const showTips = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowTipsModal(true);
    }


    const hideTips = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowTipsModal(false)
    }


    return (
        <div className="d-flex justify-content-between">
            <label htmlFor={'cf-'+id} className="align-items-center d-flex form-label fw-semibold gap-2 justify-content-start mb-0">
                {title}
                {score && (
                <span className={'badge bg-light-'+progressClassName+' font-medium fs-2 fw-semibold text-'+progressClassName+(editMode?' d-none':'')}>
                    {progress}%
                </span>
                )}
                    {progress < 100 && score && score.recommendations && (
                    <span className={'bg-light-indigo ms-2 me-4 px-1 text-white info-icon'} onClick={showTips}><i className="ti ti-question-mark fs-2 text-dark"></i></span>
                    )}
                <i className={'fs-5 ti ti-pencil d-editable text-muted' + (editMode ? ' d-none' : '')}></i>
            </label>

            {score && (
            <>
            <div className={'align-items-center justify-content-end w-50 ' + (editMode ? 'd-flex' : 'd-none')}>
                <div className="progress w-100">
                    <div className={'progress-bar progress-bar-striped bg-' + progressClassName + ' progress-bar-animated'} role="progressbar"
                         aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{width: progress + '%'}}></div>
                    <div className="progress-bar progress-bar-animated bg-body-secondary" role="progressbar" aria-valuenow={100 - progress} aria-valuemin="0"
                         aria-valuemax="100" style={{width: (100 - progress) + '%'}}></div>
                </div>
                <span className={'fs-2 fw-semibold ms-3'}>{progress + '%'}</span>
                {progress < 100 && score.recommendations && (
                <span className="bg-dark ms-2 px-1 text-white info-icon" onClick={showTips}>
                    <i className="ti ti-question-mark fs-2"></i>
                </span>
                )}
            </div>

            <BusinessFormModal recommendations={score.recommendations} visible={showTipsModal} onClose={hideTips} />
            </>
            )}
        </div>
    );
}