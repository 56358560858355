import React, {useEffect} from 'react';

import SimpleBar from "simplebar-react";
import {useApi} from "../../../../providers/ApiProvider";
import BusinessesModel from "../../../../models/BusinessesModel";
import {Link, useParams} from "react-router-dom";
import {useBreadcrumbs} from "../../../../providers/BreadcrumbsProvider";
import BusinessViewHeader from "../../BusinessViewHeader";
import BusinessViewMenu from "../../BusinessViewMenu";
import {useNotifications} from "../../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../../providers/TranslationProvider";
import BusinessesReviewsPublishRepliesMenu from "./BusinessesReviewsPublishRepliesMenu";
import StarRating from "../../../ui/StarRating";
import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";
import RequestSampleResponseAlert from "../../rules/RequestSampleResponseAlert";

export default function BusinessesReviewsPublishRepliesStatsAlert({reviewsQueue}) {
    const {t} = useTranslation();

    const [processingNum, setProcessingNum] = React.useState(0);


    useEffect(() => {
        let processingNumNew = 0;
        for (const review of Object.values(reviewsQueue)) {
            if (review.status === 'pending' || review.status === 'processing') {
                processingNumNew++;
            }
        }

        setProcessingNum(processingNumNew);
    }, [reviewsQueue]);


    return processingNum ? (
        <div className="alert alert-warning mt-4 mb-4">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <strong>{t('Several reviews are now under processing.')}</strong>
            </div>

            {t('You can return to this page later to see the progress.')}
        </div>
    ) : '';
}
