import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";
import ErrorsHelper from "../../../helpers/ErrorsHelper";

function BusinessFormWebsite(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [data, setData] = React.useState('');
    const [saveLoading, setSaveLoading] = React.useState(false);


    useEffect(() => {
        setData(props.data);
    }, [props.data]);


    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        businessesModel.update(params.locationId, {websiteUri: data}, saveErrorHandler).then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
                setEditMode(false);
            } else {
                notificationsContext.notify(t('Information change failed.'), 'error');
            }

            setSaveLoading(false);
        });
    }


    const saveErrorHandler = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
    }


    const onCancel = (e) => {
        e.preventDefault();

        setData(props.data);
        setEditMode(false);
    }


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <BusinessFormHeadingProgress
                score={props.score}
                editMode={editMode}
                title={t('Website')}
                id="website"
            />

            {editMode === false && (
                <div className="pt-2">{(data ? data : t('No website defined.'))}</div>
            )}
            {editMode === true && (
                <>
                <div className="fs-2 mb-3 mt-1">{t('Add the link to your website.')}</div>

                <div className="row">
                    <div className="col-md-6 col-xl-6">
                        <input type="text" className="form-control" id="cf-website" placeholder={'https://...'} value={data} onChange={(e) => {setData(e.target.value)}} />

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel} />
                    </div>
                </div>
                </>
            )}
        </div>
        </>
    );
}

export default BusinessFormWebsite;
