import React, {useEffect} from 'react';
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useApi} from "../../providers/ApiProvider";
import {Link, useParams} from "react-router-dom";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import ReviewsRulesModel from "../../models/ReviewsRulesModel";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import Pagination from "../ui/Pagination";


function ReportsActivity(props) {
    const STATUS_NEW = 'new';
    const STATUS_UPDATED = 'updated';
    const STATUS_REMOVED = 'removed';

    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const reviewsRulesModel = new ReviewsRulesModel(apiContext.api);


    const [data, setData] = React.useState({});

    let urlParams = new URLSearchParams(window.location.search),
        defaultCurrentPage = 1;
    if (urlParams.get('page')) {
        defaultCurrentPage = urlParams.get('page');
    }
    const [currentPage, setCurrentPage] = React.useState(defaultCurrentPage);
    const [totalPages, setTotalPages] = React.useState(0);


    const setMetaData = () => {
        breadcrumbs.update({
            title: t('Activity for your Businesses'),
            breadcrumbs: [
                {label: t('Latest Activity')}
            ]
        });

        document.title = t('Latest Activity')+' | LocalBoost';
    }


    useEffect(() => {
        updateUrl();

        fetchActivityData();
    }, [currentPage]);


    const fetchActivityData = () => {
        reviewsRulesModel.getLatestActivity(currentPage).then((response) => {
            if (response !== false) {
                setData(response.records);
                setTotalPages(response.total_pages);
            }
        });
    }


    const updateUrl = () => {
        let url = '/reports';
        let query = {};
        if (currentPage && currentPage > 1) {
            query.page = currentPage;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const typeToAction = (log) => {
        let title = '';
        if (log && log.id) {title = log.id;}
        switch (log.status) {
            case STATUS_NEW: return (<span className="badge bg-success fs-2" title={title}>{t('New')}</span>);
            case STATUS_UPDATED: return (<span className="badge bg-warning fs-2" title={title}>{t('Updated')}</span>);
            case STATUS_REMOVED: return (<span className="badge bg-danger fs-2" title={title}>{t('Deleted')}</span>);
            default: return (<span className="badge bg-muted fs-2" title={title}>{t('Unknown')}</span>);
        }
    }


    const ratingToNum = (rating) => {
        switch (rating) {
            case 'ONE': return 1;
            case 'TWO': return 2;
            case 'THREE': return 3;
            case 'FOUR': return 4;
            case 'FIVE': return 5;
            default: return 0;
        }
    }


    const formatDetails = (log) => {
        switch (log.status) {
            case STATUS_NEW: return formatDetailsCreated(log);
            case STATUS_UPDATED: return formatDetailsUpdated(log);
            case STATUS_REMOVED: return formatDetailsDeleted(log);
            default: return (<span className="text-muted">{t('Unknown')}</span>);
        }
    }
    const formatDetailsCreated = (log) => {
        if (log.comment) {
            return t('Customer {{customer}} left a new {{rating}}-star review with a comment.', {customer: log.author, rating: ratingToNum(log.rating)});
        } else {
            return t('Customer {{customer}} left a new {{rating}}-star review.', {customer: log.author, rating: ratingToNum(log.rating)});
        }
    }
    const formatDetailsUpdated = (log) => {
        if (log.field === 'rating') {
            return t('Rating changed from {{from}} to {{to}}.', {from: log.data.old, to: log.data.new});
        } else if (log.field === 'comment') {
            return t('Comment updated.');
        } else if (log.field === 'reply') {
            if (log.data.action === 'added') {
                return t('Reply added to the {{rating}}-star review.', {rating: ratingToNum(log.rating)});
            } else if (log.data.action === 'removed') {
                return t('Reply added to the {{rating}}-star review.', {rating: ratingToNum(log.rating)});
            } else if (log.data.action === 'updated') {
                return t('Reply updated to the {{rating}}-star review.', {rating: ratingToNum(log.rating)});
            }
        } else if (log.field === 'reviewer') {
            return t('Customer {{from}} changed their name to {{to}}.', {from: log.data.old, to: log.data.new});
        } else if (log.field === 'created') {
            return t('Review date updated to {{date}}.', {date: (new Date(log.data.new)).toLocaleString()});
        }

        return t('Unknown');
    }
    const formatDetailsDeleted = (log) => {
        return t('Customer {{customer}} deleted their {{rating}}-star review.', {customer: log.author, rating: ratingToNum(log.rating)});
    }


    useEffect(() => {
        setMetaData();

        fetchActivityData();
    }, [language]);


    return (
        <SimpleCardContainer title={t('Activity for your Businesses')}>
            <p className="card-subtitle mb-4">{t('Track all activities among your Businesses here.')}</p>

            <table className="table table-hover">
                <thead>
                <tr>
                    <th>{t('Review Date')}</th>
                    <th>{t('Business')}</th>
                    <th>{t('Action')}</th>
                    <th>{t('Details')}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {(data && data.length) ? (
                    data.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{(new Date(item.timestamp)).toLocaleString()}</td>
                                <td>
                                    {item.business ? (
                                        <Link to={'/businesses/' + item.business.id + ''}>
                                            {item.business.name}
                                        </Link>
                                    ) : t('Unknown')}
                                </td>
                                <td>{typeToAction(item)}</td>
                                <td>{formatDetails(item)}</td>
                                <td>
                                    {(item.status !== 'removed' && item.business) && (
                                        <Link to={'/businesses/' + item.business.id + '/reviews/' + item.id + '/reply'}
                                              className="btn btn-light-primary btn-sm btn-circle text-primary d-flex justify-content-center">
                                            <i className="ti ti-message fs-4"></i>
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        );
                    })) : (
                    <tr>
                        <td colSpan={5}><span className="text-muted fs-2">{t('No log data at the moment.')}</span></td>
                    </tr>
                )}
                </tbody>
            </table>

            {totalPages > 1 && (
            <div className="my-3">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => {
                        setCurrentPage(page);
                    }}
                />
            </div>
            )}
        </SimpleCardContainer>
    );
}

export default ReportsActivity;
