import React from 'react';
import {useTranslation} from "react-i18next";



function BusinessesPostAddPublish(props) {
    const { t} = useTranslation();
    const [publishDate, setPublishDate] = React.useState(''); // local date
    const [publishTime, setPublishTime] = React.useState(''); // local time
    const [errors, setErrors] = React.useState([]);


    React.useEffect(() => {
        let dateUTC = new Date(props.data);
        let localTime = dateUTC.getHours().toString().padStart(2, '0') + ':' + dateUTC.getMinutes().toString().padStart(2, '0') + ':' + dateUTC.getSeconds().toString().padStart(2, '0');
        let localDate = dateUTC.getFullYear() + '-' + (dateUTC.getMonth()+1).toString().padStart(2, '0') + '-' + dateUTC.getDate().toString().padStart(2, '0');

        setPublishDate(localDate);
        setPublishTime(localTime);
    }, [props.data]);


    const onDateChange = (e) => {
        setPublishDate(e.target.value);

        afterDateTimeChange(e.target.value, publishTime);
    }


    const onTimeChange = (e) => {
        setPublishTime(e.target.value);

        afterDateTimeChange(publishDate, e.target.value);
    }


    const formatTime = (time) => {
        let formattedTime = '';

        if (time) {
            let publishTimeParts = time.split(':');
            if (publishTimeParts.length >= 2) {
                let newTimeParts = [];
                newTimeParts[0] = publishTimeParts[0].padStart(2, '0');
                newTimeParts[1] = publishTimeParts[1].padStart(2, '0');
                newTimeParts[2] = publishTimeParts[2] ? publishTimeParts[2].padStart(2, '0') : '00';

                formattedTime = newTimeParts.join(':');
            } else {
                formattedTime = '00:00:00';
            }
        } else {
            //set to current time
            formattedTime = '00:00:00';
        }

        formattedTime += '.000000Z';

        return formattedTime;
    }


    const afterDateTimeChange = (date, time) => {
        let localDateTime = date+'T'+formatTime(time);
        let localDateObj = new Date(localDateTime);
        localDateObj.setHours(localDateObj.getHours() + localDateObj.getTimezoneOffset() / 60);
        let isoDateTime = localDateObj.toISOString();

        props.onPublishDateChange(isoDateTime);
    }


    return (
        <div className={'form-group py-2'}>
            <label htmlFor="cf-button" className="form-label fw-semibold">{t('Publication date')}</label>
            <div className="row">
                <div className="col-6 col-md-4">
                    <input type="date" className={'form-control'} value={publishDate} onChange={onDateChange} />
                </div>
                <div className="col-6 col-md-4">
                    <input type="time" className={'form-control'} value={publishTime} onChange={onTimeChange} />
                </div>
            </div>
            {errors && <div className="form-text text-danger">{errors.join('. ')}</div>}
        </div>
    );
}

export default BusinessesPostAddPublish;
