export default class StrHelper {
    static filterGoogleTranslatedText(text) {
        if (!text) {return '';}

        if (text.indexOf('(Translated by Google)') !== -1 && text.indexOf('(Original)') !== -1) {
            const textParts = text.split('\n\n(Original)\n');

            return textParts[1];
        } else if (text.indexOf('(Translated by Google)') !== -1) {
            const textParts = text.split('\n\n(Translated by Google)\n');

            return textParts[0];
        }

        return text;
    }
}