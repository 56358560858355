import React from 'react';
import {useTranslation} from "react-i18next";


function ReviewsRulesTabs(props) {
    const { t} = useTranslation();

    return (
        <table className="table table-hover text-nowrap align-middle reviews-rules-stats">
            <thead className="fs-4">
                <tr>
                    <th></th>
                    <th>{t('AI-Generated')}</th>
                    <th>{t('Template')}</th>
                </tr>
            </thead>

            <tbody>
            {[...Array(5)].map((item, index) => (
                <tr key={index} className={(props.active === (5 - index)) ? 'table-primary' : ''} onClick={(e) => {props.onRowClick(5 - index)}}>
                    <td>
                        <div>
                            {
                                [...Array(5 - index)].map((item, starIndex) => (
                                    <i className="ti ti-star" key={'star'+starIndex}></i>
                                ))
                            }
                        </div>
                    </td>
                    <td>
                        {props.data && props.data[5 - index] && props.data[5 - index].hasOwnProperty('ai') && !!(props.data[5 - index].ai.is_active) && (
                        <i className="ti ti-check fs-4"></i>
                        )}
                    </td>
                    <td>
                        {props.data && props.data[5 - index] && props.data[5 - index].hasOwnProperty('template') && !!(props.data[5 - index].template.is_active) && (
                        <i className="ti ti-check fs-4"></i>
                        )}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default ReviewsRulesTabs;
