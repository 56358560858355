import React, {useEffect} from 'react';

import imgDefault from './../../assets/img/blank.png';
import StarRating from "../ui/StarRating";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CardLoading from "../ui/CardLoading";

export default function BusinessCard(props) {
    const { t} = useTranslation();

    useEffect(() => {
    }, []);

    const linkToBusiness = '/businesses/'+props.item.id;

    //convert GMT to local time
    const date = new Date(props.item.last_synced);

    //prepare cover image
    let imgCover = imgDefault;
    if (props.item.data.media && props.item.data.media['COVER'] && props.item.data.media['COVER'].thumbnailUrl) {
        imgCover = props.item.data.media['COVER'].thumbnailUrl;
        imgCover = imgCover.replace('=s300', '=s900');
    }


    const getAddressString = (storefrontAddress) => {
        let address = {
            'first': [],
            'second': []
        };
        if (!storefrontAddress) {
            return address;
        }
        if (storefrontAddress.postalCode) {
            address['first'].push(storefrontAddress.postalCode);
        }
        if (storefrontAddress.locality) {
            address['first'].push(storefrontAddress.locality);
        }
        if (storefrontAddress.addressLines) {
            address['second'].push(storefrontAddress.addressLines.join(', '));
        }
        address['first'] = address['first'].join(', ');
        address['second'] = address['second'].join(', ');
        return address;
    }


    return (
        <div className={'card card-sq-image overflow-hidden rounded-2'+(props.loading ? ' loading' : '')}>
            <div className="position-relative">
                <Link to={linkToBusiness} className="sq-image">
                    <img src={imgCover} className="card-img-top rounded-0" alt={props.item.data.title} />
                </Link>
                <Link to={linkToBusiness} className="bg-primary rounded-circle p-2 text-white d-inline-flex position-absolute bottom-0 end-0 mb-n3 me-3">
                    <i className="ti ti-pencil fs-4"></i>
                </Link>
            </div>
            <div className="card-body pt-3 p-4">
                <h6 className="fw-semibold fs-4 one-liner">
                    <Link to={linkToBusiness} className="text-dark">
                        {props.item.data.title}
                    </Link>
                </h6>
                <div className="d-flex align-items-center justify-content-between">
                    <h6 className="fs-2 mb-0 one-liner w-100">
                        {getAddressString(props.item.data.storefrontAddress)[0]}<br />
                        {getAddressString(props.item.data.storefrontAddress)[1]}
                    </h6>
                    <StarRating rating={props.item.data.avgRating} className="mb-0" />
                </div>

                <div className="mt-3 text-end fs-2">
                    <i className="ti ti-refresh fs-3 me-2"></i> {date.toLocaleString()}
                </div>
            </div>

            <CardLoading loading={props.loading} />
        </div>
    );
}
