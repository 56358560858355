import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import AccountTypesModel from "../../models/AccountTypesModel";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import {useAuth} from "../../providers/AuthProvider";
import {useParams} from "react-router-dom";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";

export default function AccountTypeCreate(props) {
    const params = useParams();
    const {language} = useMyTranslation();
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const accountTypesModel = new AccountTypesModel(apiContext.api);
    const {t} = useTranslation();

    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [formId, setFormId] = React.useState('');
    const [formName, setFormName] = React.useState('');
    const [formMaxProfiles, setFormMaxProfiles] = React.useState(0);
    const [formOrder, setFormOrder] = React.useState(0);


    useEffect(() => {
        breadcrumbs.update({
            title: t('Create a new account type'),
            breadcrumbs: [
                {label: t('Account types'), url: '/account-types'},
                {label: t('Create a new account type')}
            ]
        });

        document.title = t('Create a new account type') + ' | LocalBoost';
    }, [language]);


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        accountTypesModel.create({
            id: formId,
            name: formName,
            max_profiles: formMaxProfiles,
            order: formOrder,
        }, onCreateError).then((response) => {
            if (response !== false) {
                window.location.href = '/account-types';
            }

            setLoading(false);
        });
    }


    const onCreateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
    }


    return (
        <SimpleCardContainer title={t('Create a new account type')}>
            <form className={'py-2'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-md-6'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">{t('ID')}</label>

                                    <input id={'cd-id'} className={'form-control'} value={formId} onChange={(e) => {
                                        setFormId(e.target.value)
                                    }}/>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.id && <div className="form-text text-danger">{errors.id.join('. ')}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-6'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">{t('Name')}</label>

                                    <input id={'cd-id'} className={'form-control'} value={formName} onChange={(e) => {
                                        setFormName(e.target.value)
                                    }}/>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.name && <div className="form-text text-danger">{errors.name.join('. ')}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-4'}>
                                <div className={'form-group mt-3'}>
                                    <label className="form-label fw-semibold mb-2">{t('Max Profiles')}</label>

                                    <input id={'cd-id'} className={'form-control'} value={formMaxProfiles} onChange={(e) => {
                                        setFormMaxProfiles(e.target.value)
                                    }}/>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.max_profiles && <div className="form-text text-danger">{errors.max_profiles.join('. ')}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-4'}>
                                <div className={'form-group mt-3'}>
                                    <label className="form-label fw-semibold mb-2">{t('Sorting Order')}</label>

                                    <input id={'cd-id'} className={'form-control'} value={formOrder} onChange={(e) => {
                                        setFormOrder(e.target.value)
                                    }}/>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.order && <div className="form-text text-danger">{errors.order.join('. ')}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="bordered-tip pb-4">
                            <div className="alert alert-info" role="alert">
                                <strong>{t('Pro Tip!')}</strong>
                                <span className="d-block fs-2 mt-2">{t('Please, for ID use only letters, digits, "_" and "-". For example: ')}<em>10_profiles</em>.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'form-group mt-4'}>
                    <ButtonWithLoading
                        className={'btn-primary'}
                        label={t('Create account type')}
                        loading={loading}
                        onClick={onSubmit}
                    />
                </div>
            </form>
        </SimpleCardContainer>
    );
}
