import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesPostsModel from "../../../models/BusinessesPostsModel";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import TableEditableRow from "../../ui/TableEditableRow";
import Alert from "../../ui/Alert";
import BusinessFormTitle from "../formElements/BusinessFormTitle";
import BusinessFormCategories from "../formElements/BusinessFormCategories";
import BusinessFormDescription from "../formElements/BusinessFormDescription";
import BusinessFormWebsite from "../formElements/BusinessFormWebsite";
import BusinessFormPhoneNumbers from "../formElements/BusinessFormPhoneNumbers";
import BusinessesPostAddEvent from "./BusinessesPostAddEvent";
import BusinessesPostAddStandard from "./BusinessesPostAddStandard";
import BusinessesPostAddOffer from "./BusinessesPostAddOffer";
import BusinessViewMenu from "../BusinessViewMenu";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";

function BusinessesPostView(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const bpModel = new BusinessesPostsModel(apiContext.api);
    const businessesModel = new BusinessesModel(apiContext.api);


    const topicTypes = {
        'TOPIC_TYPE_UNSPECIFIED': t('Unspecified'),
        'STANDARD': t('Standard Post'),
        'EVENT': t('Event'),
        'OFFER': t('Offer')
    }

    const callToActionTypes = {
        'ACTION_TYPE_UNSPECIFIED': t('Unspecified'),
        'BOOK': t('Book'),
        'ORDER': t('Order online'),
        'SHOP': t('Shop'),
        'LEARN_MORE': t('Learn more'),
        'SIGN_UP': t('Sign up'),
        'CALL': t('Call now'),
    }


    const breadcrumbs = useBreadcrumbs();
    const [data, setData] = React.useState({});


    const onDelete = (e) => {
        e.preventDefault();

        bpModel.delete(params.locationId, params.postId).then((response) => {
            if (response !== false) {
                window.location.href = '/businesses/'+params.locationId+'/publications/';
            }
        });
    }


    const onProfileRefresh = (profile, lastUpdated) => {
        return true;
    }


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                breadcrumbs.update({
                    title: response.data.title,
                    breadcrumbs: [
                        {label: t('Businesses List'), url: '/businesses'},
                        {label: response.data.title, url: '/businesses/'+params.locationId},
                        {label: t('Publications'), url: '/businesses/'+params.locationId+'/publications'},
                        {label: t('Publication')}
                    ]
                });

                document.title = t('Publication')+' | '+response.data.title+' | LocalBoost';
            }
        });

        bpModel.view(params.locationId, params.postId).then((response) => {
            if (response !== false) {
                setData(response);
            }
        });
    }, [language]);


    const pad = (num, length) => {
        if (!length) {length = 2;}

        let str = '' + (num ? num : 0);
        while (str.length < length) {
            str = '0' + str;
        }

        return str;
    }


    const dateToString = (date) => {
        const format = date.year+'-'+pad(date.month)+'-'+pad(date.day);
        return (new Date(format)).toLocaleDateString(language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }


    const timeToString = (time) => {
        if (!time || !time.hours && !time.minutes) {
            return '00:00';
        } else {
            return pad(time.hours)+':'+pad(time.minutes);
        }
    }


    return (
        <>
            <BusinessViewHeader
                locationId={params.locationId}
                onProfileRefresh={onProfileRefresh}
            />

            <BusinessViewMenu locationId={params.locationId} active="publications"/>

            <div className="d-flex m-block-gap">
                <Link to={'/businesses/' + params.locationId + '/publications'} className="btn btn-light-info text-info">
                    <i className="ti ti-arrow-left me-2"></i>
                    {t('Back to Publication')}
                </Link>
            </div>

            <div className="row">
                <div className={'col-lg-8'}>
                    <SimpleCardContainer title={(data.data && data.data.topicType) ? topicTypes[data.data.topicType] : '...'}>

                        {data.data && ['EVENT', 'OFFER'].indexOf(data.data.topicType) !== -1 && (
                            <div className="mb-4">
                                <div className="text-muted fs-3">{t('Title')}</div>
                                <div className="fs-4">{data.data.event.title ? data.data.event.title : '—'}</div>
                            </div>
                        )}

                        {data.data && (data.data.topicType === 'EVENT') && (
                            <div className="mb-4">
                                <div className="row mb-2">
                                    <div className="col-6">
                                        <div className="text-muted fs-3">{t('Start Date')}</div>
                                        <div
                                            className="fs-4">{data.data.event.schedule.startDate ? dateToString(data.data.event.schedule.startDate) : '—'}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="text-muted fs-3">{t('End Date')}</div>
                                        <div className="fs-4">{data.data.event.schedule.endDate ? dateToString(data.data.event.schedule.endDate) : '—'}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="text-muted fs-3">{t('Start Time')}</div>
                                        <div
                                            className="fs-4">{data.data.event.schedule.startTime ? timeToString(data.data.event.schedule.startTime) : '—'}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="text-muted fs-3">{t('End Time')}</div>
                                        <div className="fs-4">{data.data.event.schedule.endTime ? timeToString(data.data.event.schedule.endTime) : '—'}</div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data.data && (data.data.topicType === 'OFFER') && (
                            <div className="mb-4">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="text-muted fs-3">{t('Start Date')}</div>
                                        <div
                                            className="fs-4">{data.data.event.schedule.startDate ? dateToString(data.data.event.schedule.startDate) : '—'}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="text-muted fs-3">{t('End Date')}</div>
                                        <div className="fs-4">{data.data.event.schedule.endDate ? dateToString(data.data.event.schedule.endDate) : '—'}</div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="mb-4">
                            <div className="text-muted fs-3">{t('Summary')}</div>
                            <div className="fs-4">
                                {data.data && data.data.summary ? data.data.summary.split('\n').map((line, index, arr) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        {index === arr.length - 1 ? null : <br/>}
                                    </React.Fragment>
                                )) : '—'}
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="text-muted fs-3 mb-2">{t('Media')}</div>
                            <div className="fs-4">
                                {data.data && data.data.media && data.data.media.length ? (
                                    <div className="row">
                                        {data.data.media.map((media, index) => (
                                            <div key={index} className="col-2 mb-3">
                                                <img src={media.googleUrl} className="img-fluid" alt=""/>
                                            </div>
                                        ))}
                                    </div>
                                ) : '—'}
                            </div>
                        </div>

                        {data.data && (['STANDARD', 'EVENT'].indexOf(data.data.topicType) !== -1) && (
                            <div className="mb-4">
                                <div className="text-muted fs-3 mb-2">{t('Link')}</div>
                                <div className="fs-4">
                                    {data.data && data.data.callToAction && !['ACTION_TYPE_UNSPECIFIED', 'CALL'].includes(data.data.callToAction.actionType) ? (
                                        <div className="mb-4">
                                            <Link to={data.data.callToAction.url} target={'_blank'}
                                                  className={'align-items-center justify-content-center btn btn-light-primary text-primary'}>
                                                {callToActionTypes[data.data.callToAction.actionType]}
                                            </Link>
                                        </div>
                                    ) : '—'}
                                </div>
                            </div>
                        )}

                        {data.data && (data.data.topicType === 'OFFER') && (
                            <>
                                <div className="mb-4">
                                    <div className="text-muted fs-3">{t('Voucher code')}</div>
                                    <div className="fs-4">
                                        {data.data && data.data.offer ? (
                                            <div className="mb-4">
                                                {data.data.offer.couponCode}
                                            </div>
                                        ) : '—'}
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="text-muted fs-3">{t('Link to redeem offer')}</div>
                                    <div className="fs-4">
                                        {data.data && data.data.offer ? (
                                            <div className="mb-4">
                                                {data.data.offer.redeemOnlineUrl}
                                            </div>
                                        ) : '—'}
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="text-muted fs-3">{t('Terms and conditions')}</div>
                                    <div className="fs-4">
                                        {data.data && data.data.offer ? (
                                            <div className="mb-4">
                                                {data.data.offer.termsConditions}
                                            </div>
                                        ) : '—'}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="mb-4">
                            <div className="text-muted fs-3 mb-2">{t('Publication Date')}</div>
                            <div className="fs-4">
                                {data.publish_time && (
                                    <>{(new Date(data.publish_time)).toLocaleString()}</>
                                )}
                            </div>
                        </div>
                    </SimpleCardContainer>
                </div>
                <div className={'col-lg-4'}>
                    <SimpleCardContainer title={t('Publication Details')}>
                        <div className={'align-items-start d-flex flex-column gap-3'}>
                            <Link to={'/businesses/' + params.locationId + '/publications/' + params.postId + '/edit'}
                                  className={'align-items-center justify-content-center btn btn-light-primary text-primary'}>
                                <i className={'fs-4 ti ti-pencil me-2'}></i>
                                {t('Edit publication')}
                            </Link>

                            <button onClick={onDelete} className={'align-items-center justify-content-center btn btn-light-danger text-danger'}>
                                <i className={'fs-4 ti ti-trash me-2'}></i>
                                {t('Delete')}
                            </button>

                            {data.data && data.data.searchUrl && (
                                <Link to={data.data.searchUrl} target={'_blank'} className={'d-block align-items-center justify-content-center mt-4'}>
                                    {t('Check out at My Business')}
                                    <i className={'fs-4 ti ti-external-link ms-2'}></i>
                                </Link>
                            )}
                        </div>
                    </SimpleCardContainer>
                </div>
            </div>
        </>
    );
}

export default BusinessesPostView;
