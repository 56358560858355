import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useApi} from "../../providers/ApiProvider";
import BusinessesModel from "../../models/BusinessesModel";

import {Link, useParams} from "react-router-dom";
import BusinessViewHeader from "./BusinessViewHeader";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import TableEditableRow from "../ui/TableEditableRow";
import Alert from "../ui/Alert";
import BCP47LanguageCodes from "./BCP47LanguageCodes";
import BusinessFormCategories from "./formElements/BusinessFormCategories";
import BusinessFormTitle from "./formElements/BusinessFormTitle";
import BusinessFormDescription from "./formElements/BusinessFormDescription";
import BusinessFormWebsite from "./formElements/BusinessFormWebsite";
import BusinessFormPhoneNumbers from "./formElements/BusinessFormPhoneNumbers";
import BusinessViewProfileQuality from "./BusinessViewProfileQuality";
import BusinessFormOpeningDate from "./formElements/BusinessFormOpeningDate";
import BusinessFormSocialProfiles from "./formElements/BusinessFormSocialProfiles";
import BusinessFormLocation from "./formElements/BusinessFormLocation";
import BusinessFormServiceArea from "./formElements/BusinessFormServiceArea";
import BusinessFormOpeningHours from "./formElements/BusinessFormOpeningHours";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useGoogleMapsApi} from "../../providers/googleMapsApiProvider";
import BusinessViewMenu from "./BusinessViewMenu";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import BusinessDisconnect from "./BusinessDisconnect";
import BusinessTerminate from "./BusinessTerminate";
import BusinessFormLanguage from "./formElements/BusinessFormLanguage";
import {useAuth} from "../../providers/AuthProvider";
import BusinessFormServices from "./formElements/BusinessFormServices";

const LocationInfo = {
    title: '',
    languageCode: '',
    categories: '',
    phoneNumbers: '',
    websiteUri: '',
    latlng: {
        latitude: '',
        longitude: ''
    },
    profile: {
        description: ''
    },
    openInfo: {
        openingDate: {
            day: '',
            month: '',
            year: ''
        },
    },
    storefrontAddress: {
        locality: '',
        postalCode: '',
        regionCode: '',
        addressLines: [],
        languageCode: '',
        administrativeArea: ''
    },
    metadata: {},
    serviceArea: {},
    regularHours: {periods: []},
    specialHours: {specialHourPeriods: []},
    moreHours: [],
    serviceItems: [],
    score: {},
    admins: [],
    attributes: []
}

function BusinessView(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const authUser = useAuth()['user'];
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const googleMapsApiContext = useGoogleMapsApi();

    const [alertNotification, setAlertNotification] = React.useState({type: null, text: null});
    const [locationInfo, setLocationInfo] = React.useState(LocationInfo);
    const [gAccountId, setGAccountId] = React.useState(null);
    const [businessLanguage, setBusinessLanguage] = React.useState('');
    const [loading, setLoading] = React.useState(true);


    const onProfileRefresh = (profile, lastUpdated) => {
        return true;
    }


    const applyLoadedLocationInfo = (response) => {
        let newLocationInfo = {...locationInfo};
        Object.keys(newLocationInfo).forEach((key) => {
            if (response.data[key]) {
                newLocationInfo[key] = response.data[key];
            }
        });
        setLocationInfo(newLocationInfo);
        setBusinessLanguage(response.language);

        if (response.gaccount_id) {
            setGAccountId(response.gaccount_id);
        }
    }


    useEffect(() => {
        setLoading(true);

        businessesModel.view(params.locationId, locationInfo.languageCode).then((response) => {
            if (response !== false) {
                breadcrumbs.update({
                    title: response.data.title,
                    breadcrumbs: [
                        {label: t('Businesses List'), url: '/businesses'},
                        {label: response.data.title}
                    ]
                });

                document.title = response.data.title+' | LocalBoost';

                applyLoadedLocationInfo(response);

                setLoading(false);
            }
        });
    }, [language]);


    const getLocale = (locationInfo) => {
        let lang = 'en';
        if (locationInfo.languageCode) {
            lang = locationInfo.languageCode;
        }
        let country = 'US';
        if (locationInfo.storefrontAddress && locationInfo.storefrontAddress.regionCode) {
            country = locationInfo.storefrontAddress.regionCode;
        }
        return lang+'-'+country;
    }


    const getUserRoleForLocation = (locationInfo) => {
        let role = 'NOT DEFINED';

        if (locationInfo.admins) {
            locationInfo.admins.forEach((admin) => {
                if (admin.account && admin.account.replace('accounts/', '') === gAccountId) {
                    role = admin.role;
                }
            });
        }

        switch (role) {
            case 'PRIMARY_OWNER': role = t('Primary Owner'); break;
            case 'OWNER': role = t('Owner'); break;
            case 'MANAGER': role = t('Manager'); break;
            default: role = t('Not defined'); break;
        }

        return role;
    }


    return (
        <>
            <BusinessViewHeader
                locationId={params.locationId}
                onProfileRefresh={onProfileRefresh}
            />

            <BusinessViewMenu locationId={params.locationId} active="profile" />

            <BusinessViewProfileQuality locationInfo={locationInfo} />

            <SimpleCardContainer
                title={t('Business Information')}
                titleRight={getUserRoleForLocation(locationInfo)}
                titleRightWrapper={'badge bg-primary text-white fs-2'}
                loading={loading}
            >

                <div className="card-subtitle mb-4">
                    {t('To change business information, edit and save from here.')}<br />
                    {locationInfo.metadata && (locationInfo.metadata.mapsUri ? (
                        <a href={locationInfo.metadata.mapsUri} target="_blank" className="align-items-center btn btn-light-primary d-inline-flex justify-content-center mt-2 text-primary">
                            <i className={'ti ti-map-pin fs-4 me-2'}></i>
                            {t('View on Google Maps')}
                        </a>) : (<span className="text-danger fw-semibold">{t('Your location is not published to Google Maps. Some features may not be available for it.')}</span>))}
                </div>

                {alertNotification.text && (
                    <Alert
                        type={alertNotification.type}
                        text={alertNotification.text}
                    />
                )}

                <form>

                    <h5 className="mt-4 mb-2">{t('About your business')}</h5>

                    <BusinessFormLanguage
                        data={businessLanguage}
                        onUpdate={applyLoadedLocationInfo}
                    />

                    <hr/>

                    <BusinessFormTitle
                        data={locationInfo.title}
                        onUpdate={applyLoadedLocationInfo}
                        score={locationInfo.score.scores ? {
                            progress: locationInfo.score.scores.title,
                            recommendations: locationInfo.score.recommendations.title
                        } : {}}
                    />

                    <hr/>

                    <BusinessFormCategories
                        data={locationInfo.categories}
                        onUpdate={applyLoadedLocationInfo}
                        score={locationInfo.score.scores ? {
                            progress: locationInfo.score.scores.categories,
                            recommendations: locationInfo.score.recommendations.categories
                        } : {}}
                    />

                    <hr/>

                    <BusinessFormDescription
                        data={locationInfo.profile.description}
                        onUpdate={applyLoadedLocationInfo}
                        score={locationInfo.score.scores ? {
                            progress: locationInfo.score.scores.description,
                            recommendations: locationInfo.score.recommendations.description
                        } : {}}
                    />

                    <hr/>

                    <BusinessFormOpeningDate
                        data={(locationInfo.openInfo ? locationInfo.openInfo.openingDate : null)}
                        locale={getLocale(locationInfo)}
                        onUpdate={applyLoadedLocationInfo}
                    />

                    <hr/>

                    <h5 className="mt-4 mb-2">{t('Contact information')}</h5>

                    <BusinessFormPhoneNumbers
                        data={locationInfo.phoneNumbers}
                        onUpdate={applyLoadedLocationInfo}
                        score={locationInfo.score.scores ? {
                            progress: locationInfo.score.scores.phones,
                            recommendations: locationInfo.score.recommendations.phones
                        } : {}}
                    />

                    <hr/>

                    <BusinessFormWebsite
                        data={locationInfo.websiteUri}
                        onUpdate={applyLoadedLocationInfo}
                        score={locationInfo.score.scores ? {
                            progress: locationInfo.score.scores.website,
                            recommendations: locationInfo.score.recommendations.website
                        } : {}}
                    />

                    <hr/>

                    <BusinessFormSocialProfiles
                        attributes={locationInfo.attributes}
                        categories={locationInfo.categories}
                        onUpdate={applyLoadedLocationInfo}
                    />

                    <hr/>

                    {googleMapsApiContext.gmAPILoaded && (<>
                        <h5 className="mt-4 mb-2">{t('Location and areas')}</h5>

                        <BusinessFormLocation
                            latlng={locationInfo.latlng}
                            storefrontAddress={locationInfo.storefrontAddress}
                            placeId={locationInfo.metadata.placeId}
                            locationInfo={locationInfo}
                            onUpdate={applyLoadedLocationInfo}
                            score={locationInfo.score.scores ? {
                                progress: locationInfo.score.scores.location,
                                recommendations: locationInfo.score.recommendations.location
                            } : {}}
                        />

                        <hr/>

                        <BusinessFormServiceArea
                            data={locationInfo.serviceArea}
                            regionCode={locationInfo.storefrontAddress.regionCode}
                            onUpdate={applyLoadedLocationInfo}
                        />

                        <hr/>
                    </>)}

                    <h5 className="mt-4 mb-2">{t('Opening hours')}</h5>

                    <BusinessFormOpeningHours
                        data={locationInfo.regularHours}
                        onUpdate={applyLoadedLocationInfo}
                        score={locationInfo.score.scores ? {
                            progress: locationInfo.score.scores.hours,
                            recommendations: locationInfo.score.recommendations.hours
                        } : {}}
                    />

                    <hr/>

                    <BusinessFormServices
                        attributes={locationInfo.attributes}
                        categories={locationInfo.categories}
                        onUpdate={applyLoadedLocationInfo}
                    />
                </form>
            </SimpleCardContainer>

            <div className="row">
                <div className="col-md-6">
                    <SimpleCardContainer title={t('Terminate Business')}>

                        <div className="card-subtitle mb-4">
                            {t('Terminating your business will hide it from Google Maps.')}
                        </div>

                        <BusinessTerminate openInfo={locationInfo ? locationInfo.openInfo : null}/>

                        {locationInfo && ( // TODO: Implement this
                        <div className="d-flex flex-column gap-2 mt-4">
                            {locationInfo.metadata.hasGoogleUpdated ? (
                            <div className="text-warning"><i className="ti ti-eye-check fs-3 me-2"></i> {t('Some edits are pending your review.')}</div>
                            ) : (
                            <div><i className="ti ti-checks fs-3 me-2"></i> {t('All edits are successfully published.')}</div>
                            )}
                            {locationInfo.metadata.hasPendingEdits ? (
                            <div className="text-warning"><i className="ti ti-checks fs-3 me-2"></i> {t('Some properties are in the edit pending state.')}</div>
                            ) : ('')}
                            {locationInfo.metadata.canDelete ? (
                            <div><i className="ti ti-checks fs-3 me-2"></i> {t('This location can be deleted.')}</div>
                            ) : ('')}
                            {locationInfo.metadata.canOperateLocalPost ? (
                            <div className="text-success"><i className="ti ti-checks fs-3 me-2"></i> {t('This location can create local posts.')}</div>
                            ) : (
                            <div className="text-danger"><i className="ti ti-circle-x fs-3 me-2"></i> {t('This location is not allowed to create local posts.')}</div>
                            )}
                            {locationInfo.metadata.canModifyServiceList ? (
                                <div><i className="ti ti-checks fs-3 me-2"></i> {t('This location can modify the service list.')}</div>
                            ) : (
                                <div className="text-danger"><i className="ti ti-circle-x fs-3 me-2"></i> {t('This location is not allowed to modify the service list.')}</div>
                            )}
                            {locationInfo.metadata.canHaveFoodMenus ? (
                                <div><i className="ti ti-checks fs-3 me-2"></i> {t('This location is eligible for food menu.')}</div>
                            ) : ('')}
                            {locationInfo.metadata.canOperateHealthData ? (
                            <div><i className="ti ti-checks fs-3 me-2"></i> {t('This location can operate on Health data.')}</div>
                            ) : ('')}
                            {locationInfo.metadata.canOperateLodgingData ? (
                            <div><i className="ti ti-checks fs-3 me-2"></i> {t('This location can operate on Lodging data.')}</div>
                            ) : ('')}
                            {locationInfo.metadata.canHaveBusinessCalls ? (
                            <div><i className="ti ti-checks fs-3 me-2"></i> {t('This location is eligible for business calls.')}</div>
                            ) : (
                            <div className="text-danger"><i className="ti ti-circle-x fs-3 me-2"></i> {t('This location is not yet eligible for business calls.')}</div>
                            )}
                            {locationInfo.metadata.hasVoiceOfMerchant !== undefined && (locationInfo.metadata.hasVoiceOfMerchant ? (
                                <div><i className="ti ti-checks fs-3 me-2"></i> {t('This location has Voice of Merchant.')}</div>
                            ) : (
                                <div className="text-danger"><i className="ti ti-circle-x fs-3 me-2"></i> {t('This location does not have Voice of Merchant.')}</div>
                            ))}
                        </div>
                        )}
                    </SimpleCardContainer>
                </div>
                <div className="col-md-6">
                    <SimpleCardContainer title={t('Disconnect this location')}>

                        <div className="card-subtitle mb-4">
                            {t('You can disconnect your location, the business will keep been published on Google Maps.')}
                        </div>

                        <BusinessDisconnect/>
                    </SimpleCardContainer>
                </div>
            </div>
        </>
    );
}

export default BusinessView;
