import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import BusinessViewMenu from "../BusinessViewMenu";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import BusinessesMediaModel from "../../../models/BusinessesMediaModel";
import BusinessesMediaNav from "./BusinessesMediaNav";
import BusinessesMediaCard from "./BusinessesMediaCard";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";


function BusinessesMedia(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const mediaModel = new BusinessesMediaModel(apiContext.api);


    const breadcrumbs = useBreadcrumbs();
    const [data, setData] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [syncLoading, setSyncLoading] = React.useState(false);
    const [deleteProcessing, setDeleteProcessing] = React.useState(false);
    const [mediaCategory, setMediaCategory] = React.useState('all');


    const keysToLabels = {
        'all': t('All business media'),
        'COVER': t('Cover photos'),
        'PROFILE': t('Profile photos'),
        'LOGO': t('Logos'),
        'EXTERIOR': t('Exterior media'),
        'INTERIOR': t('Interior media'),
        'PRODUCT': t('Products media'),
        'AT_WORK': t('At-work'),
        'FOOD_AND_DRINK': t('Food and drink'),
        'MENU': t('Menu'),
        'COMMON_AREA': t('Common area'),
        'ROOMS': t('Rooms'),
        'TEAMS': t('Teams'),
        'ADDITIONAL': t('Additional'),
        'CATEGORY_UNSPECIFIED': t('Unspecified'),
        'Uncategorized': t('Uncategorized'),
        'customers': t('Customers Activity'),
    };


    const onDelete = (pk) => {
        setDeleteProcessing(pk);

        mediaModel.delete(params.locationId, pk).then((response) => {
            if (response !== false) {
                fetchMedia().finally(() => {
                    setDeleteProcessing(false);
                });
            }
        });
    }


    const onSyncClick = () => {
        setSyncLoading(true);

        fetchMedia().finally(() => {
            setSyncLoading(false);
        });
    }


    const onProfileRefresh = (profile, lastUpdated) => {
        return true;
    }


    const fetchMedia = () => {
        return mediaModel.listOwn(params.locationId).then((response) => {
            if (response) {
                let filteredData = [];

                if (mediaCategory === 'all' || mediaCategory === '') {
                    Object.keys(response).map((key) => {
                        filteredData = filteredData.concat(response[key]);
                    });
                } else {
                    if (response[mediaCategory]) {
                        filteredData = response[mediaCategory];
                    } else if (response[mediaCategory.toUpperCase()]) {
                        filteredData = response[mediaCategory.toUpperCase()];
                    } else {
                        filteredData = [];
                    }
                }

                setData(filteredData);
                setCategories(Object.keys(response));
            }
        });
    }


    const init = () => {
        let curMediaCategory = 'all';
        if (params.category) {
            curMediaCategory = params.category;
        }


        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                breadcrumbs.update({
                    title: response.data.title,
                    breadcrumbs: [
                        {label: t('Businesses List'), url: '/businesses'},
                        {label: response.data.title, url: '/businesses/'+params.locationId},
                        {label: (keysToLabels[curMediaCategory.toUpperCase()] || keysToLabels[curMediaCategory])}
                    ]
                });

                document.title = (keysToLabels[curMediaCategory.toUpperCase()] || keysToLabels[curMediaCategory])+' | '+response.data.title+' | LocalBoost';
            }
        });


        setMediaCategory(curMediaCategory);
    }


    /*useEffect(() => {
        console.log('321');
        fetchMedia();
    }, []);*/


    useEffect(() => {
        init();
    }, [params.category, language]);


    useEffect(() => {
        //console.log(JSON.stringify(mediaCategory));
        fetchMedia();
        //console.log(JSON.stringify(mediaCategory));
    }, [mediaCategory]);


    return (
        <>
            <BusinessViewHeader
                locationId={params.locationId}
                onProfileRefresh={onProfileRefresh}
            />

            <BusinessViewMenu locationId={params.locationId} active="media" />

            <BusinessesMediaNav
                data={categories}
                active={mediaCategory}
                keysToLabels={keysToLabels}
            />

            <SimpleCardContainer title={(<>
                {keysToLabels[mediaCategory] || keysToLabels[mediaCategory.toUpperCase()]}
                <ButtonWithLoading
                    className="btn-light-primary btn-sm btn-circle justify-content-center text-primary"
                    noOriginalIconClassName={true}
                    iconClassName="ti ti-refresh fs-4"
                    noOriginalSpinnerClassName={true}
                    spinnerClassName=""
                    label=""
                    onClick={onSyncClick}
                    loading={syncLoading}
                />
            </>)} titleRight={(
                <Link to={'/businesses/'+params.locationId+'/media/add'} className="btn btn-success btn-circle align-items-center justify-content-center"><i className="ti ti-plus fs-4 lh-lg"></i></Link>
            )}>
                <p>{t('You can manage your own media and list media from customers.')}</p>

                <div className="row g-3">
                    {Array.from({length: 4}).map((colItem, colIndex) => {
                        return (
                            <div className="col-md-3" key={'col-'+colIndex}>
                                {data && data.map((item, index) => {
                                    if (index % 4 === colIndex) {
                                        return (
                                            <BusinessesMediaCard
                                                data={item}
                                                key={'card-'+colIndex+'-'+index}
                                                keysToLabels={keysToLabels}
                                                onDelete={onDelete}
                                                deleteProcessing={deleteProcessing === item.name}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        )
                    })}
                </div>
            </SimpleCardContainer>
        </>
    );
}

export default BusinessesMedia;
