import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useApi} from "../../providers/ApiProvider";
import BusinessesModel from "../../models/BusinessesModel";

import imgDefault from './../../assets/img/blank.png';
import {Link, useParams} from "react-router-dom";
import BusinessViewMenu from "./BusinessViewMenu";
import StarRating from "../ui/StarRating";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import BCP47LanguageCodes from "./BCP47LanguageCodes";
import {useTranslation} from "react-i18next";

function BusinessViewHeader(props) {
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);


    const [lastSynced, setLastSynced] = React.useState(null);
    const [processingRefresh, setProcessingRefresh] = React.useState(false);
    const [profile, setProfile] = React.useState({});
    const [imgCover, setImgCover] = React.useState(imgDefault);
    const [imgProfile, setImgProfile] = React.useState(imgDefault);
    const [locationLinkOnMaps, setLocationLinkOnMaps] = React.useState('');


    const onRefreshClick = () => {
        setProcessingRefresh(true);
        businessesModel.refresh(params.locationId).then((response) => {
            if (response !== false) {
                const data = processRawData(response.data);

                applyData(data, response.last_synced);
                setProcessingRefresh(false);
            }
        });
    }


    const processRawData = (data) => {
        let address = [];
        if (data.storefrontAddress) {
            ['postalCode', 'locality'].forEach((key) => {
                if (data.storefrontAddress[key]) {
                    address.push(data.storefrontAddress[key]);
                }
            });
            if (data.storefrontAddress.addressLines) {address.push(data.storefrontAddress.addressLines.join(', '));}
        }
        data.formattedAddress = address.join(', ');

        return data;
    }


    const applyData = (data, dateLastSynced) => {
        setProfile(data);
        setLastSynced(new Date(dateLastSynced));
        if (data && data.metadata) {
            setLocationLinkOnMaps(data.metadata.mapsUri);
        }

        if (data.media && data.media['COVER'] && data.media['COVER'].googleUrl) {
            setImgCover(data.media['COVER'].googleUrl);
        }

        if (data.media && data.media['PROFILE'] && data.media['PROFILE'].googleUrl) {
            setImgProfile(data.media['PROFILE'].googleUrl);
        }
    }


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                const data = processRawData(response.data);

                applyData(data, response.last_synced);
            }
        });
    }, []);


    return (<>
        <div className="d-flex flex-column flex-md-row align-items-center gap-4 mb-4">
            <div className="position-relative" data-html2canvas-ignore="true">
                <div className="">
                    <img src={imgProfile} className="rounded-circle object-fit-cover" alt={profile.title} width="100" height="100" />
                </div>

                <button
                    className="align-items-center bottom-0 btn btn-circle btn-primary btn-sm d-inline-flex end-0 justify-content-center position-absolute text-white"
                    disabled={processingRefresh}
                    onClick={onRefreshClick}
                >
                    <span
                        className={'spinner-border spinner-border-sm '+((processingRefresh)?(''):('d-none'))}
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <i className={'fs-4 ti ti-refresh '+((!processingRefresh)?(''):('d-none'))}></i>
                </button>
            </div>

            <div className="text-center text-md-start">
                <h3 className="fw-semibold m-0 mb-1">{profile.title}</h3>
                <div className="mb-2">
                    {profile.storefrontAddress && (<>
                    {profile.storefrontAddress.postalCode+', '+profile.storefrontAddress.locality},
                    {profile.storefrontAddress.addressLines.join(', ')}
                    </>)}
                </div>
                <StarRating rating={profile.avgRating} className="d-flex justify-content-center justify-content-md-start mb-0" />
            </div>
        </div>

{/*<div className="business-info-sticky m-block-gap">
        <div className="card card-sq-image overflow-hidden rounded-2">
            <div className="position-relative">
                <Link to={locationLinkOnMaps} target={'_blank'} className="sq-image">
                    <img src={imgCover} className="card-img-top rounded-0" alt={profile.title} />
                </Link>

                <Link
                    to="#"
                    className="bg-primary rounded-circle p-2 text-white d-inline-flex position-absolute bottom-0 end-0 mb-n3 me-3"
                    disabled={processingRefresh}
                    onClick={onRefreshClick}
                >
                    <span
                        className={'spinner-border spinner-border-sm '+((processingRefresh)?(''):('d-none'))}
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <i className={'fs-4 ti ti-refresh '+((!processingRefresh)?(''):('d-none'))}></i>
                </Link>
            </div>
            <div className="card-body pt-3 p-4">
                <h6 className="fw-semibold fs-4 one-liner">
                    <Link to={locationLinkOnMaps} target={'_blank'} className="text-dark">
                        {profile.title}
                    </Link>
                </h6>
                <div className="d-flex align-items-center justify-content-between">
                    {profile.storefrontAddress && (<>
                    <h6 className="fs-2 mb-0 one-liner w-100">
                        {profile.storefrontAddress.postalCode+', '+profile.storefrontAddress.locality}<br />
                        {profile.storefrontAddress.addressLines.join(', ')}
                    </h6>
                    <StarRating rating={profile.avgRating} className="mb-0" />
                    </>)}
                </div>

                <div className="mt-3 text-end fs-2">
                    Last Synced: {lastSynced && lastSynced.toLocaleString()}
                </div>
            </div>
        </div>

        <div className="m-block-gap">
            {profile && profile.metadata && profile.metadata.mapsUri && (
            <a href={profile.metadata.mapsUri} target="_blank" className={'d-flex align-items-center justify-content-center btn btn-primary'}>
                <i className={'ti ti-map-pin fs-4 me-2'}></i>
                See on Google Maps
            </a>
            )}
        </div>

        <div className="alert alert-info fs-2">
            <strong className="d-block fs-3 mb-1">{BCP47LanguageCodes.languages[profile.languageCode]}</strong>
            This location has defined it's language upon creation, and this can't be changed.
        </div>

        <div className="card overflow-hidden d-none">
            <div className="card-body p-0">
                <img src={imgCover} alt="" className="img-fluid business-top-profile" />
                {profile && profile.title && (<>
                <div className="row align-items-center">
                    <div className="col-lg-4 order-lg-1 order-2">
                        <div className="d-flex align-items-center justify-content-around m-5">
                            <div className="text-center">
                                <h4 className="mb-0 fw-semibold lh-1">{profile.totalReviews}</h4>
                                <p className="mb-0 fs-4">Reviews</p>
                            </div>
                            <div className="text-center">
                                <h4 className="mb-0 fw-semibold lh-1">{profile.media.totalMedia}</h4>
                                <p className="mb-0 fs-4">Photos</p>
                            </div>
                            <div className="text-center">
                                <h4 className="mb-0 fw-semibold lh-1">{profile.totalPublications}</h4>
                                <p className="mb-0 fs-4">Publications</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-n3 order-lg-2 order-1">
                        <div className="mt-n5">
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <div className="d-flex align-items-center justify-content-center rounded-circle" style={{width: '110px', height: '110px'}}>
                                    <div className="border border-4 border-white bg-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden" style={{width: '100px', height: '100px'}}>
                                        <img src={imgProfile} alt="" className="w-100 h-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <h5 className="fs-5 mb-1 fw-semibold">{profile.title}</h5>
                                <p className="mb-0 fs-3">{profile.formattedAddress}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 order-last">
                        <div className="d-flex align-items-center justify-content-around m-5">
                            <div>
                                <button
                                    className="btn btn-primary align-items-center justify-content-center"
                                    disabled={processingRefresh}
                                    onClick={onRefreshClick}
                                >
                                    <span
                                        className={'spinner-border spinner-border-sm me-2 '+((processingRefresh)?(''):('d-none'))}
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <i className={'fs-4 ti ti-refresh me-2 '+((!processingRefresh)?(''):('d-none'))}></i>
                                    Refresh now
                                </button>
                                <div className="fs-2 fw-semibold mt-1">Last Synced:</div>
                            </div>
                        </div>
                    </div>
                </div>
                </>)}
            </div>
        </div>
        </div>*/}</>
    );
}

export default BusinessViewHeader;
