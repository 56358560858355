import React from 'react';

export default function BusinessesReviewsPublishRepliesMenu({onClick, selectedRatingNum, stats}) {
    const indexToStatsKey = ['NONE', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE'];

    return (
        <ul className="list-group">
            <li className="fw-semibold text-dark text-uppercase mx-9 my-2 px-3 fs-2">Filter by review</li>
            {Array(5).fill(0).map((item, index) => {
                let starClassName = 'ti-star';
                if (selectedRatingNum === (5 - index)) {
                    starClassName = 'ti-star-filled';
                }

                return (
                    <li className="list-group-item border-0 p-0 mx-8" key={'item-' + index}>
                        <a className={'d-flex align-items-center gap-2 list-group-item-action text-dark py-8 mb-1 rounded-1 px-1 px-lg-4' + (selectedRatingNum === (5 - index) ? ' bg-light-warning' : '')}
                           href="#" onClick={(e) => {
                            e.preventDefault();
                            e.target.blur();

                            onClick(5 - index);
                        }}>
                            {Array(5 - index).fill(0).map((subitem, starIndex) => (
                                <i className={'ti text-warning fs-5 ' + starClassName} key={'star-' + starIndex}></i>
                            ))}
                            <span className={'text-warning fs-2 fw-semibold'}>({stats[indexToStatsKey[5 - index]]})</span>
                        </a>
                    </li>
                );
            })}
        </ul>
    );
}
