/**
 * Businesses Reporting model
 *
 * @param {ApiHelper} api
 */
export default class BusinessesReportingModel {
    baseUrl = '/businesses/{locationId}/insights/reporting';

    constructor(api, locationId) {
        this.api = api;
        this.baseUrl = this.baseUrl.replace('{locationId}', locationId);
    }


    async view() {
        const response = await this.api.getRequest(this.baseUrl);
        return (response.success === true)?(response.data):false;
    }


    async update(data, errorHandler) {
        const response = await this.api.postRequestFile(this.baseUrl, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async delete() {

        const response = await this.api.deleteRequest(this.baseUrl);
        return (response !== false);
    }
}