import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import Chart from "react-apexcharts";
import BusinessViewMenu from "../BusinessViewMenu";
import {useTranslation} from "react-i18next";


export default function BusinessesInsightsChart(props) {
    const { t} = useTranslation();
    const options = {
        chart: {
            id: "pq-insights-chart",
            type: "area",
            height: 120,
            group: "pq-insights-chart",
            fontFamily: '"Plus Jakarta Sans", "Open Sans", Arial, sans-serif',
            foreColor: "#adb0bb",
            toolbar: {
                show: false,
            },
        },
        stroke: {
            curve: "smooth",
            width: 2,
        },
        fill: {
            type: "gradient",
            gradient: {
                color: "#5D87FF",
                shadeIntensity: 0,
                inverseColors: false,
                opacityFrom: 0.50,
                opacityTo: 0,
                stops: [0, 90],
            },
        },
        markers: {
            show: false,
            size: 0,
        },
        grid: {
            show: false,
        },
        dataLabels: {
            show: false,
            enabled: false,
        },
        xaxis: {
            type: "datetime",
            tooltip: {
                enabled: false,
            }
        },
        tooltip: {
            theme: "dark",
            x: {
                show: false,
                format: 'dd MMM yyyy'
            },
        }
    };

    const seriesBase = [
        {
            name: '',
            color: "#5D87FF",
            data: []
            /*data: [
                {x: new Date('2023-01-01').getTime(), y: 30},
                {x: new Date('2023-01-02').getTime(), y: 40},
                // ... more data points
            ]*/
        },
    ];
    const getSeries = (metricLabel) => {
        let seriesData = [];
        if (props.insights[metricLabel]) {
            Object.entries(props.insights[metricLabel]).forEach(([key, value]) => {
                seriesData.push({x: new Date(key).getTime(), y: parseInt(value)});
            });
        } else if (metricLabel === ('Total')) {
            let tmpData = {};
            ['BUSINESS_BOOKINGS', 'BUSINESS_CONVERSATIONS', 'BUSINESS_DIRECTION_REQUESTS', 'CALL_CLICKS', 'WEBSITE_CLICKS'].forEach((item) => {
                if (props.insights[item]) {
                    Object.entries(props.insights[item]).forEach(([key, value]) => {
                        tmpData[key] = (tmpData[key])?(tmpData[key] + parseInt(value)):parseInt(value);
                    });
                }
            });

            Object.entries(tmpData).forEach(([key, value]) => {
                seriesData.push({x: new Date(key).getTime(), y: parseInt(value)});
            });
        }

        return [{...seriesBase[0], data: seriesData}];
    }


    return (
        <Chart type={'area'} options={options} series={getSeries(props.metricLabel)} width={'100%'} height={120} />
    );
}