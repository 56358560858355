export const AttributeValueType = {
    ATTRIBUTE_VALUE_TYPE_UNSPECIFIED: 'ATTRIBUTE_VALUE_TYPE_UNSPECIFIED',
    BOOL: 'BOOL',
    ENUM: 'ENUM',
    URL: 'URL',
    REPEATED_ENUM: 'REPEATED_ENUM',

    getValue: function(attributeInfo, attributeValue) {
        let values = [];

        if (([this.ENUM, this.BOOL].indexOf(attributeInfo['valueType']) !== -1) && attributeValue.values) {
            values = attributeValue.values;
        } else if (attributeInfo['valueType'] === this.URL) {
            values = attributeValue.uriValues;
        } else if (attributeInfo['valueType'] === this.REPEATED_ENUM) {
            values = attributeValue.repeatedEnumValue.setValues;
        }

        return values;
    }
}