import React from 'react';

import imgLogo from "modernize-essentials/dist/images/backgrounds/errorimg.svg";
import {useTranslation} from "react-i18next";


const Error404 = (props) => {
    const { t} = useTranslation();

    document.title = '404 - '+t('Page not found');

    return (
        <div className="position-relative overflow-hidden min-vh-100 d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center w-100">
                <div className="row justify-content-center w-100">
                    <div className="col-lg-4">
                        <div className="text-center">
                            <img src={imgLogo} alt="" className="img-fluid" width="500" />
                            <h1 className="fw-semibold mb-7 fs-9">{t('Opps!!!')}</h1>
                            <h4 className="fw-semibold mb-7">{t('This page you are looking for could not be found.')}</h4>
                            <a className="btn btn-primary" href="/" role="button">{t('Go Back to Home')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error404;