import React from 'react';

import MainLayout from "../layout/MainLayout";
import {Link, Outlet} from "react-router-dom";

function Businesses(props) {


    return (
        <MainLayout>
            <Outlet />
        </MainLayout>
    );
}

export default Businesses;
