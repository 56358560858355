import React, {useEffect} from 'react';

import SimpleBar from "simplebar-react";
import {useApi} from "../../../../providers/ApiProvider";
import BusinessesModel from "../../../../models/BusinessesModel";
import {Link, useParams} from "react-router-dom";
import {useBreadcrumbs} from "../../../../providers/BreadcrumbsProvider";
import BusinessViewHeader from "../../BusinessViewHeader";
import BusinessViewMenu from "../../BusinessViewMenu";
import {useNotifications} from "../../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../../providers/TranslationProvider";
import BusinessesReviewsPublishRepliesMenu from "./BusinessesReviewsPublishRepliesMenu";
import BusinessesReviewsPublishRepliesItem from "./BusinessesReviewsPublishRepliesItem";
import BusinessesReviewsPublishRepliesDetails from "./BusinessesReviewsPublishRepliesDetails";
import CardLoading from "../../../ui/CardLoading";
import ReviewsRulesModel from "../../../../models/ReviewsRulesModel";
import BusinessesReviewsPublishRepliesStatsAlert from "./BusinessesReviewsPublishRepliesStatsAlert";

export default function BusinessesReviewsPublishReplies(props) {
    const {language} = useMyTranslation();
    const {t} = useTranslation();
    const params = useParams();
    const notificationsContext = useNotifications();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const reviewsRulesModel = new ReviewsRulesModel(apiContext.api);

    const breadcrumbs = useBreadcrumbs();
    const [filterType, setFilterType] = React.useState(0); // 0 = all, 1 = 1 star, 2 = 2 stars, 3 = 3 stars, 4 = 4 stars, 5 = 5 stars
    const [filterSearch, setFilterSearch] = React.useState('');
    const [reviews, setReviews] = React.useState([]);
    const [reviewsFiltered, setReviewsFiltered] = React.useState([]);
    const [selectedReview, setSelectedReview] = React.useState(null);
    const [replyLoading, setReplyLoading] = React.useState(false);
    const [showOffcanvas, setShowOffcanvas] = React.useState(false);
    const [showReviewsList, setShowReviewsList] = React.useState(true);
    const [cardLoading, setCardLoading] = React.useState(true);
    const [reviewsStats, setReviewsStats] = React.useState({});
    const [rules, setRules] = React.useState([]);
    const [reviewsQueue, setReviewsQueue] = React.useState([]);


    const onFilterSearchChange = (e) => {
        setFilterSearch(e.target.value);
    }


    const toggleShowReviewsList = (e) => {
        e.preventDefault();

        setShowReviewsList(!showReviewsList);
    }


    const fetchReviews = () => {
        businessesModel.getUnansweredReviews(params.locationId).then((response) => {
            if (response !== false) {
                setReviews(response);

                let foundReview = false;
                if (selectedReview) {
                    foundReview = response.find((review) => review.reviewId === selectedReview.reviewId);
                    if (!foundReview) {
                        foundReview = response[0];
                    }
                } else {
                    foundReview = response[0];
                }

                if (foundReview) {
                    setSelectedReview(foundReview);
                } else {
                    setSelectedReview(null);
                }
            }
        }).finally(() => {
            setCardLoading(false);
        });
    }


    const onReplySent = () => {
        setCardLoading(true);

        fetchReviews();
    }


    const fetchReviewsQueue = () => {
        businessesModel.getReviewsQueue(params.locationId).then((response) => {
            if (response !== false) {
                setReviewsQueue(response);
            }
        });
    }


    const setMetaData = (profile) => {
        if (profile !== false) {
            breadcrumbs.update({
                title: profile.title,
                breadcrumbs: [
                    {label: t('Businesses List'), url: '/businesses'},
                    {label: profile.title, url: '/businesses/' + params.locationId},
                    {label: t('Reviews'), url: '/businesses/' + params.locationId + '/reviews'},
                    {label: t('Publish Replies')}
                ]
            });

            document.title = t('Publish Replies') + ' | ' + profile.title + ' | LocalBoost';
        }
    }


    const onProfileRefresh = (profile, lastUpdated) => {
        setMetaData(profile);
        fetchReviews();
    }


    const onPublishRepliesClick = (e) => {
        e.preventDefault();

        setReplyLoading(true);

        const reviewIds = reviewsFiltered.map(review => review.reviewId);

        businessesModel.addReviewsQueue(params.locationId, reviewIds).then((response) => {
            if (response !== false) {
                const message = t('Preparing {{num}} replies.', {num: response.replied});
                notificationsContext.notify(message, 'success');

                fetchReviewsQueue();

                setReplyLoading(false);
            }
        });
    }


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                setMetaData(response.data);
            }
        });

        reviewsRulesModel.list(params.locationId).then((response) => {
            if (response !== false) {
                setRules(response);
            }
        });

        fetchReviews();

        fetchReviewsQueue();
    }, [language]);


    useEffect(() => {
        let newReviewsStats = {
            'ONE': 0,
            'TWO': 0,
            'THREE': 0,
            'FOUR': 0,
            'FIVE': 0
        };

        if (reviews) {
            let reviewsFilteredNew = reviews.filter(review => compareTypeToString(review.starRating));

            if (filterSearch.length) {
                reviewsFilteredNew = reviewsFilteredNew.filter(review => {
                    let condition = false;
                    condition += (review.reviewer.displayName.toLowerCase().indexOf(filterSearch.toLowerCase()) !== -1);
                    if (review.comment) {
                        condition += (review.comment.toLowerCase().indexOf(filterSearch.toLowerCase()) !== -1);
                    }

                    return condition;
                });
            }

            setReviewsFiltered(reviewsFilteredNew);

            let selectedExists = false;
            if (selectedReview) {
                reviewsFilteredNew.forEach(review => {
                    if (review.reviewId === selectedReview.reviewId) {
                        selectedExists = true;
                    }
                });
            }
            if (!selectedExists) {
                if (reviewsFilteredNew.length) {
                    setSelectedReview(reviewsFilteredNew[0]);
                } else {
                    setSelectedReview(null);
                }
            }

            reviews.forEach(review => {
                newReviewsStats[review.starRating]++;
            });
        }

        setReviewsStats(newReviewsStats);
    }, [reviews, filterType, filterSearch]);


    const toggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
    }


    const onTypeChange = (type) => {
        if (type === filterType) {
            setFilterType(0);
        } else {
            setFilterType(type);
        }
    }


    const compareTypeToString = (typeStr) => {
        switch (filterType) {
            case 1: return typeStr === 'ONE';
            case 2: return typeStr === 'TWO';
            case 3: return typeStr === 'THREE';
            case 4: return typeStr === 'FOUR';
            case 5: return typeStr === 'FIVE';
            default: return true;
        }
    }


    const onReviewSelect = (review) => {
        setSelectedReview(review);
        setShowReviewsList(false);
    }


    return (
        <>
            <BusinessViewHeader
                locationId={params.locationId}
                onProfileRefresh={onProfileRefresh}
            />

            <BusinessViewMenu locationId={params.locationId} active="reviews"/>

            <div className="d-flex m-block-gap">
                <Link to={'/businesses/' + params.locationId + '/reviews'} className="btn btn-light-info text-info">
                    <i className="ti ti-arrow-left me-2"></i>
                    {t('Back to Reviews')}
                </Link>
            </div>

            <BusinessesReviewsPublishRepliesStatsAlert reviewsQueue={reviewsQueue} />

            <div className={'card w-100 position-relative overflow-hidden chat-application'+(cardLoading ? ' loading' : '')}>
                <div className="d-flex align-items-center justify-content-between gap-3 m-3 d-lg-none" style={{height: '39px'}}>
                    <button className="btn btn-primary d-flex" type="button" aria-controls="chat-sidebar" onClick={toggleOffcanvas}>
                        <i className="ti ti-menu-2 fs-5"></i>
                    </button>
                    <form className={'position-relative w-100'+(!showReviewsList ? ' d-none d-lg-block' : '')}>
                        <input type="text" className="form-control search-chat py-2 ps-5" placeholder="Search" value={filterSearch} onChange={onFilterSearchChange} />
                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
                    </form>
                </div>
                <div className="d-flex w-100">
                    <div className="left-part border-end w-20 flex-shrink-0 d-none d-lg-block">
                        <div className="px-9 pt-4 pb-3">
                            <button className="btn btn-primary y-8 w-100 d-flex align-items-center justify-content-center" style={{marginTop: '-3px'}} onClick={onPublishRepliesClick}>
                                <span
                                    className={'spinner-border spinner-border-sm me-2 ' + ((replyLoading) ? ('') : ('d-none'))}
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <i className={'ti ti-settings-automation fs-4 me-2' + ((replyLoading) ? ' d-none' : '')}></i>
                                {t('Reply to all now')+' ('+reviewsFiltered.length+')'}
                            </button>
                        </div>

                        <div style={{height: 'calc(100vh - 400px)'}}>
                            <BusinessesReviewsPublishRepliesMenu
                                onClick={onTypeChange}
                                selectedRatingNum={filterType}
                                stats={reviewsStats}
                            />
                        </div>

                    </div>
                    <div className="d-flex w-100">
                        <div className="min-width-340">
                        <div className="border-end user-chat-box h-100">
                                <div className="px-4 pt-9 pb-6 mb-2 d-none d-lg-block">
                                    <form className="position-relative">
                                        <input type="text" className="form-control search-chat py-2 ps-5" placeholder="Search" value={filterSearch} onChange={onFilterSearchChange} />
                                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
                                    </form>
                                </div>
                                <div className="app-chat">
                                    <SimpleBar autoHide={true} className={'simplescroll-maxheight'}>
                                        {reviewsFiltered && reviewsFiltered.length ? (
                                        <ul className="chat-users">
                                            {reviewsFiltered.map((review, index) => {
                                                return (
                                                    <BusinessesReviewsPublishRepliesItem
                                                        key={'review-' + index}
                                                        review={review}
                                                        reviewQueueInfo={reviewsQueue && reviewsQueue[review.reviewId] ? reviewsQueue[review.reviewId] : null}
                                                        selectedReview={selectedReview}
                                                        onReviewSelect={onReviewSelect}
                                                    />
                                                );
                                            })}
                                        </ul>
                                        ) : (
                                            <div className="text-center p-9">
                                                <span className={'text-muted'}>{t('No reviews found.')}</span>
                                            </div>
                                        )}
                                    </SimpleBar>
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="chat-container h-100 w-100">
                                <div className="chat-box-inner-part h-100">
                                    <div className={'chatting-box app-email-chatting-box'+(showReviewsList ? ' d-none d-lg-block' : '')}>
                                        <div className="border-bottom chat-meta-user d-flex align-items-center justify-content-between" style={{padding: '24px 20px 26px 20px'}}>
                                            <h5 className="text-dark mb-0 fw-semibold p-1">Review Information</h5>
                                            <ul className="list-unstyled mb-0 d-flex align-items-center">
                                                <li className="d-lg-none d-block">
                                                    <a className="text-dark back-btn px-2 fs-5 bg-hover-primary nav-icon-hover position-relative z-index-5" href="#" onClick={toggleShowReviewsList}>
                                                        <i className="ti ti-arrow-left"></i>
                                                    </a>
                                                </li>
                                                {selectedReview && (
                                                <li className="position-relative" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="important">
                                                    <Link
                                                        to={`/businesses/${params.locationId}/reviews/${selectedReview.reviewId}`}
                                                        className={'text-dark px-2 fs-5 bg-hover-primary nav-icon-hover position-relative z-index-5'}
                                                    >
                                                        <i className="ti ti-external-link"></i>
                                                    </Link>
                                                </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="position-relative overflow-hidden">
                                            <div className="position-relative">
                                                <BusinessesReviewsPublishRepliesDetails
                                                    review={selectedReview}
                                                    reviewQueueInfo={reviewsQueue && selectedReview && reviewsQueue[selectedReview.reviewId] ? reviewsQueue[selectedReview.reviewId] : null}
                                                    rules={rules}
                                                    onReplySent={onReplySent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'offcanvas offcanvas-start user-chat-box'+(showOffcanvas ? ' show' : '')} tabIndex="-1" id="chat-sidebar" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasExampleLabel"> Filter Reviews </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={toggleOffcanvas}></button>
                        </div>
                        <div className="mx-1 pb-3 pt-1 px-8">
                            <button className="btn btn-primary y-8 w-100 d-flex align-items-center justify-content-center">
                                <span
                                    className={'spinner-border spinner-border-sm me-2 ' + ((replyLoading) ? ('') : ('d-none'))}
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <i className={'ti ti-message-plus fs-4 me-2' + ((replyLoading) ? ' d-none' : '')}></i>
                                {t('Reply to all now')}
                            </button>
                        </div>

                        <BusinessesReviewsPublishRepliesMenu
                            onClick={onTypeChange}
                            selectedRatingNum={filterType}
                            stats={reviewsStats}
                        />
                    </div>
                    {showOffcanvas && (
                        <div className={'offcanvas-backdrop fade' + (showOffcanvas ? ' show' : '')}></div>
                    )}
                </div>

                <CardLoading loading={cardLoading} />
            </div>
        </>
    );
}
