import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'modernize-essentials/dist/css/style.min.css';
import 'react-day-picker/dist/style.css';

import "./assets/css/base/theme.css";
import "./assets/css/styles/merged.css";


import reportWebVitals from './reportWebVitals';


import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as Brands from '@fortawesome/free-brands-svg-icons';


import {RouterProvider} from "react-router-dom";
import {AuthProvider} from "./providers/AuthProvider";
import {ApiProvider} from "./providers/ApiProvider";
import {NotificationsProvider} from "./providers/NotificationsProvider";
import {BreadcrumbsProvider} from "./providers/BreadcrumbsProvider";
import {router} from "./router";
import {GoogleMapsApiProvider} from "./providers/googleMapsApiProvider";
import {TranslationProvider} from "./providers/TranslationProvider";
import './i18n';


const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);
library.add(...iconList);

const brandList = Object.keys(Brands)
    .filter((key) => key !== 'fab' && key !== 'prefix')
    .map((icon) => Brands[icon]);
library.add(...brandList);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
        <ApiProvider>
            <GoogleMapsApiProvider>
                <NotificationsProvider>
                    <BreadcrumbsProvider>
                        <TranslationProvider>
                            <RouterProvider router={router}/>
                        </TranslationProvider>
                    </BreadcrumbsProvider>
                </NotificationsProvider>
            </GoogleMapsApiProvider>
        </ApiProvider>
    </AuthProvider>
);

reportWebVitals();