import React, {useEffect} from 'react';
import Alert from "../ui/Alert";
import {useTranslation} from "react-i18next";

function ProfilePhotoCard(props) {
    const { t} = useTranslation();
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [alertNotification, setAlertNotification] = React.useState({type: null, text: null});


    const onSubmit = (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setAlertNotification({
                type: 'danger',
                text: t('New passwords don\'t match.')
            });

            return false;
        }

        if (newPassword === currentPassword) {
            setAlertNotification({
                type: 'danger',
                text: t('New password cannot be the same as the current password.')
            });

            return false;
        }

        props.accountModel.changePassword(currentPassword, newPassword).then((response) => {
            if (response !== false) {
                setAlertNotification({
                    type: 'success',
                    text: t('Password changed successfully.')
                });

                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                setAlertNotification({
                    type: 'danger',
                    text: t('Password change failed.')
                });
            }
        });
    }


    const onCancel = (e) => {
        e.preventDefault();

        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }


    return (
        <div className="card w-100 position-relative overflow-hidden">
            <div className="card-body p-4">
                <h5 className="card-title fw-semibold">{t('Change Password')}</h5>
                <p className="card-subtitle mb-4">{t('To change your password, please confirm here.')}</p>

                {alertNotification.text && (
                    <Alert
                        type={alertNotification.type}
                        text={alertNotification.text}
                    />
                )}

                <form>
                    <div className="mb-4">
                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('Current Password')}</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" value={currentPassword} onChange={(e) => {setCurrentPassword(e.target.value)}} />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('New Password')}</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" value={newPassword} onChange={(e) => {setNewPassword(e.target.value)}} />
                    </div>
                    <div className="">
                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">{t('Confirm Password')}</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value)}} />
                    </div>
                    <div className="">
                        <div className="d-flex align-items-center justify-content-end mt-4 gap-3">
                            <button className="btn btn-primary" onClick={onSubmit}>{t('Save')}</button>
                            <button className="btn btn-light-danger text-danger" onClick={onCancel}>{t('Cancel')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ProfilePhotoCard;
