import jsonErrors from './gmb_errors.json'

export default class ErrorsHelper {
    static convertConstToDescription(constant, t) {
        let description = constant;

        if (jsonErrors[constant]) {
            description = jsonErrors[constant];
        }

        return t(description);
    }


    static displayError(rawResponse, notificationsContext, t) {
        if (rawResponse.data && rawResponse.data.error && (rawResponse.data.error.code === 400)) {
            const details = rawResponse.data.error.details;

            if (details) {
                details.forEach((detail) => {
                    notificationsContext.notify(ErrorsHelper.convertConstToDescription(detail.reason, t), 'error');
                });
            }
        }
    }
}