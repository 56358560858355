import React, {useEffect} from 'react';

import MainLayout from "../layout/MainLayout";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import TableEditable from "../ui/TableEditable";
import {useApi} from "../../providers/ApiProvider";
import UsersModel from "../../models/UsersModel";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import BusinessesPostMedia from "../businesses/posts/BusinessesPostMedia";
import BusinessesPostAddStandard from "../businesses/posts/BusinessesPostAddStandard";
import BusinessesPostAddEvent from "../businesses/posts/BusinessesPostAddEvent";
import BusinessesPostAddOffer from "../businesses/posts/BusinessesPostAddOffer";
import BusinessesPostAddPublish from "../businesses/posts/BusinessesPostAddPublish";
import {useAuth} from "../../providers/AuthProvider";
import {useParams} from "react-router-dom";
import accountTypesModel from "modernize-essentials/libs/quill/dist/quill.min";
import AccountTypesModel from "../../models/AccountTypesModel";

export default function UserSearchForm(props) {
    const { t} = useTranslation();
    const [searchText, setSearchText] = React.useState('');


    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('search');
        if (search) {
            setSearchText(search);
        }
    }, []);


    const onSearch = (e) => {
        e.preventDefault();

        props.onSearch(searchText);
    }


    return (
        <form className={'form-group d-flex flex-grow-1 flex-row align-items-center justify-content-end gap-3'} onSubmit={onSearch}>
            <input id={'u-l-search'} className={'form-control w-25'} autoComplete={'off'} placeholder={t('Enter your request...')} value={searchText} onChange={(e) => {
                setSearchText(e.target.value)
            }}/>
            <button className="btn btn-primary" onClick={onSearch}>{t('Search')}</button>
        </form>
    );
}
