import React, {useEffect} from 'react';
import * as L from 'leaflet';
import "leaflet.gridlayer.googlemutant";
import mapPin from '../../../assets/img/pin.svg';
import {useTranslation} from "react-i18next";

function BusinessFormMap(props) {
    const { t} = useTranslation();
    const [latlng, setLatlng] = React.useState({latitude: 0, longitude: 0});
    const [editMode, setEditMode] = React.useState(false);
    const mapContainer = React.createRef();
    const [map, setMap] = React.useState(null);
    const [mainMarker, setMainMarker] = React.useState(null);


    useEffect(() => {
        const propCoords = {
            latitude: parseFloat(props.latlng.latitude),
            longitude: parseFloat(props.latlng.longitude)
        };
        setLatlng(propCoords);

        if (map) {
            map.setView(L.latLng(propCoords.latitude, propCoords.longitude), map.getZoom());
            mainMarker.setLatLng(L.latLng(propCoords.latitude, propCoords.longitude));
        }
    }, [props.latlng]);

    useEffect(() => {
        const newMap = L.map(mapContainer.current, {
            attributionControl: false,
            scrollWheelZoom: false,
            doubleClickZoom: false,
            dragging: false,
            zoomControl: true,
            touchZoom: false,
            keyboard: false
        }).setView(L.latLng(parseFloat(props.latlng.latitude), parseFloat(props.latlng.longitude)), 15);
        L.gridLayer.googleMutant({type: 'roadmap'}).addTo(newMap);
        newMap.zoomControl.remove();

        const newMainMarker = L.marker([parseFloat(props.latlng.latitude), parseFloat(props.latlng.longitude)], {
            icon: L.icon({
                iconUrl: mapPin,
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                popupAnchor: [0, -40]
            }),
            draggable: false
        }).addTo(newMap);
        setMap(newMap);
        setMainMarker(newMainMarker);
    }, []);


    const enableMapChanges = () => {
        map.scrollWheelZoom.enable();
        map.doubleClickZoom.enable();
        map.dragging.enable();
        map.touchZoom.enable();
        map.keyboard.enable();
        map.zoomControl.addTo(map);

        map.on('move', function(e) {
            let coords = e.target.getCenter();
            setLatlng({latitude: coords.lat, longitude: coords.lng});
            mainMarker.setLatLng(L.latLng(coords.lat, coords.lng));
        });
    }


    const disableMapChanges = () => {
        map.scrollWheelZoom.disable();
        map.doubleClickZoom.disable();
        map.dragging.disable();
        map.touchZoom.disable();
        map.keyboard.disable();

        map.zoomControl.remove();

        map.off('move');
    }


    const onAdjust = (e) => {
        e.preventDefault();

        setEditMode(true);

        enableMapChanges();
    }


    const onReset = (e) => {
        e.preventDefault();

        setLatlng(props.latlng);
        map.setView(L.latLng(props.latlng.latitude, props.latlng.longitude), map.getZoom());
        mainMarker.setLatLng(L.latLng(props.latlng.latitude, props.latlng.longitude));
        setEditMode(false);

        disableMapChanges();
    }


    const onDone = (e) => {
        e.preventDefault();

        props.onLocationChange(latlng);
        setEditMode(false);

        disableMapChanges();
    }


    return (
        <div className={'business-form-map-cover'}>
            {props.isEditable && (
            <div className="on-map-toolbox">
                {editMode && (
                    <div className="btn-container">
                        <button className="btn btn-muted" onClick={onReset}>{t('Reset')}</button>
                        <button className="btn btn-primary" onClick={onDone}>{t('Done')}</button>
                    </div>
                ) || !editMode && (
                    <button className="btn btn-dark" onClick={onAdjust}>{t('Adjust')}</button>
                )}
            </div>
            )}
            <div className={'business-form-map'} ref={mapContainer}>
            </div>
        </div>
    );
}

export default BusinessFormMap;
