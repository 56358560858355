import React from 'react';
import {useTranslation} from "react-i18next";

function BusinessesPostAddOffer(props) {
    const { t} = useTranslation();


    const pad = (num, length) => {
        if (!length) {length = 2;}

        let str = '' + (num ? num : 0);
        while (str.length < length) {
            str = '0' + str;
        }

        return str;
    }


    const dateToString = (date) => {
        if (!date || !isValidDate(date.year, date.month, date.day)) {
            return '';
        } else {
            return date.year+'-'+pad(date.month)+'-'+pad(date.day);
        }
    }
    const stringToDate = (date) => {
        let parts = date.split('-');
        let results = {
            day: parseInt(parts[2]),
            month: parseInt(parts[1]),
            year: parseInt(parts[0])
        };

        if (!isValidDate(results.year, results.month, results.day)) {
            return null;
        } else {
            return results;
        }
    }


    const isValidDate = (year, month, day) => {
        const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        return date.getFullYear() === year &&
               date.getMonth() === month - 1 &&
               date.getDate() === day;
    }


    const onDateChange = (field, value) => {
        return props.onFieldChange(field, stringToDate(value));
    }


    return (
        <div className="row">
            <div className="col-md-8">
                <div className={'form-group py-2'}>
                    <label htmlFor="cf-event-title" className="form-label fw-semibold">{t('Offer Title')}</label>
                    <input type="text" className={'form-control'} value={props.data.event.title} onChange={(e) => {
                        props.onFieldChange('event.title', e.target.value)
                    }}/>
                    <div className="d-flex justify-content-between">
                        {props.errors['event.title'] && <div className="form-text text-danger">{props.errors['event.title'].join('. ')}</div>}
                        <div className="text-end flex-grow-1"><small className="form-text text-muted">{props.data.event.title.length} / 58</small></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className={'form-group py-2'}>
                            <label htmlFor="cf-start-date" className="form-label fw-semibold">{t('Start Date')}</label>
                            <input type="date" id={'cf-start-date'} className={'form-control'} value={dateToString(props.data.event.schedule.startDate)}
                                   onChange={(e) => {
                                       onDateChange('event.schedule.startDate', e.target.value)
                                   }}/>
                            {props.errors['event.schedule.start_date'] &&
                                <div className="form-text text-danger">{props.errors['event.schedule.start_date'].join('. ')}</div>}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className={'form-group py-2'}>
                            <label htmlFor="cf-end-date" className="form-label fw-semibold">{t('End Date')}</label>
                            <input type="date" id={'cf-end-date'} className={'form-control'} value={dateToString(props.data.event.schedule.endDate)}
                                   onChange={(e) => {
                                       onDateChange('event.schedule.endDate', e.target.value)
                                   }}/>
                            {props.errors['event.schedule.end_date'] &&
                                <div className="form-text text-danger">{props.errors['event.schedule.end_date'].join('. ')}</div>}
                        </div>
                    </div>
                </div>

                <h6 className="mt-4">{t('Add more details (Optional)')}</h6>

                <div className={'form-group py-2'}>
                    <label htmlFor="cf-offer-details" className="form-label fw-semibold">{t('Offer details')}</label>
                    <textarea id={'cd-offer-details'} className={'form-control'} rows={10} value={props.data.summary} onChange={(e) => {
                        props.onFieldChange('summary', e.target.value)
                    }}></textarea>
                    <div className="d-flex justify-content-between">
                        {props.errors.summary && <div className="form-text text-danger">{props.errors.summary.join('. ')}</div>}
                        <div className="text-end flex-grow-1"><small className="form-text text-muted">{props.data.summary ? props.data.summary.length : 0} / 1500</small></div>
                    </div>
                </div>

                <div className={'form-group py-2'}>
                    <label htmlFor="cf-voucher-code" className="form-label fw-semibold">{t('Voucher code')}</label>
                    <input type="text" id={'cd-voucher-code'} className={'form-control'} value={props.data.offer ? props.data.offer.couponCode : ''} onChange={(e) => {
                        props.onFieldChange('offer.couponCode', e.target.value)
                    }}/>
                </div>

                <div className={'form-group py-2'}>
                    <label htmlFor="cf-link-to-redeem" className="form-label fw-semibold">{t('Link to redeem offer')}</label>
                    <input type="text" id={'cf-link-to-redeem'} className={'form-control'} value={props.data.offer ? props.data.offer.redeemOnlineUrl : ''} onChange={(e) => {
                        props.onFieldChange('offer.redeemOnlineUrl', e.target.value)
                    }}/>
                </div>

                <div className={'form-group py-2'}>
                    <label htmlFor="cf-terms" className="form-label fw-semibold">{t('Terms and conditions')}</label>
                    <input type="text" id={'cf-terms'} className={'form-control'} value={props.data.offer ? props.data.offer.termsConditions : ''} onChange={(e) => {
                        props.onFieldChange('offer.termsConditions', e.target.value)
                    }}/>
                </div>
            </div>
        </div>
    );
}

export default BusinessesPostAddOffer;
