import React, {useEffect} from 'react';

import MainLayout from "../layout/MainLayout";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import TableEditable from "../ui/TableEditable";
import {useApi} from "../../providers/ApiProvider";
import UsersModel from "../../models/UsersModel";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import BusinessesPostMedia from "../businesses/posts/BusinessesPostMedia";
import BusinessesPostAddStandard from "../businesses/posts/BusinessesPostAddStandard";
import BusinessesPostAddEvent from "../businesses/posts/BusinessesPostAddEvent";
import BusinessesPostAddOffer from "../businesses/posts/BusinessesPostAddOffer";
import BusinessesPostAddPublish from "../businesses/posts/BusinessesPostAddPublish";
import {useAuth} from "../../providers/AuthProvider";
import {useParams} from "react-router-dom";
import accountTypesModel from "modernize-essentials/libs/quill/dist/quill.min";
import AccountTypesModel from "../../models/AccountTypesModel";

function UserEdit(props) {
    const params = useParams();
    const authContext = useAuth();
    const userData = authContext.user;
    const {language} = useMyTranslation();
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const usersModel = new UsersModel(apiContext.api);
    const accountTypesModel = new AccountTypesModel(apiContext.api);

    const { t} = useTranslation();
    const [billingPlan, setBillingPlan] = React.useState('free');
    const [name, setName] = React.useState('');
    const [types, setTypes] = React.useState({});


    useEffect(() => {
        breadcrumbs.update({
            title: t('Edit user information'),
            breadcrumbs: [
                {label: t('Users'), url: '/users'},
                {label: t('Edit user information')}
            ]
        });

        document.title = t('Edit user information')+' | LocalBoost';

        usersModel.view(params.userId).then((response) => {
            if (response !== false) {
                setName(response.name);
                setBillingPlan(response.type);
            }
        });

        accountTypesModel.list().then((response) => {
            if (response !== false) {
                let newTypes = {
                    /*'free': t('Free'),
                    '1_profile': t('1 Profile'),
                    '3_profiles': t('{{count}} Profiles', {count: 3}),
                    '10_profiles': t('{{count}} Profiles', {count: 10}),
                    'admin': t('Administrator'),*/
                };
                response.map((type) => {
                    newTypes[type.id] = type.name;
                });
                setTypes(newTypes);
            }
        });
    }, [language]);


    const onSubmit = (e) => {
        e.preventDefault();

        usersModel.update(params.userId, {
            'type': billingPlan,
            'name': name,
        }).then((response) => {
            if (response !== false) {
                window.location.href = '/users';
            }
        });
    }


    const onImpersonate = (e) => {
        e.preventDefault();

        usersModel.impersonate(params.userId).then((response) => {
            if (response !== false) {
                const formattedResponse = {
                    access_token: response
                }
                let user = usersModel.processAuthResponse(formattedResponse);
                if (user) {
                    authContext.logout();
                    authContext.login(user);
                    window.location.href = '/';
                }
            }
        });
    }


    return (
        <SimpleCardContainer title={t('Edit user information')}>
            <form className={'py-2'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'form-group'}>
                            <label className="form-label fw-semibold mb-2">{t('Full name')}</label>

                            <input id={'cd-name'} className={'form-control'} value={name} onChange={(e) => {
                                setName(e.target.value)
                            }}/>
                        </div>

                        <div className={'form-group mt-3'}>
                            <label className="form-label fw-semibold mb-2">{t('Current billing plan')}</label>

                            <select id={'cd-button'} className={'form-control form-select'} value={billingPlan} onChange={(e) => {
                                setBillingPlan(e.target.value)
                            }}>
                                {Object.keys(types).map((key) => {
                                    return <option value={key} key={key}>{types[key]}</option>
                                })}
                            </select>

                            <button className="btn btn-primary mt-3" onClick={onSubmit}>{t('Save changes')}</button>
                        </div>
                    </div>
                    <div className={'col-md-6'}>
                        <div className="bordered-tip pb-4 pt-4">
                            <div className="alert alert-warning fs-2">
                                <strong>{t('Warning!')}</strong><br/>
                                {t('You can operate on user\'s behalf now.')}

                                <button className={'btn btn-danger d-flex align-items-center mt-3'} onClick={onImpersonate}>
                                    <i className={'ti ti-status-change fs-4 me-2'}></i>
                                    {t('Impersonate user')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </SimpleCardContainer>
    );
}

export default UserEdit;
