import React, {useEffect} from 'react';

import SimpleBar from "simplebar-react";
import {useApi} from "../../../../providers/ApiProvider";
import BusinessesModel from "../../../../models/BusinessesModel";
import {Link, useParams} from "react-router-dom";
import {useBreadcrumbs} from "../../../../providers/BreadcrumbsProvider";
import BusinessViewHeader from "../../BusinessViewHeader";
import BusinessViewMenu from "../../BusinessViewMenu";
import {useNotifications} from "../../../../providers/NotificationsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../../providers/TranslationProvider";
import BusinessesReviewsPublishRepliesMenu from "./BusinessesReviewsPublishRepliesMenu";
import StarRating from "../../../ui/StarRating";

export default function BusinessesReviewsPublishRepliesItem({review, selectedReview, onReviewSelect, reviewQueueInfo}) {
    const {t} = useTranslation();

    const [isSelected, setIsSelected] = React.useState(false);
    const [starRatingNumeric, setReviewRating] = React.useState(null);


    useEffect(() => {
        setIsSelected(selectedReview && (selectedReview.reviewId === review.reviewId));
    }, [selectedReview]);


    const onClick = (e) => {
        e.preventDefault();
        onReviewSelect(review);
    }


    useEffect(() => {
        if (review) {
            let starRatingNumeric = 0;
            switch (review.starRating) {
                case 'ONE': starRatingNumeric = 1; break;
                case 'TWO': starRatingNumeric = 2; break;
                case 'THREE': starRatingNumeric = 3; break;
                case 'FOUR': starRatingNumeric = 4; break;
                case 'FIVE': starRatingNumeric = 5; break;
            }
            setReviewRating(starRatingNumeric);
        }
    }, [review]);


    const getQueueStatusLabel = () => {
        switch (reviewQueueInfo.status) {
            case 'pending': return (<i className={'ti ti-hourglass fs-6'} title={t('Processing')}></i>);
            case 'publishing': return (<i className={'ti ti-outbound fs-6'} title={t('Publishing')}></i>);
            case 'published': return (<i className={'ti ti-checks fs-6'} title={t('Published')}></i>);
            case 'error': return (<i className={'ti ti-alert-triangle fs-6'} title={t('Error')}></i>);
        }
    }


    const getQueeueStatusClasses = () => {
        switch (reviewQueueInfo.status) {
            case 'pending': return 'bg-light-primary text-primary';
            case 'publishing': return 'bg-light-warning text-warning';
            case 'published': return 'bg-light-success text-success';
            case 'error': return 'bg-light-danger text-danger';
        }
    }


    return (
        <li className={'position-relative'}>
            <a href="#" className={'px-4 py-3 bg-hover-light-black d-flex align-items-center chat-user' + (isSelected ? ' bg-light' : '')} onClick={onClick}>
                <span className="position-relative">
                    <img src={review.reviewer.profilePhotoUrl} alt={review.reviewer.displayName} width="40" height="40" className="rounded-circle"/>
                </span>

                <div className="ms-6 d-inline-block w-75">
                    <h6 className="mb-0 fw-semibold chat-title">{review.reviewer.displayName}</h6>
                    <span className="fs-2 text-body-color d-block">
                        {starRatingNumeric && (
                            <StarRating rating={starRatingNumeric}/>
                        )}
                    </span>
                </div>
            </a>

            <div className={'position-absolute end-0 top-50 translate-middle-y pe-4'}>
                {reviewQueueInfo && (
                    <span className={'badge '+getQueeueStatusClasses()}>{getQueueStatusLabel()}</span>
                )}
            </div>
        </li>
    );
}
