import React from "react";

import imgFlagEn from "modernize-essentials/dist/images/svgs/icon-flag-en.svg";
import imgFlagDe from "modernize-essentials/dist/images/svgs/icon-flag-de.svg";
import {useTranslation} from "../../../providers/TranslationProvider";


export default function HeaderLanguage(props) {

    const translation = useTranslation();
    const [visible, setVisible] = React.useState(false);


    const onDropdownToggle = (e) => {
        e.preventDefault();

        setVisible(!visible);
    }


    const onLanguageChange = (e, lang) => {
        e.preventDefault();

        translation.translate(lang);
        setVisible(false);
    }


    return (
        <li className="nav-item dropdown">
            <a href="#" className={'nav-link nav-icon-hover'+(visible ? ' show' : '')} onClick={onDropdownToggle}>
                <img src={(translation.language === 'de' ? imgFlagDe : imgFlagEn)} alt="English" className="rounded-circle object-fit-cover round-20" />
            </a>
            <div className={'dropdown-menu dropdown-menu-end dropdown-menu-animate-up'+(visible ? ' show' : '')} data-bs-popper="static">
                <div className="message-body">
                    <a href="#" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item" onClick={(e) => {onLanguageChange(e, 'en')}}>
                        <div className="position-relative">
                            <img src={imgFlagEn} alt="English" className="rounded-circle object-fit-cover round-20" />
                        </div>
                        <p className="mb-0 fs-3">English</p>
                    </a>
                    <a href="#" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item" onClick={(e) => {onLanguageChange(e, 'de')}}>
                        <div className="position-relative">
                            <img src={imgFlagDe} alt="Deutsch" className="rounded-circle object-fit-cover round-20" />
                        </div>
                        <p className="mb-0 fs-3">Deutsch</p>
                    </a>
                </div>
            </div>
        </li>
    );
};