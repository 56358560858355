import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useParams} from "react-router-dom";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useTranslation} from "react-i18next";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";
import BCP47LanguageCodes from "../BCP47LanguageCodes";

export default function BusinessFormLanguage(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [data, setData] = React.useState('');
    const [errors, setErrors] = React.useState([]);
    const [saveLoading, setSaveLoading] = React.useState(false);


    useEffect(() => {
        setData(props.data);
    }, [props.data]);


    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        businessesModel.update(params.locationId, {language: data}).then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
                setEditMode(false);
            }

            setSaveLoading(false);
        });
    }


    const onCancel = (e) => {
        e.preventDefault();

        setData(props.data);
        setEditMode(false);
    }


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <BusinessFormHeadingProgress
                editMode={editMode}
                title={t('Preferred Language')}
                id="language"
            />

            {editMode === false && (
                <div className="pt-2">{BCP47LanguageCodes.languages.hasOwnProperty(data) ? BCP47LanguageCodes.languages[data] : t('Not detected')}</div>
            )}
            {editMode === true && (
                <>
                <div className="fs-2 mb-3 mt-1">{t('You can define the language, used for your business by default.')}</div>
                <div className="row">
                    <div className="col-md-6">
                        <select className={'form-select'} id="cf-language" value={data} onChange={(e) => {
                            setData(e.target.value)
                        }}>
                            {Object.keys(BCP47LanguageCodes.languages).map((key) => {
                                return <option value={key} key={key}>{BCP47LanguageCodes.languages[key]}</option>
                            })}
                            <option value="">{t('Not detected')}</option>
                        </select>

                        <div className="d-flex justify-content-between">
                            {errors && <div className="form-text text-danger">{errors.join('. ')}</div>}
                        </div>

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel}/>
                    </div>
                </div>
                </>
            )}
        </div>
        </>
    );
}
