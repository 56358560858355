import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import imgLogo from '../../assets/img/logo.png';

import imgProfileTmp from 'modernize-essentials/dist/images/profile/user-1.jpg';

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import SimpleBar from "simplebar-react";
import {useAuth} from "../../providers/AuthProvider";
import {useApi} from "../../providers/ApiProvider";
import AccountModel from "../../models/AccountModel";
import { useTranslation } from 'react-i18next';
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";


//using Tabler Icons from https://tabler-icons.io/

const Nav = (props) => {
    const {language} = useMyTranslation();
    const authContext = useAuth();
    const userData = authContext.user;
    const { t} = useTranslation();

    const apiContext = useApi();
    const accountModel = new AccountModel(apiContext.api);

    let imgProfile = imgProfileTmp;
    if (userData.avatar) {
        imgProfile = userData.avatar;
    }


    const onNavbarToggle = (e) => {
        e.preventDefault();

        const htmlClass = 'navbar-vertical-collapsed';
        const html = document.querySelector('html');

        if (html.classList.contains(htmlClass)) {
            html.classList.remove(htmlClass);
        } else {
            html.classList.add(htmlClass);
        }

        const navbarVertical = document.querySelector('#navbarVerticalCollapse');
        const navbarVerticalCollapse = new bootstrap.Collapse(navbarVertical, {
            toggle: false
        });
        navbarVerticalCollapse.toggle();
    }


    const onLogoutClick = (e) => {
        e.preventDefault();

        window.location.href = '/auth/logout';
    }


    let menuDefault = {
        'businesses': {
            'label': t('My Businesses'),
            'items': {
                /*'business-create': {
                    'icon': 'plus',
                    'title': t('Create a new Business'),
                    'url': '/businesses/create'
                },*/
                'business-add': {
                    'icon': 'link',
                    'title': t('Connect a Location'),
                    'url': '/businesses/add'
                },
                'businesses': {
                    'icon': 'map-pin',
                    'title': t('Tracked Locations'),
                    'url': '/businesses',
                    'count': null
                }
            }
        },
        'reports': {
            'label': t('Reports'),
            'items': {
                'reports-activity': {
                    'icon': 'chart-line',
                    'title': t('Latest Activity'),
                    'url': '/reports'
                },
            }
        },
        'settings': {
            'label': t('Settings'),
            'items': {
                'settings': {
                    'icon': 'settings',
                    'title': t('Account Settings'),
                    'url': '/settings'
                },
            }
        }
    }
    if (userData.type === 'admin') {
        menuDefault['settings']['items']['account-types'] = {
            'icon': 'lock-access',
            'title': t('Account types'),
            'url': '/account-types'
        };
        menuDefault['settings']['items']['users'] = {
            'icon': 'users',
            'title': t('Users'),
            'url': '/users'
        };
    }
    const [menu, setMenu] = React.useState(menuDefault);
    const isElementActive = (key, section) => {
        const simpleKeys = {
            'businesses': ['businesses'],
            'reports': ['reports-activity', 'reports-for-ai'],
            'settings': ['settings']
        };

        if (simpleKeys[section].indexOf(key) !== -1) {
            if (window.location.pathname.indexOf(menu[section]['items'][key].url) !== -1) {
                return true;
            }
        }

        return false;
    }


    React.useEffect(() => {
        accountModel.getGeneralInfo().then((response) => {
            if (response !== false) {
                const newMenu = {...menuDefault};
                let maxBusinesses = response.businessesLeft + response.businessesCount;
                if (maxBusinesses > 100) {
                    maxBusinesses = '∞';
                }
                newMenu['businesses']['items']['businesses']['count'] = response.businessesCount + '/' + maxBusinesses;
                setMenu(newMenu);
            }
        });
    }, [language]);


    const checkIfReactivationNeeded = (response) => {
        if (response !== false) {
            if (response.requiresReauth === true) {
                window.location.href = '/businesses/reactivate';
                return true;
            }
        }

        return false;
    }


    return (
        <aside className="left-sidebar">
            <div>
                <div className="brand-logo d-flex align-items-center justify-content-between">
                    <a href="/" className="text-nowrap logo-img">
                        <img src={imgLogo} className="dark-logo" width="180" alt=""/>
                    </a>
                    <div className="close-btn d-lg-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse" onClick={props.onSidebarToggle}>
                        <i className="ti ti-x fs-8 text-muted"></i>
                    </div>
                </div>
                <SimpleBar autoHide={true} className={'sidebar-nav scroll-sidebar'}>
                    <ul id="sidebarnav">
                        {Object.keys(menu).map((section, sIndex) => {
                            return (
                                <React.Fragment key={sIndex}>
                                    <li className="nav-small-cap">
                                        <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                        <span className="hide-menu">{menu[section]['label']}</span>
                                    </li>
                                    {Object.keys(menu[section]['items']).map((key, index) => {
                                        const element = menu[section]['items'][key];
                                        return (
                                            <li className={`sidebar-item ${isElementActive(key, section) ? 'selected' : ''}`} key={sIndex+'-'+index}>
                                                <a className={`sidebar-link ${isElementActive(key, section) ? 'active' : ''}`} href={element.url} aria-expanded="false">
                                                    <span>
                                                        <i className={`ti ti-${element.icon}`}></i>
                                                    </span>
                                                    <span className="hide-menu">{element.title}</span>
                                                    {/*typeof element.count === 'number'*/(element.count !== undefined) && (
                                                        <span className="hide-menu" style={{marginLeft: 'auto'}}><span className="align-items-center badge bg-primary d-flex fs-2 justify-content-center p-0 px-1 py-1">{element.count}</span></span>
                                                    )}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </SimpleBar>
                <div className="fixed-profile p-3 bg-light-secondary rounded sidebar-ad mt-3">
                    <div className="hstack gap-3">
                        <div className="john-img">
                            <img src={imgProfile} className="rounded-circle" width="40" height="40" alt=""/>
                        </div>
                        <div className="john-title">
                            <h6 className="mb-0 fs-4 fw-semibold">{userData.name}</h6>
                            <span className="fs-2 text-dark">{userData.type}</span>
                        </div>
                        <button className="border-0 bg-transparent text-primary ms-auto" tabIndex="0" onClick={onLogoutClick}>
                            <i className="ti ti-power fs-6"></i>
                        </button>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default Nav;
