/**
 * AccountTypes model
 *
 * @param {ApiHelper} api
 */
export default class AccountTypesModel {
    constructor(api) {
        this.api = api;
    }


    async list() {
        const url = '/account-types';

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):false;
    }

    async view(accountTypeId) {
        const url = '/account-types/'+accountTypeId;

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):false;
    }

    async create(accountType, errorHandler) {
        const url = '/account-types';

        const response = await this.api.postRequest(url, accountType);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }

    async update(accountTypeId, accountType, errorHandler) {
        const url = '/account-types/'+accountTypeId;

        const response = await this.api.postRequest(url, accountType);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }

    async delete(accountTypeId, errorHandler) {
        const url = '/account-types/'+accountTypeId;

        const response = await this.api.deleteRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }
}