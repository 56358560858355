import React, {useEffect} from 'react';

import {useTranslation} from "react-i18next";

export default function BusinessFormServiceCheckbox({value, metaData, label, onChange, editMode}) {
    const {t} = useTranslation();
    const uid = Math.random().toString(36).substring(7);


    const onValueChange = (e) => {
        onChange(!value);
    }


    const getValueLabel = () => {
        let valueLabel = false;

        if (metaData && metaData.length) {
            metaData.forEach((meta) => {
                if (meta.value === value) {
                    valueLabel = meta.displayName;
                }
            });
        }

        return valueLabel;
    }


    return editMode ? (
        <div className="form-check form-check-inline">
            <input
                type="checkbox"
                id={'closed-' + uid}
                name={'closed-' + uid}
                className={'form-check-input danger'}
                checked={value === true}
                onChange={onValueChange}
            />
            <label
                className="form-check-label cursor-pointer"
                htmlFor={'closed-' + uid}
                onClick={onValueChange}>{label}</label>
        </div>
    ) : (getValueLabel() ? (
        <div className="d-flex align-items-center"><i className="ti ti-checks fs-3 me-2 ms-1"></i>{getValueLabel()}</div>
    ) : (''));
}
