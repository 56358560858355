import React, {useEffect} from 'react';
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import BusinessFormTimePeriod from "./BusinessFormTimePeriod";
import {useTranslation} from "react-i18next";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";
import ErrorsHelper from "../../../helpers/ErrorsHelper";

function BusinessFormOpeningHours(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [data, setData] = React.useState({});
    const [saveLoading, setSaveLoading] = React.useState(false);


    useEffect(() => {
        if (props.data && props.data.periods && props.data.periods.length) {
            const periods = props.data.periods.map(period => ({
                ...period, // Spread the existing period properties
                openTime: { // Create a new object for openTime
                    hours: period.openTime.hours || 0,
                    minutes: period.openTime.minutes || 0
                },
                closeTime: { // Create a new object for closeTime
                    hours: period.closeTime.hours || 0,
                    minutes: period.closeTime.minutes || 0
                }
            }));

            setData({...props.data, periods: periods});
        } else {
            setData({...props.data, periods: []});
        }
    }, [props.data, editMode]);


    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        businessesModel.update(params.locationId, {regularHours: data}, saveErrorHandler).then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
            }

            setSaveLoading(false);
            setEditMode(false);
        });
    }


    const saveErrorHandler = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
    }


    const onCancel = (e) => {
        e.preventDefault();

        setData(props.data);
        setEditMode(false);
    }

    const onCloseChange = (checked, dayName) => {
        setData(currentData => {
            const newPeriods = currentData.periods.filter(period => period.openDay.toUpperCase() !== dayName.toUpperCase());

            if (!checked) {
                newPeriods.push({
                    openDay: dayName,
                    openTime: {hours: 0, minutes: 0},
                    closeDay: dayName,
                    closeTime: {hours: 24, minutes: 0}
                });
            }

            return {...currentData, periods: newPeriods};
        });
    };


    const onAddClick = (dayName) => {
        let newPeriods = [...data.periods];
        newPeriods.push({
            openDay: dayName,
            openTime: {hours: 0, minutes: 0},
            closeDay: dayName,
            closeTime: {hours: 24, minutes: 0}
        });

        setData(currentData => {
            return {...currentData, periods: newPeriods};
        });
    }


    const onDeleteClick = (dayName, index) => {
        let newPeriods = [...data.periods];
        newPeriods.splice(index, 1);

        setData(currentData => {
            return {...currentData, periods: newPeriods};
        });
    }


    const padTo2 = (number) => {
        return (number < 10 ? '0' : '') + number;
    }


    return (
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <BusinessFormHeadingProgress
                score={props.score}
                editMode={editMode}
                title={t('Hours')}
                id="hours"
            />

            {editMode === false && (
                <div>
                    <div className={'mt-2'}>
                        {data && data.periods && data.periods.length ?
                        [('Monday'), ('Tuesday'), ('Wednesday'), ('Thursday'), ('Friday'), ('Saturday'), ('Sunday')].map((dayName, dIndex) => (
                            <div className="mt-2" key={'d'+dIndex}>
                                <h5 className={'h6 my-0'}>{t(dayName)}</h5>

                                {data && data.periods && data.periods.length ? data.periods.map((period, pIndex) => {
                                    if (period.openDay.toUpperCase() === dayName.toUpperCase()) {
                                        let is24Hours = false;
                                        if (
                                            period.openTime.hours === 0 &&
                                            period.openTime.minutes === 0 &&
                                            period.closeTime.hours === 24 &&
                                            period.closeTime.minutes === 0
                                        ) {
                                            is24Hours = true;
                                        }

                                        return (
                                            <div className={'mt-1'} key={'p' + pIndex}>
                                                {is24Hours ? (
                                                    <span>{t('open 24 hours')}</span>
                                                ) : (
                                                    <span>{padTo2(period.openTime.hours)}:{padTo2(period.openTime.minutes)} - {padTo2(period.closeTime.hours)}:{padTo2(period.closeTime.minutes)}</span>
                                                )}
                                            </div>
                                        );
                                    }

                                    if (pIndex === 0) {
                                        let dayExists = false;
                                        data.periods.forEach((period, pIndex) => {
                                            if (period.openDay.toUpperCase() === dayName.toUpperCase()) {
                                                dayExists = true;
                                            }
                                        });

                                        if (!dayExists) {
                                            return (
                                                <div className={'mt-1'} key={'p' + pIndex}>
                                                    <span>{t('Closed')}</span>
                                                </div>
                                            );
                                        }
                                    }
                                }) : (
                                    <div className={'mt-1'}>
                                        <span>{t('Closed')}</span>
                                    </div>
                                )}
                            </div>
                        )) : (
                        <div className={'mt-1'}>
                            <span>{t('Regular hours not defined.')}</span>
                        </div>
                        )}
                    </div>
                </div>
            )}
            {editMode === true && (
                <div className="row">
                    <div className="col-md-8 col-xl-8">
                        <div className="fs-2 mb-3 mt-1">{t('Set main business hours or mark your business as closed.')}</div>

                        {[('Monday'), ('Tuesday'), ('Wednesday'), ('Thursday'), ('Friday'), ('Saturday'), ('Sunday')].map((dayName, ddIndex) => {
                            let closedCheckboxChecked = true;
                            if (data.periods) {
                                data.periods.forEach((period, pIndex) => {
                                    if (period.openDay.toUpperCase() === dayName.toUpperCase()) {
                                        closedCheckboxChecked = false;
                                    }
                                });
                            } else if (data.periods && !data.periods.length) {
                                closedCheckboxChecked = false;
                            }

                            let dayIndex = 0;

                            return (
                                <div className="mt-2" key={ddIndex+'|'+closedCheckboxChecked}>
                                    <h5 className={'h6 my-0'}>{t(dayName)}</h5>

                                    <div className={'row mt-2'}>
                                        <div className={'col-md-3 pt-1'}>
                                            <div className="form-check form-check-inline py-2">
                                                <input
                                                    type="checkbox"
                                                    id={'closed-'+ddIndex}
                                                    name={'closed-'+ddIndex}
                                                    className={'form-check-input danger'}
                                                    checked={closedCheckboxChecked}
                                                    onChange={(e) => {onCloseChange(e.target.checked, dayName)}}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={'closed-'+ddIndex}
                                                    onClick={(e) => {onCloseChange(!closedCheckboxChecked, dayName)}}>{t('Closed')}</label>
                                            </div>
                                        </div>
                                        <div className={'col-md-9'}>
                                            {!closedCheckboxChecked && data && data.periods && data.periods.map((period, pIndex) => {
                                                if (period.openDay.toUpperCase() === dayName.toUpperCase()) {
                                                    dayIndex++;
                                                    return (
                                                        <BusinessFormTimePeriod
                                                            key={ddIndex+'|'+pIndex}
                                                            index={dayIndex-1}
                                                            data={period}
                                                            onChange={(newPeriod) => {
                                                                let newData = {...data};
                                                                newData.periods[pIndex] = newPeriod;
                                                                setData(newData);
                                                            }}
                                                            onAddClick={onAddClick}
                                                            onDeleteClick={onDeleteClick}
                                                        />
                                                    );
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default BusinessFormOpeningHours;
