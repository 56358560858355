import React, {useState, useCallback, useEffect} from "react";

import SimpleBar from "simplebar-react";
import {useAuth} from "../../../providers/AuthProvider";

import imgProfileTmp from 'modernize-essentials/dist/images/profile/user-1.jpg';
import imgIconAccount from 'modernize-essentials/dist/images/svgs/icon-account.svg';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import AccountTypesModel from "../../../models/AccountTypesModel";
import {useApi} from "../../../providers/ApiProvider";
import UsersModel from "../../../models/UsersModel";
import {useNotifications} from "../../../providers/NotificationsProvider";


function HeaderProfile(props) {
    const authContext = useAuth();
    const userData = authContext.user;
    const { t} = useTranslation();
    const apiContext = useApi();
    const accountTypesModel = new AccountTypesModel(apiContext.api);
    const usersModel = new UsersModel(apiContext.api);
    const [types, setTypes] = React.useState({});
    const notificationsContext = useNotifications();

    let imgProfile = imgProfileTmp;
    if (userData.avatar) {
        imgProfile = userData.avatar;
    }


    useEffect(() => {
        accountTypesModel.list().then((response) => {
            if (response !== false) {
                let newTypes = {
                    /*'free': t('Free'),
                    '1_profile': t('1 Profile'),
                    '3_profiles': t('{{count}} Profiles', {count: 3}),
                    '10_profiles': t('{{count}} Profiles', {count: 10}),
                    'admin': t('Administrator'),*/
                };
                response.map((type) => {
                    newTypes[type.id] = type.name;
                });
                setTypes(newTypes);
            }
        });
    }, []);


    const onClearCacheClick = (e) => {
        e.preventDefault();
            notificationsContext.notify(t('Requested cache refresh.'), 'warning');

        usersModel.clearCache().then((response) => {
            if (response !== false) {
                notificationsContext.notify(t('Cache cleared.'), 'success');
            }
        });
    }


    return <>
        <a className="nav-link pe-0" href="#" data-bs-toggle="dropdown" aria-expanded="false">
            <div className="d-flex align-items-center">
                <div className="user-profile-img d-flex">
                    <img src={imgProfile} className="rounded-circle" width="35" height="35" alt=""/>
                </div>
            </div>
        </a>
        <div className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up">
            <SimpleBar autoHide={true} className={'profile-dropdown position-relative'}>
                <div className="py-3 px-7 pb-0">
                    <h5 className="mb-0 fs-5 fw-semibold">{t('User Profile')}</h5>
                </div>
                <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                    <img src={imgProfile} className="rounded-circle" width="80" height="80" alt=""/>
                    <div className="ms-3">
                        <h5 className="mb-1 fs-3">{userData.name}</h5>
                        <span className="mb-1 d-block text-dark">{types[userData.type]}</span>
                        <p className="mb-0 d-flex text-dark align-items-center gap-2">
                            <i className="ti ti-mail fs-4"></i>
                            {userData.email}
                        </p>
                    </div>
                </div>
                <div className="message-body">
                    <Link to={'/settings/connected-accounts'} reloadDocument className="py-8 px-7 mt-8 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <img src={imgIconAccount} alt="" width="24" height="24"/>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-1 bg-hover-primary fw-semibold">{t('My Accounts')}</h6>
                            <span className="d-block text-dark">{t('Connected Google accounts')}</span>
                        </div>
                    </Link>
                </div>
                {userData.type === 'admin' && (
                <div className="message-body">
                    <a href="#" className="py-8 px-7 mt-8 d-flex align-items-center" onClick={onClearCacheClick}>
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className="ti ti-hourglass-low fs-5"></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-1 bg-hover-primary fw-semibold">{t('Clear Cache')}</h6>
                        </div>
                    </a>
                </div>)}
                <div className="d-grid py-4 px-7 pt-8">
                    <a href="/auth/logout" className="btn btn-outline-primary">{t('Log Out')}</a>
                </div>
            </SimpleBar>
        </div>
    </>
}

export default HeaderProfile;