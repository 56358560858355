import React, {useEffect} from 'react';
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import BusinessFormMap from "./BusinessFormMap";
import AddressAutocomplete from "../../ui/AddressAutocomplete";
import {useTranslation} from "react-i18next";
import BusinessFormHeadingProgress from "./BusinessFormHeadingProgress";
import ErrorsHelper from "../../../helpers/ErrorsHelper";

function BusinessFormLocation(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [latlng, setLatlng] = React.useState({latitude: '',longitude: ''});
    const [preciseLocationEditable, setPreciseLocationEditable] = React.useState(false);
    const [storefrontAddress, setStorefrontAddress] = React.useState({locality: '', postalCode: '', regionCode: '', addressLines: [], languageCode: '', administrativeArea: ''});
    const [secondLineShown, setSecondLineShown] = React.useState(false);
    const [saveLoading, setSaveLoading] = React.useState(false);


    useEffect(() => {
        setStorefrontAddress(props.storefrontAddress);
    }, [props.storefrontAddress]);
    useEffect(() => {
        if ((!props.latlng.latitude || !props.latlng.longitude) && props.placeId) {
            setPreciseLocationEditable(false);
            geocodePlaceId();
        } else if (props.latlng.latitude && props.latlng.longitude) {
            setLatlng(props.latlng);
            setPreciseLocationEditable(true);
        }
    }, [props.latlng, props.placeId]);


    const setAddressParts = (type, value) => {
        let addressParts = {...storefrontAddress};

        if (['locality', 'administrativeArea', 'postalCode'].indexOf(type) !== -1) {
            addressParts[type] = value;
        } else if (type === 'addressLine1') {
            if (addressParts.addressLines.length === 0) {
                addressParts.addressLines.push('');
            }
            addressParts.addressLines[0] = value;
        } else if (type === 'addressLine2') {
            if (addressParts.addressLines.length === 0) {
                addressParts.addressLines.push('');
                addressParts.addressLines.push('');
            } else if (addressParts.addressLines.length === 1) {
                addressParts.addressLines.push('');
            }

            addressParts.addressLines[1] = value;
        }

        setStorefrontAddress(addressParts);
    }


    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        let saveLatLng = false;
        if (props.latlng && props.latlng.latitude && props.latlng.longitude) {
            saveLatLng = true;
        }
        saveLocationInfo(saveLatLng);
    }


    const onSubmitError = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
        
        let shouldRerunWithoutLatLng = false;
        if (response.data && response.data.error && response.data.error.details) {
            response.data.error.details.forEach((error) => {
                if (error.reason === 'LAT_LNG_UPDATES_NOT_PERMITTED') {
                    shouldRerunWithoutLatLng = true;
                }
            });
        }

        if (shouldRerunWithoutLatLng) {
            notificationsContext.notify(t('You are not allowed to save precise location. Saving address...'), 'warning');
            saveLocationInfo(false);
        } else {
            notificationsContext.notify(t('Information change failed.'), 'error');
            setSaveLoading(false);
        }
    }


    const onCancel = (e) => {
        e.preventDefault();

        setStorefrontAddress(props.storefrontAddress);
        setLatlng(props.latlng);
        setEditMode(false);
    }


    const saveLocationInfo = (updateLatLng = true) => {
        let updatePromise;
        if (updateLatLng) {
            updatePromise = businessesModel.update(params.locationId, {latlng: latlng, storefrontAddress: storefrontAddress}, onSubmitError);
        } else {
            updatePromise = businessesModel.update(params.locationId, {storefrontAddress: storefrontAddress}, onSubmitError);
        }

        updatePromise.then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
                setEditMode(false);
                setSaveLoading(false);
            } else if (!updateLatLng) {
                notificationsContext.notify(t('Information change failed.'), 'error');
                setSaveLoading(false);
            }
        });
    }


    const formatAddress = () => {
        let formattedAddress = [storefrontAddress.addressLines.reverse().join(', '), storefrontAddress.locality, [storefrontAddress.administrativeArea, storefrontAddress.postalCode].join(' '), storefrontAddress.regionCode].filter((item, index) => {if (item) {return item}}).join(', ');

        if (formattedAddress === null || formattedAddress.trim().length === 0) {
            formattedAddress = t('No address set.');
        }

        return formattedAddress;
    }


    const onLocationChange = (latlng) => {
        setLatlng(latlng);
    }


    const processAutocompleteResponse = (type, place) => {
        let addressParts = {...storefrontAddress};

        if (type === 'addressLine1') {
            addressParts.addressLines[0] = place.name;
        }

        let fillableAddressParts = {};
        if (type === 'addressLine1') {
            fillableAddressParts = {
                'postalCode': 'postal_code',
                'locality': 'locality',
                'administrativeArea': 'administrative_area_level_1',
            };
        } else if (type === 'locality') {
            fillableAddressParts = {
                'locality': 'locality',
                'administrativeArea': 'administrative_area_level_1',
            };
        } else if (type === 'administrativeArea') {
            fillableAddressParts = {
                'administrativeArea': 'administrative_area_level_1',
            };
        }

        Object.entries(fillableAddressParts).forEach(([keyPart, addressPart]) => {
            if (place.address_components) {
                place.address_components.forEach((component) => {
                    if (component.types.indexOf(addressPart) !== -1) {
                        addressParts[keyPart] = component.short_name;
                    }
                });
            }
        });

        setStorefrontAddress(addressParts);
    }


    const geocodePlaceId = () => {
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({placeId: props.placeId})
            .then(({results}) => {
                if (results && results[0] && results[0].geometry && results[0].geometry.location) {
                    setLatlng({
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng()
                    });
                }
            });
    };


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <BusinessFormHeadingProgress
                score={props.score}
                editMode={editMode}
                title={t('Business location')}
                id="location"
            />

            {editMode === false && (
                <>
                    <div className="pt-2 pb-3">{formatAddress()}</div>

                    {latlng.latitude && latlng.longitude && (
                        <BusinessFormMap latlng={latlng} isEditable={false} />
                    )}
                </>
            )}
            {editMode === true && (
                <>
                <div className="fs-2 mb-3 mt-1">{t('If customers visit your business, add an address and adjust the pin on the map to its location.')}</div>

                <div className="row">
                    <div className="col-md-6 col-xl-6">
                        <div className="mt-3 mb-1 fs-2">{t('Street Address')}</div>
                        <AddressAutocomplete
                            value={storefrontAddress.addressLines[0] ? storefrontAddress.addressLines[0] : ''}
                            onChange={(place) => {
                                processAutocompleteResponse('addressLine1', place)
                            }}
                            types={['address']}
                            componentRestrictions={{country: storefrontAddress.regionCode}}
                        />

                        {(storefrontAddress.addressLines[1] || secondLineShown) && (
                            <input type="text" className="form-control my-2" id="cf-street2"
                                   value={(storefrontAddress.addressLines[1] ? storefrontAddress.addressLines[1] : '')} onChange={(e) => {
                                setAddressParts('addressLine2', e.target.value)
                            }}/>
                        ) || !(storefrontAddress.addressLines[1] && secondLineShown) && (
                            <button className={'btn btn-light-primary mb-1 mt-2 text-primary'} onClick={(e) => {
                                e.preventDefault();
                                setSecondLineShown(true);
                            }}>
                                <i className={'ti ti-plus fs-4 me-2'}></i>
                                {t('Add address line (optional)')}
                            </button>
                        )}

                        <div className="mt-3 mb-1 fs-2">{t('Town/City')}</div>
                        <AddressAutocomplete
                            value={storefrontAddress.locality ? storefrontAddress.locality : ''}
                            onChange={(place) => {
                                processAutocompleteResponse('locality', place)
                            }}
                            types={['(cities)']}
                            componentRestrictions={{country: storefrontAddress.regionCode}}
                        />

                        <div className={'mt-3 mb-1 d-flex gap-3'}>
                            <div className={'flex-grow-1'}>
                                <div className="fs-2">{t('Region')}</div>
                                <AddressAutocomplete
                                    value={storefrontAddress.administrativeArea ? storefrontAddress.administrativeArea : ''}
                                    onChange={(place) => {
                                        processAutocompleteResponse('administrativeArea', place)
                                    }}
                                    types={['(regions)']}
                                    componentRestrictions={{country: storefrontAddress.regionCode}}
                                    className={'my-2'}
                                />
                            </div>
                            <div style={{width: '150px'}}>
                                <div className="fs-2">{t('Postcode')}</div>
                                <input type="text" className="form-control my-2"
                                       value={storefrontAddress.postalCode ? storefrontAddress.postalCode : ''} onChange={(e) => {
                                    setAddressParts('postalCode', e.target.value)
                                }}/>
                            </div>
                        </div>


                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel}/>
                    </div>
                    {latlng && latlng.latitude && latlng.longitude && (
                    <div className="col-md-6 col-xl-6">
                        <div className="bordered-tip pb-4 pt-4">
                            <BusinessFormMap latlng={latlng} isEditable={preciseLocationEditable} onLocationChange={onLocationChange} />
                        </div>
                    </div>
                    )}
                </div>
                </>
            )}
        </div>
        </>
    );
}

export default BusinessFormLocation;
