import React, {useCallback, useEffect} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import BusinessViewMenu from "../BusinessViewMenu";
import BusinessesMediaModel from "../../../models/BusinessesMediaModel";
import {useDropzone} from 'react-dropzone'
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import {useTranslation} from "react-i18next";


export default function BusinessesMediaAddFile(props) {
    const { t} = useTranslation();
    const STATUS_PENDING = 'pending';
    const STATUS_UPLOADING = 'uploading';
    const STATUS_COMPLETE = 'complete';
    const STATUS_ERROR = 'error';

    const params = useParams();
    const apiContext = useApi();
    const mediaModel = new BusinessesMediaModel(apiContext.api);

    const [imgSrc, setImgSrc] = React.useState(null);
    const [uploadStatus, setUploadStatus] = React.useState(STATUS_PENDING);
    const [customErrorHint, setCustomErrorHint] = React.useState(null);


    useEffect(() => {
        const reader = new FileReader();

        reader.onload = function (e) {
            setImgSrc(e.target.result);
        };

        reader.readAsDataURL(props.file);
    }, []);


    useEffect(() => {
        if (props.performUpload) {
            performUpload();
        }
    }, [props.performUpload]);


    const performUpload = () => {
        setUploadStatus(STATUS_UPLOADING);
        setCustomErrorHint(null);

        mediaModel.upload(params.locationId, props.selectedCategory, [props.file], uploadErrorHandler).then((response) => {
            if (response !== false) {
                setUploadStatus(STATUS_COMPLETE);
                props.onPerformUpload(true);
            }
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            const errorDescription = response.data[0].error.details[0].errorDetails[0].message;

            setUploadStatus(STATUS_ERROR);
            setCustomErrorHint(errorDescription);
        }

        props.onPerformUpload(false);
    }


    const getProgressBg = () => {
        if (uploadStatus === STATUS_PENDING) {
            return 'bg-muted';
        }

        if (uploadStatus === STATUS_UPLOADING) {
            return 'bg-primary';
        }

        if (uploadStatus === STATUS_COMPLETE) {
            return 'bg-success';
        }

        if (uploadStatus === STATUS_ERROR) {
            return 'bg-danger';
        }
    }


    const getUploadHint = () => {
        if (uploadStatus === STATUS_PENDING) {
            return {
                className: 'text-muted',
                text: t('Ready for upload')
            };
        }

        if (uploadStatus === STATUS_UPLOADING) {
            return {
                className: 'text-primary',
                text: t('Uploading')
            };
        }

        if (uploadStatus === STATUS_COMPLETE) {
            return {
                className: 'text-success',
                text: t('Upload complete')
            };
        }

        if (uploadStatus === STATUS_ERROR) {
            return {
                className: 'text-danger',
                text: t('Upload error')
            };
        }
    }


    const getProgressWidth = () => {
        if (uploadStatus === STATUS_PENDING) {
            return '15px';
        }

        if (uploadStatus === STATUS_UPLOADING) {
            return '70%';
        }

        if (uploadStatus === STATUS_COMPLETE) {
            return '100%';
        }

        if (uploadStatus === STATUS_ERROR) {
            return '100%';
        }
    }


    return (
        <div className="d-flex flex-row gap-3 align-items-center justify-content-start fs-2 fw-semibold text-primary mb-2">
            <div className={'image-upload-thumbnail'}>
                {imgSrc && (
                    <img src={imgSrc} alt={''} />
                )}
            </div>
            <div className={'d-flex flex-column gap-2 flex-grow-1'}>
                <div>{props.file.name}</div>

                <div className="progress flex-grow-1 mt-n1">
                    <div className={'progress-bar progress-bar-striped progress-bar-animated '+getProgressBg()} style={{width: getProgressWidth(), height: '8px'}} role="progressbar"></div>
                </div>

                <div className={getUploadHint()['className']}>{getUploadHint()['text']}{customErrorHint ? '. '+customErrorHint : ''}</div>
            </div>
        </div>
    );
}
