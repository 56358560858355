import React, {useEffect} from 'react';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import BusinessFormSaveCancel from "./BusinessFormSaveCancel";
import {useTranslation} from "react-i18next";
import ErrorsHelper from "../../../helpers/ErrorsHelper";

function BusinessFormOpeningDate(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [editMode, setEditMode] = React.useState(false);
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();
    const [saveLoading, setSaveLoading] = React.useState(false);
    const [day, setDay] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [year, setYear] = React.useState('');


    useEffect(() => {
        formatAndSetData(props.data);
    }, [props.data]);


    const formatAndSetData = (input) => {
        if (input) {
            if (input.hasOwnProperty('year')) {setYear(input.year);}
            if (input.hasOwnProperty('month')) {setMonth(input.month);}
            if (input.hasOwnProperty('day')) {setDay(input.day);}
        } else {
            setYear('');
            setMonth('');
            setDay('');
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();

        if (!validateYear() || !validateMonth() || !validateDay()) {
            notificationsContext.notify(t('Please fill out all the required fields.'), 'error');
            return false;
        }

        setSaveLoading(true);

        businessesModel.update(params.locationId, {'openInfo.openingDate': {day: day, month: month, year: year}}, saveErrorHandler).then((response) => {
            if (response !== false) {
                props.onUpdate(response);

                notificationsContext.notify(t('Information saved successfully.'), 'success');
                setEditMode(false);
            }

            setSaveLoading(false);
        });
    }


    const saveErrorHandler = (response) => {
        ErrorsHelper.displayError(response, notificationsContext, t);
    }


    const onCancel = (e) => {
        e.preventDefault();

        formatAndSetData(props.data);
        setEditMode(false);
    }


    const validateYear = () => {
        if (year === '' && month !== '') {return false;} else if (month === '' && year === '') {return true;}

        const yearInt = parseInt(year);
        if (isNaN(yearInt) || yearInt < 1000 || yearInt > 9999) {return false;}

        return true;
    }
    const validateMonth = () => {
        if (month === '' && year !== '') {return false;} else if (month === '' && year === '') {return true;}

        const monthInt = parseInt(month);
        if (isNaN(monthInt) || monthInt < 1 || monthInt > 12) {return false;}

        return true;
    }
    const validateDay = () => {
        if (day === null || day === '') {return true;}

        const dayInt = parseInt(day);
        if (isNaN(dayInt) || dayInt < 1 || dayInt > 31) {return false;}

        return true;
    }


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <div className="d-flex justify-content-between">
                <label htmlFor="cf-title" className="align-items-center d-flex form-label fw-semibold gap-2 justify-content-start mb-0">
                    {t('Opening date')}
                    <span className={'badge bg-light-info font-medium fs-2 fw-semibold text-info d-none'}>7/10</span>
                    <i className={'fs-5 ti ti-pencil d-editable text-muted'+(editMode?' d-none':'')}></i>
                </label>

                <div className={'align-items-center justify-content-end w-50 d-none'}>
                    <div className="progress w-100">
                        <div className="progress-bar progress-bar-striped bg-warning progress-bar-animated" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: '70%'}}></div>
                        <div className="progress-bar progress-bar-animated bg-body-secondary" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" style={{width: '30%'}}></div>
                    </div>
                    <span className={'fs-2 fw-semibold ms-3'}>7/10</span>
                </div>
            </div>
            {editMode === false && (
                <div className="pt-2">
                {year && month && day && (
                    [day, month, year].map((item, index) => {
                        if (index < 2 && item) {
                            return ('0'+item).slice(-2);
                        } else {
                            return item;
                        }
                    }).join('.')
                ) || year && month && !day && (
                    (new Intl.DateTimeFormat(props.locale, {
                        year: 'numeric',
                        month: 'long',
                    })).format(new Date(parseInt(year), parseInt(month) - 1))
                ) || (!year || !month) && (
                    t('No date specified.')
                )}
                </div>
            )}
            {editMode === true && (
                <>
                <div className="fs-2 mb-3 mt-1">{t('Add the date that you opened or will open at this address.')}</div>

                <div className="row">
                    <div className="col-md-6 col-xl-6">
                        <div className="row">
                            <div className="col-md-4">
                                <input type="text" className={'form-control'+((!validateYear())?(' is-invalid'):(''))} placeholder={t('Year *')} value={year} onChange={(e) => {setYear(e.target.value)}} />
                            </div>
                            <div className="col-md-4">
                                <select className={'form-control'+((!validateMonth())?(' is-invalid'):(''))} value={month} onChange={(e) => {setMonth(e.target.value)}}>
                                    <option value={''}>{t('Month *')}</option>
                                    <option value={''}>{t('None')}</option>
                                    {Array.from(Array(12).keys()).map((month, index) => {
                                        return (
                                            <option key={index} value={month+1}>{format(new Date(2021, month, 1), 'MMMM')}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <select className={'form-control'+((!validateDay())?(' is-invalid'):(''))} value={day} onChange={(e) => {setDay(e.target.value)}}>
                                    <option value={''} disabled={true}>{t('Day')}</option>
                                    <option value={''}>{t('None')}</option>
                                    {Array.from(Array(31).keys()).map((day, index) => {
                                        return (
                                            <option key={index} value={day+1}>{day+1}</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel} />
                    </div>
                </div>
                </>
            )}
        </div>
        </>
    );
}

export default BusinessFormOpeningDate;
