import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useApi} from "../../providers/ApiProvider";
import BusinessesModel from "../../models/BusinessesModel";
import {useNotifications} from "../../providers/NotificationsProvider";
import BusinessFormSaveCancel from "./formElements/BusinessFormSaveCancel";
import {useTranslation} from "react-i18next";

export default function BusinessTerminate({openInfo}) {
    const STATUS_UNSPECIFIED = 'OPEN_FOR_BUSINESS_UNSPECIFIED';
    const STATUS_OPEN = 'OPEN';
    const STATUS_CLOSED_PERMANENTLY = 'CLOSED_PERMANENTLY';
    const STATUS_CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY';
    const allStatuses = [STATUS_UNSPECIFIED, STATUS_OPEN, STATUS_CLOSED_PERMANENTLY, STATUS_CLOSED_TEMPORARILY];

    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const businessesModel = new BusinessesModel(apiContext.api);
    const notificationsContext = useNotifications();

    const [editMode, setEditMode] = React.useState(false);
    const [saveLoading, setSaveLoading] = React.useState(false);
    const [status, setStatus] = React.useState(STATUS_UNSPECIFIED);


    useEffect(() => {
        if (openInfo && allStatuses.includes(openInfo.status)) {
            setStatus(openInfo.status);
        }
    }, [openInfo]);


    const getStatusLabel = (status) => {
        switch (status) {
            case STATUS_UNSPECIFIED:
                return t('...');
            case STATUS_OPEN:
                return t('Open');
            case STATUS_CLOSED_PERMANENTLY:
                return t('Closed Permanently');
            case STATUS_CLOSED_TEMPORARILY:
                return t('Closed Temporarily');
            default:
                return t('Unspecified');
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();

        setSaveLoading(true);

        businessesModel.update(params.locationId, {'openInfo.status': status}).then((response) => {
            if (response !== false) {
                notificationsContext.notify(t('Information saved successfully.'), 'success');
                setEditMode(false);
            } else {
                notificationsContext.notify(t('Information change failed.'), 'error');
            }
        }).finally(() => {
            setSaveLoading(false);
        });
    }


    const onCancel = (e) => {
        e.preventDefault();

        if (openInfo && allStatuses.includes(openInfo.status)) {
            setStatus(openInfo.status);
        }
        setEditMode(false);
    }


    return (
        <>
        <div className={'py-2'+(editMode?'':' editable')} onClick={(e) => {e.preventDefault(); !editMode && setEditMode(true)}}>
            <div className="d-flex justify-content-between">
                <label htmlFor="cf-title" className="align-items-center d-flex form-label fw-semibold gap-2 justify-content-start mb-0">
                    {t('Business Status')}
                    <i className={'fs-5 ti ti-pencil d-editable text-muted'+(editMode?' d-none':'')}></i>
                </label>
            </div>
            {editMode === false && (
                <div className="pt-2">
                {getStatusLabel(status)}
                </div>
            )}
            {editMode === true && (
                <>
                <div className="fs-2 mb-3 mt-1">{t('Add the status of your business.')}</div>

                <div className="row">
                    <div className="col-md-6 col-xl-6">
                        <select className={'form-control form-select'} value={status} onChange={(e) => {
                            setStatus(e.target.value)
                        }}>
                            <option value={STATUS_OPEN}>{getStatusLabel(STATUS_OPEN)}</option>
                            <option value={STATUS_CLOSED_TEMPORARILY}>{getStatusLabel(STATUS_CLOSED_TEMPORARILY)}</option>
                            <option value={STATUS_CLOSED_PERMANENTLY}>{getStatusLabel(STATUS_CLOSED_PERMANENTLY)}</option>
                        </select>

                        <BusinessFormSaveCancel saveLoading={saveLoading} onSubmit={onSubmit} onCancel={onCancel} />
                    </div>
                </div>
                </>
            )}
        </div>
        </>
    );
}