import * as React from "react";


const breadcrumbsStructure = {
    title: '',
    breadcrumbs: [],
    update: () => {}
}

const BreadcrumbsContext = React.createContext(breadcrumbsStructure);


function BreadcrumbsProvider({children}) {
    let [breadcrumbs, setBreadcrumbs] = React.useState(breadcrumbsStructure);


    const updateBreadcrumbs = (newStructure) => {
        let newBreadcrumbs = JSON.parse(JSON.stringify(breadcrumbs));

        if (newStructure.hasOwnProperty('title') && newBreadcrumbs.title !== newStructure.title) {newBreadcrumbs.title = newStructure.title;}
        if (newStructure.hasOwnProperty('breadcrumbs') && newBreadcrumbs.breadcrumbs !== newStructure.breadcrumbs) {newBreadcrumbs.breadcrumbs = newStructure.breadcrumbs;}

        setBreadcrumbs(newBreadcrumbs);
    }


    let value = JSON.parse(JSON.stringify(breadcrumbs));
    value.update = updateBreadcrumbs;

    return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
}


function useBreadcrumbs() {
    return React.useContext(BreadcrumbsContext);
}


export {BreadcrumbsProvider, useBreadcrumbs};