/**
 * Businesses model
 *
 * @param {ApiHelper} api
 */
class BusinessesModel {
    constructor(api) {
        this.api = api;
    }


    async getTemporaryAccess(locationId, errorHandler) {
        const url = '/businesses/' + locationId + '/temporary-access';

        const response = await this.api.postRequest(url, {access: 123});

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    getLabels(fields, errorHandler) {
        const labels = {
            name: 'Name',
            last_synced: 'Last Synced'
        };

        let results = [];
        for (let i = 0; i < fields.length; i++) {
            results.push(labels[fields[i]]);
        }

        return results;
    }


    async list(errorHandler) {
        const url = '/businesses/get-all';

        const response = await this.api.getRequest(url);

        let results = [];
        if (response.success === true && response.data) {
            results = response.data;
        }

        return results;
    }


    async listFresh(errorHandler) {
        const url = '/businesses/get-all-fresh';

        const response = await this.api.getRequest(url);

        let results = [];
        if (response.success === true && response.data) {
            results = response.data;
        }

        return results;
    }


    async toggleLink(locationId, accountEmail, status, errorHandler) {
        const url = '/businesses/toggle-link/' + locationId;

        const response = await this.api.postRequest(url, {
            account_email: accountEmail,
            status: status
        });

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async generate(locationId, reviewId, errorHandler) {
        const url = '/businesses/' + locationId + '/generate';
        const data = {
            review_id: reviewId
        };

        const response = await this.api.postRequest(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async addReviewsQueue(locationId, reviewIds, errorHandler) {
        const url = '/businesses/' + locationId + '/reviews-queue';
        const data = {
            review_ids: reviewIds
        };

        const response = await this.api.postRequest(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async getReviewsQueue(locationId, errorHandler) {
        const url = '/businesses/' + locationId + '/reviews-queue';

        const response = await this.api.getRequest(url);

        let results = [];
        if (response.success === true && response.data) {
            results = response.data;
        }

        return results;
    }


    async getReviews(locationId, errorHandler) {
        const url = '/businesses/' + locationId + '/reviews';

        const response = await this.api.getRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async getUnansweredReviews(locationId, errorHandler) {
        const url = '/businesses/' + locationId + '/reviews?unanswered=true';

        const response = await this.api.getRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async getReviewInfo(locationId, reviewId, errorHandler) {
        const url = '/businesses/' + locationId + '/reviews/' + reviewId;

        const response = await this.api.getRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async reviewReply(locationId, reviewId, replyText, errorHandler) {
        const url = '/businesses/' + locationId + '/reviews/' + reviewId+'/reply';

        const response = await this.api.postRequest(url, {data: replyText});

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async reviewReplyDelete(locationId, reviewId, errorHandler) {
        const url = '/businesses/' + locationId + '/reviews/' + reviewId+'/delete-reply';

        const response = await this.api.deleteRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async view(pk, lang, errorHandler) {
        let url = '/businesses/' + pk;
        if (lang) {
            url += '?lang=' + lang;
        }

        const response = await this.api.getRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async create(data, errorHandler) {
        const url = '/businesses/create';

        const response = await this.api.postRequest(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async update(pk, data, errorHandler) {
        const url = '/businesses/' + pk;

        const response = await this.api.postRequest(url, {'data': data});

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async delete(pk, errorHandler) {
        const url = '/businesses/' + pk + '/delete';

        const response = await this.api.deleteRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async refresh(pk, errorHandler) {
        const url = '/businesses/' + pk + '/refresh';

        const response = await this.api.postRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async autocompleteCategories(pk, categoryPart, errorHandler) {
        const url = '/businesses/' + pk + '/autocomplete-categories';

        const data = {
            category_part: categoryPart
        };

        const response = await this.api.postRequest(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async suggestCategories(pk, primaryCategory, secondaryCategories, errorHandler) {
        const url = '/businesses/' + pk + '/suggest-categories';

        const data = {
            primary_category: primaryCategory,
            secondary_categories: secondaryCategories
        };

        const response = await this.api.postRequest(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async suggestDescription(pk, errorHandler) {
        const url = '/businesses/' + pk + '/suggest-description';

        const response = await this.api.postRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async insights(pk, dateStart, dateEnd, errorHandler) {
        let url = '/businesses/' + pk + '/insights';

        let params = [];
        if (dateStart) {
            params.push('date_start=' + dateStart);
        }
        if (dateEnd) {
            params.push('date_end=' + dateEnd);
        }
        if (params.length > 0) {
            url += '?' + params.join('&');
        }

        const response = await this.api.getRequest(url);
        
		if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async ightsSearchKeywords(pk, dateStart, dateEnd, errorHandler) {
        let url = '/businesses/' + pk + '/ightsSearchKeywords';

        let params = [];
        if (dateStart) {
            params.push('date_start=' + dateStart);
        }
        if (dateEnd) {
            params.push('date_end=' + dateEnd);
        }
        if (params.length > 0) {
            url += '?' + params.join('&');
        }

        const response = await this.api.getRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async disconnect(pk, errorHandler) {
        const url = '/businesses/' + pk + '/disconnect';

        const response = await this.api.deleteRequest(url);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }
}

export default BusinessesModel;