import React from 'react';
import {useTranslation} from "react-i18next";

function BusinessesPostAddStandard(props) {
    const { t} = useTranslation();

    return (
        <>
        <div className={'form-group py-2'}>
            <label htmlFor="cf-summary" className="form-label fw-semibold">{t('Summary')}</label>
            <textarea id={'cd-summary'} className={'form-control'} rows={10} defaultValue={props.data.summary}  onChange={(e) => {props.onFieldChange('summary', e.target.value)}}></textarea>
            <div className="d-flex justify-content-between">
                {props.errors.summary && <div className="form-text text-danger">{props.errors.summary.join('. ')}</div>}
                <div className="text-end flex-grow-1"><small className="form-text text-muted">{props.data.summary ? props.data.summary.length : 0} / 1500</small></div>
            </div>
        </div>

        <div className={'form-group py-2'}>
            <label htmlFor="cf-button" className="form-label fw-semibold">{t('Add a button')} {((props.data.topicType !== 'STANDARD')?('(optional)'):(''))}</label>
            <div className="row">
                <div className="col-md-6">
                    <select id={'cd-button'} className={'form-control form-select'} value={props.data.callToAction ? props.data.callToAction.actionType : ''} onChange={(e) => {props.onFieldChange('callToAction.actionType', e.target.value)}}>
                        <option value={'ACTION_TYPE_UNSPECIFIED'}>{t('None')}</option>
                        <option value={'BOOK'}>{t('Book')}</option>
                        <option value={'ORDER'}>{t('Order online')}</option>
                        <option value={'SHOP'}>{t('Buy')}</option>
                        <option value={'LEARN_MORE'}>{t('Learn more')}</option>
                        <option value={'SIGN_UP'}>{t('Sign up')}</option>
                        <option value={'CALL'}>{t('Call now')}</option>
                    </select>
                    {props.errors['call_to_action.action_type'] && <div className="form-text text-danger">{props.errors['call_to_action.action_type'].join('. ')}</div>}

                    <div className={'mt-3'+((props.data.callToAction && props.data.callToAction.actionType && !['ACTION_TYPE_UNSPECIFIED', 'CALL'].includes(props.data.callToAction.actionType))?'':' d-none')}>
                        <input type="text" className={'form-control'} defaultValue={props.data.callToAction ? props.data.callToAction.url : ''} onChange={(e) => {props.onFieldChange('callToAction.url', e.target.value)}} placeholder={t('Link for your button *')} />

                        {props.errors['call_to_action.url'] && <div className="form-text text-danger">{props.errors['call_to_action.url'].join('. ')}</div>}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default BusinessesPostAddStandard;
