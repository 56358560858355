import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import BusinessesPostsModel from "../../../models/BusinessesPostsModel";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import BusinessesModel from "../../../models/BusinessesModel";
import BusinessViewHeader from "../BusinessViewHeader";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import TableEditableRow from "../../ui/TableEditableRow";
import BusinessViewMenu from "../BusinessViewMenu";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../../providers/TranslationProvider";


function BusinessesPosts(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const params = useParams();
    const apiContext = useApi();
    const bpModel = new BusinessesPostsModel(apiContext.api);
    const businessesModel = new BusinessesModel(apiContext.api);


    const breadcrumbs = useBreadcrumbs();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [deleteProcessing, setDeleteProcessing] = React.useState(false);
    const [syncLoading, setSyncLoading] = React.useState(false);


    const onDelete = (pk) => {
        setDeleteProcessing(pk);

        bpModel.delete(params.locationId, pk).then((response) => {
            if (response !== false) {
                let newData = data.filter((item) => {
                    return item.id !== pk;
                });

                setData(newData);
                setDeleteProcessing(false);
            }
        });
    }


    const onSyncClick = () => {
        setSyncLoading(true);
        bpModel.sync(params.locationId).then((response) => {
            if (response !== false) {
                setData(response);
            }
        }).finally(() => {
            setSyncLoading(false);
        });
    }


    const onProfileRefresh = (profile, lastUpdated) => {
        return true;
    }


    useEffect(() => {
        businessesModel.view(params.locationId).then((response) => {
            if (response !== false) {
                breadcrumbs.update({
                    title: response.data.title,
                    breadcrumbs: [
                        {label: t('Businesses List'), url: '/businesses'},
                        {label: response.data.title, url: '/businesses/'+params.locationId},
                        {label: t('Publications')}
                    ]
                });

                document.title = t('Publications')+' | '+response.data.title+' | LocalBoost';
            }
        });


        bpModel.list(params.locationId).then((response) => {
            if (response !== false) {
                setData(response);
                setLoading(false);
            }
        });
    }, [language]);


    const getTopicTypeLabel = (topicType) => {
        const topicTypes = {
            'STANDARD': t('Standard post'),
            'EVENT': t('Event'),
            'OFFER': t('Offer'),
            'ALERT': t('Alert')
        }

        return topicTypes[topicType] || t('Standard post');
    }


    const getStateColor = (state) => {
        const colors = {
            'LOCAL_POST_STATE_UNSPECIFIED': 'primary',
            'REJECTED': 'danger',
            'PROCESSING': 'warning',
            'LIVE': 'success'
        }

        return colors[state] || 'primary';
    }


    return (
        <>
        <BusinessViewHeader
            locationId={params.locationId}
            onProfileRefresh={onProfileRefresh}
        />

        <BusinessViewMenu locationId={params.locationId} active="publications" />

        <SimpleCardContainer title={t('Publications')}>
            <div className="d-flex justify-content-between pb-3">
                <Link to={'/businesses/'+params.locationId+'/publications/create'} className="align-items-center justify-content-center btn btn-primary">
                    <i className="fs-4 ti ti-plus me-2"></i> {t('Create a new Publication')}
                </Link>
                <ButtonWithLoading
                    className="text-primary btn-light-primary"
                    iconClassName="ti-refresh"
                    label="Sync with Google"
                    loading={syncLoading}
                    onClick={onSyncClick}
                />
            </div>

            {data.length ? (
            <div className="table-responsive rounded-2">
                <table className="table border text-nowrap customize-table mb-0 align-middle">
                    <thead className="text-dark fs-4">
                    <tr>
                        <th style={{width: '240px'}}><h6 className="fs-4 fw-semibold mb-0">{t('Published')}</h6></th>
                        <th><h6 className="fs-4 fw-semibold mb-0">{t('Topic type')}</h6></th>
                        <th><h6 className="fs-4 fw-semibold mb-0">{t('Text')}</h6></th>
                        <th><h6 className="fs-4 fw-semibold mb-0">{t('Status')}</h6></th>
                        <th style={{width: '240px'}}><h6 className="fs-4 fw-semibold mb-0"></h6></th>
                    </tr>
                    </thead>
                    <tbody>

                    <TableEditableRow
                        columns={4}
                        loading={loading}
                    />
                    {data.map((publication, index) => {
                        const dateCreated = new Date(publication.publish_time);

                        let words = (publication.data && publication.data.summary) ? publication.data.summary.split(' ') : [],
                            summaryPreview = '';
                        if (words.length > 10) {
                            summaryPreview = words.slice(0, 10).join(' ')+'...';
                        } else if (words.length > 0) {
                            summaryPreview = publication.data.summary;
                        }

                        return (
                            <tr key={index}>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <Link to={'/businesses/' + params.locationId + '/publications/' + publication.id}>
                                            {dateCreated.toLocaleString()}
                                        </Link>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        {getTopicTypeLabel(publication.data.topicType)}
                                    </div>
                                </td>
                                <td className="text-wrap">{summaryPreview && (summaryPreview) || !summaryPreview && (
                                    <span className="text-muted">{t('No text.')}</span>
                                )}</td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <span
                                            className={'px-3 py-1 bg-light-' + getStateColor(publication.data.state) + ' text-' + getStateColor(publication.data.state)}>{publication.data.state ? publication.data.state : publication.status}</span>
                                    </div>
                                </td>
                                <td className="d-flex justify-content-end gap-3">
                                    <Link to={'/businesses/' + params.locationId + '/publications/' + publication.id}
                                          className="align-items-center justify-content-center btn waves-effect waves-light btn-light-primary text-primary">
                                        <i className="fs-4 ti ti-news me-2"></i>
                                        {t('Show Post')}
                                    </Link>

                                    <Link to={'/businesses/' + params.locationId + '/publications/' + publication.id + '/edit'}
                                          className={'align-items-center justify-content-center btn btn-light-primary text-primary'}>
                                        <i className={'fs-4 ti ti-pencil'}></i>
                                    </Link>

                                    <button onClick={() => {
                                        onDelete(publication.id)
                                    }} className="align-items-center justify-content-center btn waves-effect waves-light btn-light-danger text-danger">
                                        {deleteProcessing === publication.id && (
                                            <span
                                                className={'spinner-border spinner-border-sm'}
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) || deleteProcessing !== publication.id && (
                                            <i className="fs-4 ti ti-trash text-danger"></i>
                                        )}
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            ) : (
                <p className={'fw-semibold fs-2'}>{t('There are no publications yet.')}</p>
            )}
        </SimpleCardContainer>
        </>
    );
}

export default BusinessesPosts;
