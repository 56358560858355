import React, {useEffect, useRef} from 'react';

import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function BusinessFormServiceTextInput({className, attribute, onChange, editMode}) {
    const {t} = useTranslation();
    const uid = useRef(Math.random().toString(36).substring(7));

    const [values, setValues] = React.useState(attribute.values ? [...attribute.values] : []);
    useEffect(() => {
        setValues(attribute.values ? [...attribute.values] : []);
    }, [attribute.values]);


    const onValueChange = (value, index) => {
        let newValues = values ? [...values] : [];
        if (!newValues[index]) {newValues[index] = {uri: value};} else {newValues[index]['uri'] = value;}

        setValues(newValues);
        onChange(newValues);
    }


    const onAddMoreLinks = (e) => {
        e.preventDefault();

        let newValues = values ? [...values] : [];
        newValues.push({uri: ''});

        setValues(newValues);
    }


    const removeValue = (index) => {
        let newValues = values ? [...values] : [];
        newValues.splice(index, 1);

        setValues(newValues);
        onChange(newValues);
    }


    const getTiIconClass = () => {
        switch (attribute.parent) {
            case 'attributes/url_facebook': return 'ti-brand-facebook';
            case 'attributes/url_twitter': return 'ti-brand-twitter';
            case 'attributes/url_instagram': return 'ti-brand-instagram';
            case 'attributes/url_linkedin': return 'ti-brand-linkedin';
            case 'attributes/url_youtube': return 'ti-brand-youtube';
            case 'attributes/url_pinterest': return 'ti-brand-pinterest';
            case 'attributes/url_tiktok': return 'ti-brand-tiktok';
        }

        return 'ti-world';
    }


    return (editMode || values && values.length) ? (
        <div className={className}>
            <label htmlFor={'service-' + uid.current} className={'align-items-center d-flex' + (editMode ? ' mb-2' : '')}><i className={'ti '+getTiIconClass()+' fs-4 me-2'} style={{marginTop: '1px'}}></i>{attribute.displayName}</label>
            {editMode && (attribute.repeatable ? (<>
                    {(values.length > 1) ? values.map((value, index) => {
                        return (
                            <div className="d-flex mb-2" key={uid.current + '-' + index}>
                                <input type="text" id={'service-' + uid.current + '-' + index} name={'service-' + uid.current + '-' + index}
                                       value={values[index].uri}
                                       onChange={(e) => {
                                           onValueChange(e.target.value, index)
                                       }} className="form-control flex-grow-1"/>
                                <button className="btn btn-light-danger text-danger ms-2" onClick={(e) => {
                                    removeValue(index)
                                }}><i className={'ti ti-x'}></i></button>
                            </div>
                        );
                    }) : (
                        <input type="text" id={'service-' + uid.current} name={'service-' + uid.current} value={values.length ? values[0].uri : ''} onChange={(e) => {
                            onValueChange(e.target.value, 0)
                        }} className="form-control mb-2"/>
                    )}
                    <button className="btn btn-light-primary text-primary mb-2" onClick={onAddMoreLinks}>{t('Add a new link')}</button>
                </>) : (
                    <input type="text" id={'service-' + uid.current} name={'service-' + uid.current} value={values.length ? values[0].uri : ''} onChange={(e) => {
                        onValueChange(e.target.value, 0)
                    }} className="form-control mb-2"/>
                )
            )}

            {!editMode && (
                <div className="p-small-margins">
                    {values.length ? values.map((value, index) => {
                        return (
                            <p key={uid.current + '-' + index} className=""><Link to={value.uri} target="_blank">{value.uri}</Link></p>
                        );
                    }) : ('')}
                </div>
            )}
        </div>
    ) : ('');
}
