import React, {useEffect} from 'react';
import StarRating from "../../ui/StarRating";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import StrHelper from "../../../helpers/StrHelper";

function BusinessesReviewCard(props) {
    const { t} = useTranslation();
    const params = useParams();
    const [reviewer, setReviewer] = React.useState({});
    const [dateCreated, setDateCreated] = React.useState(null);
    const [reviewLink, setReviewLink] = React.useState('');


    useEffect(() => {
        setReviewer(props.data.reviewer);
        setDateCreated(new Date(props.data.createTime));

        if (props.data.reviewId) {
            setReviewLink('/businesses/' + params.locationId + '/reviews/'+props.data.reviewId+'/reply');
        }
    }, []);


    let starRatingNumeric = 0;
    if (props.data) {
        switch (props.data.starRating) {
            case 'ONE': starRatingNumeric = 1; break;
            case 'TWO': starRatingNumeric = 2; break;
            case 'THREE': starRatingNumeric = 3; break;
            case 'FOUR': starRatingNumeric = 4; break;
            case 'FIVE': starRatingNumeric = 5; break;
        }
    }

    return (
        <div className="card w-100 position-relative overflow-hidden">
            <div className="card-body p-3">
                <div className="d-flex flex-row flex-md-column flex-lg-row comment-row">
                    <div className="pe-3">
                        <Link to={reviewLink} className="">
                            <img src={reviewer.profilePhotoUrl} className="rounded-circle" alt={reviewer.displayName} width="50" />
                        </Link>
                    </div>
                    <div className="comment-text w-100 pt-1">
                        <h5 className="font-weight-medium mb-0">
                            <Link to={reviewLink} className="text-dark">
                                {reviewer.displayName}
                            </Link>
                        </h5>

                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <StarRating rating={starRatingNumeric} className="mb-0" />

                            {props.data.reviewReply && (
                            <span className="badge bg-light-success text-success rounded-pill font-weight-medium fs-1">
                                <i className="ti ti-checks me-2 fs-2"></i>
                                {t('Replied')}
                            </span>
                            ) || !props.data.reviewReply && (
                            <span className="badge bg-light-warning text-warning rounded-pill font-weight-medium fs-1">
                                <i className="ti ti-exclamation-circle me-2 fs-2"></i>
                                {t('Not Replied')}
                            </span>
                            )}

                        </div>

                        <div className="text-muted ms-auto fw-normal fs-2 mb-2">
                            {dateCreated && dateCreated.toLocaleString()}
                        </div>

                        <div className="fs-3 text-muted">
                            {props.data.comment && (
                                StrHelper.filterGoogleTranslatedText(props.data.comment).split(' ').map((word, wordIndex, words) => (
                                    (((wordIndex < 10)?(word+' '):('')) + ((wordIndex === 10 && words.length > 10)?('...'):('')))
                                ))
                            ) || !props.data.comment && (
                                <span className="text-muted">{t('No text.')}</span>
                            )}
                        </div>

                        <div className={'mt-3'}>
                            <Link to={reviewLink} className="align-items-center justify-content-center btn btn-sm waves-effect waves-light btn-light-primary text-primary">
                                <i className="fs-2 ti ti-message-2-share me-2"></i>{t('Go to the Review')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BusinessesReviewCard;
