import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {isGermanPrioritizedOverEnglish} from "./providers/TranslationProvider";
import enTranslation from "./i18n/en.json";
import deTranslation from "./i18n/de.json";


const resources = {
    en: {
        translation: enTranslation
    },
    de: {
        translation: deTranslation
    }
};

let storedLanguage = localStorage.getItem('language');
if (!storedLanguage) {
    if (isGermanPrioritizedOverEnglish()) {
        storedLanguage = 'de';
    } else {
        storedLanguage = 'en';
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: storedLanguage, // 'en' | 'de'
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;