/**
 * Businesses model
 *
 * @param {ApiHelper} api
 */
class ReviewsRulesModel {
    constructor(api) {
        this.api = api;
    }


    async list(locationId, errorHandler) {
        const url = '/reviews-rules/' + locationId + '/get-all';

        const response = await this.api.getRequest(url);

        let results = [];
        if (response.success === true && response.data) {
            results = response.data;
        }

        return results;
    }


    async toggleActive(locationId, data) {
        const url = '/reviews-rules/' + locationId + '/toggle-active';

        const response = await this.api.postRequest(url, data);

        let results = [];
        if (response.success === true && response.data) {
            results = response.data;
        }

        return results;
    }


    async setRule(locationId, data) {
        const url = '/reviews-rules/' + locationId + '/set-rule';

        const response = await this.api.postRequest(url, data);

        let results = [];
        if (response.success === true && response.data) {
            results = response.data;
        }

        return results;
    }


    async generateResponse(locationId, data) {
        const url = '/reviews-rules/' + locationId + '/generate-response';

        const response = await this.api.postRequest(url, data);

        let results = [];
        if (response.success === true && response.data) {
            results = response.data;
        }

        return results;
    }


    async getLatestActivity(page = 1) {
        const url = '/reviews-rules/get-latest-activity?page=' + page;

        const response = await this.api.getRequest(url);

        let results = [];
        if (response.success === true && response.data) {
            results = response.data;
        }

        return results;
    }
}

export default ReviewsRulesModel;