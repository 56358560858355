import React, {useEffect} from 'react';

import MainLayout from "../layout/MainLayout";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import TableEditable from "../ui/TableEditable";
import {useApi} from "../../providers/ApiProvider";
import UsersModel from "../../models/UsersModel";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import UserSearchForm from "./UserSearchForm";
import AccountTypesModel from "../../models/AccountTypesModel";
import TableEditableRow from "../ui/TableEditableRow";
import {Link} from "react-router-dom";
import Pagination from "../ui/Pagination";

export default function UsersList(props) {
    const {language} = useMyTranslation();
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const usersModel = new UsersModel(apiContext.api);
    const accountTypesModel = new AccountTypesModel(apiContext.api);
    const { t} = useTranslation();


    const getDefaultSorting = () => {
        let sorting = {
            sort: 'created_at',
            direction: 'desc',
            type: null,
            search: null,
            page: 1
        };

        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('sort') && urlParams.get('direction')) {
            sorting.sort = urlParams.get('sort');
            sorting.direction = urlParams.get('direction');
        }
        if (urlParams.get('type') && ['free', 'paid'].includes(urlParams.get('type'))) {
            sorting.type = urlParams.get('type');
        }
        if (urlParams.get('search')) {
            sorting.search = urlParams.get('search');
        }
        if (urlParams.get('page')) {
            sorting.page = urlParams.get('page');
        }

        return sorting;
    }
    const fields = ['name', 'email', 'type', 'businesses_count'];
    const headerItems = usersModel.getLabels(fields);
    const [dataItems, setDataItems] = React.useState([]);
    const [sorting, setSorting] = React.useState(getDefaultSorting());
    const [loading, setLoading] = React.useState(true);
    const [types, setTypes] = React.useState({});
    const [totalPages, setTotalPages] = React.useState(0);


    const onSearch = (searchText) => {
        let newSorting = {...sorting};
        newSorting.search = searchText;
        newSorting.page = 1;
        setSorting(newSorting);
    }


    const onSort = (e) => {
        let sortField = e.target.getAttribute('data-sort');
        let sortDirection = 'asc';

        if (e.target.classList.contains('asc')) {
            e.target.classList.remove('asc');
            e.target.classList.add('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
                e.target.querySelector('i.ti').classList.add('ti-sort-descending');
            }

            sortDirection = 'desc';
        } else {
            e.target.classList.remove('desc');
            e.target.classList.add('asc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-descending');
                e.target.querySelector('i.ti').classList.add('ti-sort-ascending');
            }
        }

        setSorting({...sorting, sort: sortField, direction: sortDirection});
    }


    const onDelete = (pk) => {
        const url = '/users/' + pk + '/delete';

        usersModel.delete(pk).then((response) => {
            if (response !== false) {
                window.location.href = '/users';
            }
        });
    }


    useEffect(() => {
        accountTypesModel.list().then((response) => {
            if (response !== false) {
                let newTypes = {};
                response.map((type) => {
                    newTypes[type.id] = type.name;
                });
                setTypes(newTypes);
            }
        });
    }, []);


    useEffect(() => {
        breadcrumbs.update({
            title: t('Users'),
            breadcrumbs: []
        });

        document.title = t('Users')+' | LocalBoost';
    }, [language]);


    useEffect(() => {
        loadData();

        updateUrl();
    }, [sorting]);


    const updateUrl = () => {
        let url = '/users';
        let query = {};
        if (sorting.search) {
            query.search = sorting.search;
        }
        if (sorting.sort && sorting.direction && (sorting.sort !== 'created_at' || sorting.direction !== 'desc')) {
            query.sort = sorting.sort;
            query.direction = sorting.direction;
        }
        if (sorting.type) {
            query.type = sorting.type;
        }
        if (sorting.page && sorting.page !== 1) {
            query.page = sorting.page;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const loadData = () => {
        setLoading(true);

        usersModel.list(fields, sorting).then((response) => {
            if (response !== false) {
                setDataItems(response.users);
                setTotalPages(response.totalPages);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onTypeChange = (type) => {
        let newSorting = {...sorting};
        newSorting.type = ((type === sorting.type) ? null : type);
        newSorting.page = 1;
        setSorting(newSorting);
    }


    return (
        <SimpleCardContainer title={t('Users')}>
            <div className="pt-2 pb-4 d-flex justify-content-between">
                <div className="d-flex gap-3">
                    <a href="#" className={'btn ' + ((sorting.type === 'free') ? ('btn-primary') : ('btn-light-primary text-primary'))} onClick={(e) => {
                        e.preventDefault();
                        onTypeChange('free');
                    }}>Free accounts</a>
                    <a href="#" className={'btn ' + ((sorting.type === 'paid') ? ('btn-primary') : ('btn-light-primary text-primary'))} onClick={(e) => {
                        e.preventDefault();
                        onTypeChange('paid');
                    }}>Paid accounts</a>
                </div>
                <UserSearchForm onSearch={onSearch}/>
            </div>

            <div className="table-responsive rounded-2">
                <table className="table border text-nowrap customize-table mb-0 align-middle">
                    <thead className="text-dark fs-4">
                    <tr>
                        {headerItems.map((item, index) => {
                            return (
                                <th key={index}>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((sorting.sort === fields[index]) ? (((sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort={fields[index]} onClick={onSort}>
                                        {item}
                                        {(sorting.sort === fields[index]) && (sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(sorting.sort === fields[index]) && (sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                            )
                        })}
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    <TableEditableRow
                        columns={headerItems.length}
                        loading={loading}
                    />
                    {dataItems.map((item, index) => {
                        return (
                            <tr key={'row-' + index}>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{types[item.type]}</td>
                                <td>{item.businesses_count}</td>
                                <td style={{width: '180px'}}>
                                    <div className="d-flex align-items-center justify-content-end gap-3">
                                        <Link to={'/users/' + item.id + '/edit'}
                                              className={'btn btn-light-primary btn-circle d-inline-flex align-items-center justify-content-center'}>
                                            <i className="fs-5 ti ti-edit text-primary"></i>
                                        </Link>

                                        <button type="button"
                                                className={'btn btn-light-danger btn-circle d-inline-flex align-items-center justify-content-center'}
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    if (window.confirm(t('Are you sure you want to delete this item?'))) {
                                                        onDelete(item.id);
                                                    }
                                                }}>
                                            <i className="fs-5 ti ti-trash text-danger"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

            {totalPages ? (
                <div className="my-3">
                    <Pagination
                        currentPage={sorting.page}
                        totalPages={totalPages}
                        onPageChange={(page) => {
                            let newSorting = {...sorting};
                            newSorting.page = page;
                            setSorting(newSorting);
                        }}
                    />
                </div>
            ) : ('')}
        </SimpleCardContainer>
    );
}
